import React, { lazy } from "react";
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom";


 const Header= lazy(() => import("../widgets/Header.js"));
 const Main = lazy(() => import('../widgets/AddCustomer.js'))
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))

 const AddCust = (props) => {
 

        useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/main.js';   //(This is external js url)
        document.body.appendChild(script);
         }, [])
  
    return (
    <>

      <Header />
      <Main  data={props.type}/>
      <Asidebar />
      <Footer /> 
      <Jsfile />
     
      

  
    </>
  );
};

export default AddCust;
