import React, { lazy } from "react";
import { useState, useEffect } from "react"
import { Link , useHistory} from "react-router-dom"; 
import Config from '../../../config';



 const Header= lazy(() => import("../../widgets/Header.js"));
 const Asidebar =  lazy(() => import('../../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../../widgets/Jsfile.js'))
 const PaginationButton  = lazy(() => import('../../common/PaginationButton.js'))
 

const ListBlogVideo = () => {
   // states 
  const [currentPage, setCurrentPage] = useState(1);  
  const [datacount ,setDatacount] = useState([]);
  const [pageMap ,setPage] =  useState([]);
  const [limit ,setLimit] =  useState(10);
  const [maxshow,setmaxShow] = useState(4);
  const [minshow,setminShow] = useState(0);
  const  [search ,setSearch] =  useState("");

  const [data ,setData] = useState([]);
  const history = useHistory();
  const customerData =  (limit,page) =>{
      setCurrentPage(page)
   
    Config.get(`/admin/article-video?limit=${limit}&page=${page}&search=${search}`)
    .then((res) => {
     console.log(res);
      if (res.status) {

        console.log(res.data)
        setData(res.data)
        setpaginatoion(res.data.count)

      }else{
        Config.showToast("server error!!!");
      }
    })
    .catch((err) => {
      // Config.showToast("Please try after some time.");
      console.log("error in get blog video data")
    });

  }
  
  useEffect(() => {
    // const script = document.createElement('script');
    // script.src = 'assets/js/main.js';   //(This is external js url)   
    // document.body.appendChild(script);
    customerData()
    }, [])

    const setpaginatoion = (ct) =>{
      let page = []
      var limitValue = document.getElementById("limit").value;
      setLimit(parseInt(limitValue))
      //var pageCount = ct/limit == 0? ct/limit : ct/limit+1;
      var pageCount = ((ct/limitValue) === 0)? ct/limitValue : ct/limitValue+1;
      if(isNaN(pageCount)){
        setPage([1]);
      }else{
  
        for(let i=1;i<pageCount;i++){
          page.push(i)
        }
        setPage(page)
      }

      }


      const dataCount =  () =>{
        Config.get(`/admin/dashboars-count`)
        .then((res) => {
         console.log(res);
          if (res.status) {
           setDatacount(res.ct)
        
          
          
          }else{
            Config.showToast("opps ! somthing going wrong ..  ");
          }
        })
        .catch((err) => {
          Config.showToast("opps ! somthing going wrong ..  ");
        });
    
      } 

  
  



  const Pagination = (data) =>{
    var limitValue = document.getElementById(data).value; 
    setLimit(parseInt(limitValue))
    let page = []
     let pageCount2 = datacount.blogCt/parseInt(limitValue) == 0? datacount.blogCt/parseInt(limitValue) : datacount.blogCt/parseInt(limitValue)+1; 
    for(let j=1;j<pageCount2;j++){
     page.push(j)
     }
    setPage(page)
    customerData(limitValue,1)
    }
  

    const move = (count) =>{
      if(count == -1){
      if(currentPage >1){
       let  newPage =    currentPage-1;
       setCurrentPage(newPage)
       customerData(limit,newPage)
       if(maxshow<5){
      }else{    
       setminShow(minshow-1)
       setmaxShow(maxshow-1)
      }

       }
      }else{
        if(currentPage <pageMap.length ){
        let  newPage  =  currentPage+1
        setCurrentPage(newPage)
        customerData(limit,newPage)
        setminShow(minshow+1)
        setmaxShow(maxshow+1)
        }
      }
    }


     useEffect(() => {
      const script = document.createElement('script');
      script.src = 'assets/js/main.js';   //(This is external js url)   
      document.body.appendChild(script);
      customerData(limit, currentPage)
      dataCount()
       }, [])



  

  
  const editBlog = (id)=>{
  localStorage.setItem("blogVideoId",id);
  history.push('/edit-blog-video');
  }


  const clear= ()=>{
    document.getElementById("myInput").value = '';
    seachTable()
   }

  const  AddRemoveKEYDOC = (_id) =>{

    Config.get(`/admin/featured-article-video?_id=${_id}`)
    .then((res) => {
      if (res.status) {

    
          customerData(limit, currentPage)

      }else{
        Config.showToast(res.message , 'danger');
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });

  }
      
   const seachTable = () =>{

    customerData(limit, 1);
     
    
     
    }


  return (
    <>
      <Jsfile />
      <Header />
      
      <>
  <main id="main" className="main">
 
    

    <section className="section dashboard">
      <div className="row">
        {/* Left side columns */}
        <div className="col-lg-12">
          <div className="row">
            {/* Recent Sales */}
            <div className="col-12">
              <div className="card recent-sales overflow-auto">
                <div className="card-body" id="plan">
                  <h5 className="card-title"> Articles  </h5>
                  <div className="dataTable-top">
                  <PaginationButton probs={Pagination}/>
                    <div className="dataTable-search d-flex">
                      <div className="search-bar">
                        <div
                          className="search-form d-flex align-items-center"
                          method="POST"
                          
                        >
                          <input
                            type="text"
                            id="myInput"
                            name="query"
                            placeholder="Search"
                            title="Enter search keyword"
                            className="search_input"
                            value={search}
                            onChange={(e)=>{
                                    setSearch(e.target.value);
                                  }}
                            // onKeyUp={()=>{seachTable()}}
                            
                          />
                           {/* <span  className="search_data_btn" title="Search"></span> */}
                          <button
                            type="submit"
                            className="search_data_btn"
                            title="Search"
                            onClick={() => { seachTable() }}
                          >
                            <i className="bi bi-search" />
                          </button>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="clear_data_btn"
                        title="Clear"
                        onClick={()=>{
                                setSearch("");
                                customerData(limit,1)
                              }}
                      >
                        Clear
                      </button>{" "}
                       <a className="add_lawyers_btn">
                       <Link to="/add-blog-video" style={{color: 'white'}}> Add Videos</Link>
                       </a>
                    </div>
                  </div>
                  <table id="myTable" className="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">Blog</th>
                        <th scope="col">Title</th>
                        {/* <th scope="col">Blog Content </th> */}
                        <th scope="col" className="shorting">
                        Description
                        </th>
                        <th scope="col">Author</th>
                     
                        <th className="shorting">Make It Featured</th>
                        <th className="shorting">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                     {data.map(blog => (  <tr>
                        <td><div><span>{blog.blogId == undefined ?"No blog found":blog.blogId.title} </span></div></td>
                        <td><div><span>{blog.title}</span></div> </td>
                        {/* <td><div><span>{blog.text} </span></div></td> */}
                        <td><div><span>{blog.desc}</span></div> </td>
                        <td><div><span>{blog.blogId == undefined ?"No Author found":blog.blogId.lawyerName} </span></div></td>
                        <td><span id={blog._id} className="pointer view_btn"
                             onClick={()=>{AddRemoveKEYDOC(blog._id)}} > {(blog.isFeatured==0)? "Add" : "Remove"}
                               </span></td>
                        {/* <td><div><span>{Config.myDate(new Date(blog.date).toDateString())} </span></div></td> */}
                        {/* <td><div><span>{blog.code}</span></div> </td>
                        <td><div><span>{blog.name} </span></div></td>
                        <td><div><span>{blog.code}</span></div> </td> */}

                        
                        <td><span onClick={()=>{editBlog(blog._id)}} className="pointer view_btn">
                           Edit
                          </span> 
                        
                         
                        </td>
                      </tr> ))}
                     
                    </tbody>
                  </table>
                  <div id="noRsFound" style={{display:"none",  marginLeft:"416px"}}>   No Result  </div>

                  <div className="dataTable-bottom "><div className="dataTable-info"></div>
                        
                      <nav className="dataTable-pagination">
                          <ul className="dataTable-pagination-list ">
                           <li>
                            showing {currentPage} page form {pageMap.length} 
                          </li>
                          </ul>
                        </nav>


                      <nav className="dataTable-pagination">   
                      <ul className="dataTable-pagination-list ">
                      
                      <li className={currentPage === 1 ? "pager disabled" : "pager"}><a onClick={()=>{move(-1)}} >‹</a></li>
                        {pageMap.map((data,i)=> (
                        (i <maxshow && i>=minshow)?  <li className={currentPage ==  data? "pointer active": "pointer" } ><a onClick={()=>{customerData(limit,data)}}  data-page={data}>{data}</a></li>:''
                         ))}
                      <li className={currentPage === pageMap.length ? "pager disabled" : "pager"}><a onClick={()=>{move(+1)}} >›</a></li></ul></nav>
                    </div>

                </div>
              </div>
            </div>
            {/* End Recent Sales */}
          </div>
        </div>
      </div>
    </section>
  </main>
  {/* End #main */}
</>


      <Asidebar />
      <Footer /> 
    
     
      
    </>
  );
};

export default ListBlogVideo;
