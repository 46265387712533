import React, { useState , lazy } from "react";

import "./Login.css";

import Config from '../../../config';
import { useHistory } from "react-router-dom";

const Jsfile = lazy(() => import('../../widgets/Jsfile.js'))

const Login = () => {
   
  const setCookie = (cname,cvalue,exdays)=> {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  const checkCookie=()=> {
    let email = document.getElementById("email").value;
    let password = document.getElementById("Password").value;
  

    let user = getCookie(email);
    if (user != password && password.length>3) {
      
      setCookie(email, password, 30);
      document.getElementById("Password").value = password
      } else{

      
    if (user != "") {
       document.getElementById("Password").value = user
    } else {
       user = password
       if (user != "" && user != null) {
         setCookie(email, user, 30);
       }
    }
  }
  }


  const viewPass = () =>{
   var data =  document.getElementById('Password').type;
   if(data == 'password'){
    document.getElementById('Password').type = "text";
    document.getElementById('eye').className = "bi bi bi-eye";
   }else{
    document.getElementById('Password').type = "password";
    document.getElementById('eye').className = "bi bi-eye-slash";
   }
 
  } 

  
 

  const history = useHistory();

  async function login(e) {
        e.preventDefault();
        
      var email=e.target.email.value, password=e.target.password.value
      var data = {username:email,password};
  
     if(!email)
      return Config.showToast('Please enter your email.');
    else if(!password)
      return Config.showToast('Please enter the password.','success');

    
    //  Config.showToast("Authenticating...")
     Config.post('/admin/login',data)
      .then((res) => {
      // console.log(res);
        if (res.status) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          sessionStorage.setItem("mypage","dashboard");
          sessionStorage.setItem("token",res.data.token);
          sessionStorage.setItem("userType",res.data.userType);
          if(res.data.userType =='lawyer'){
            sessionStorage.setItem("OldPw", res.data.password);
            sessionStorage.setItem("LawyerId", res.data._id);
            sessionStorage.setItem("name", res.data.name);
            sessionStorage.setItem("username", res.data.name);
            sessionStorage.setItem("profile", res.data.profilePicture);
          }else{ 
            sessionStorage.setItem("LawyerId",''); 
            sessionStorage.setItem("name", res.data.name);
            sessionStorage.setItem("username", res.data.username);
            sessionStorage.setItem("profile", res.data.profile);
            sessionStorage.setItem("AdminId", res.data._id);
          }

         
        
          

          history.push("/dashboard");
          // window.location.href("/dashboard")
          //  Config.showToast("Login success !!!! ")
        }else{
          Config.showToast("Wrong Password","danger");
        }
      })
      .catch((err) => {
        Config.showToast("Invalid Credentials.", "danger");
      });
  }

  return (

  
    <main>
 
    <div className={"container"}>

      <section className={"section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4"}>
        <div className={"container"}>
          <div className={"row justify-content-center"}>
            <div className={"col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center"}>

              <div className={"d-flex justify-content-center py-4"}>
                <a className={"logo d-flex align-items-center w-auto"}>
                <img src="assets/img/logo.png" alt=""/>
                 
                </a>
              </div>

              <div className="card mb-3">

                <div className="card-body">

                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">Log In to Your Account</h5>
                    <p className="text-center small">Enter your username & password to log in</p>
                  </div>

                  <form className={"row g-3 needs-validation"} onSubmit={login} novalidate>

                    <div className={"col-12"}>
                      <label for="yourUsername" className={"form-label"}>Username</label>
                      <div className={"input-group has-validation"}>
                        <input
                         type="text" 
                         name="username"
                         class="form-control"
                         id="email"
                         required/>
                        <span className="pointer input-group-text" id="inputGroupPrepend">@</span>
                        <div className={"invalid-feedback"}>Please enter your username.</div>
                      </div>
                    </div>

                    <div className="col-12">
                      <label for="yourPassword" className="form-label">Password</label>
                      <div className="input-group show_hide_password" >
                        <input type="password"  name="password" className="form-control" id="Password" required="" />
                        <span onClick={()=>{viewPass()}} className="input-group-text"> <i id='eye' className="bi bi-eye-slash pointer"></i> </span>
                        <div className="invalid-feedback">Please enter your password!</div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        <input onClick={()=>{checkCookie()}}  className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe"/>
                        <label className="form-check-label" for="rememberMe">Remember me</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className={"btn btn-primary w-100"} >Login</button>
                    </div>
                  </form>

                </div>
              </div>

            </div>
          </div>
        </div>

      </section>
      <Jsfile />

    </div>
  </main>


    // <div className={"login-container"} style={{background:'url(/login-bg.jpg)'}}>
    // <div className={"login-form"} >
    // <form method="post" onSubmit={login}>
    //   <Card
    //     style={{
    //       boxShadow: "none",
    //       width: "20rem",
    //       backgroundColor: "",
    //     }}
    //   >
    //     <CardContent>
    //       <CssBaseline />
    //       <center><img src="/logo.pg"  alt="logo" /></center>

    //       <Typography
    //         variant="h4"
    //         style={{
    //           fontWeight: "500",
    //           textAlign: "center",
    //           marginBottom: "1rem",
    //           marginTop: "1rem",
    //           color: "#430303",
    //         }}
    //       >
    //         LOGIN 
    //       </Typography>

    //       <TextField
    //         variant="outlined"
    //         margin="normal"
    //         fullWidth
    //         size="small"
    //         name="email"
    //         label="Email Address"
    //         id="email"
    //         autoComplete="email"
    //       />

    //       <TextField
    //         variant="outlined"
    //         margin="normal"
    //         type="password"
    //         fullWidth
    //         size="small"
    //         name="password"
    //         label="Password"
    //         id="password"
    //         autoComplete="password"
           
    //       />
    //       <br/>
    //       <br/>
    //       <button
    //         style={{ marginBottom: "20px" }}
    //         type="submit"
    //         id="signin"
    //         variant="contained"
            
    //         fullWidth
    //         className={"btn btn-warning btn-block"}
    //       >
    //         Login
    //       </button>
            
      
    //     </CardContent>
    //   </Card>
    //   </form>
      
    // </div>
    // </div>
  );
};

export default Login;
