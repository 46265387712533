import React , { lazy } from 'react'
import { useState, useEffect , useRef} from "react"
import Config from '../../config';
import JoditEditor, { Jodit } from 'jodit-react';
import Multiselect from 'multiselect-react-dropdown';

// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { useHistory } from "react-router-dom";
// const Popup = lazy(() => import('./DeletePop.js'))
 const Header= lazy(() => import("../widgets/Header.js"));
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
 const MediaUpload = lazy(() => import('./MediaUpload'))
 const Popup = lazy(() => import('../widgets/DeletePop.js'))

 function preparePaste(jodit) {
  console.log("hwy 123", jodit);
  jodit.e.on(
    "paste",
    (e) => {
      console.log("event only", e);
      console.log("content pasted..", Jodit.modules.Helpers.getDataTransfer(e).getData(
        Jodit.constants.TEXT_PLAIN
      ));
      const html = Jodit.modules.Helpers.getDataTransfer(e).getData(
        Jodit.constants.TEXT_PLAIN
      );
      console.log("type of",typeof html);
      const iframe = jodit.create.fromHTML(html);

      if (iframe.nodeName === 'IFRAME' && iframe.src.includes('www.facebook.com')) {
        jodit.e.stopPropagation("paste");
        const range = jodit.s.range;
        // const fragment = range.extractContents();
        
        // console.log("hlo2", typeof iframe, iframe.nodeName, parse(html).nodeName);
        console.log("hlo3", range, iframe);
        console.log("hlo4", iframe.width, iframe.height, iframe.style);
        console.log("src", iframe.src, iframe.src.includes('www.facebook.com'));
        if(iframe.src.includes('www.facebook.com')){
          iframe.style = `border: none; visibility: visible; width: ${iframe.width}px; height: ${iframe.height}px;`;
        }
        //  else {
        //   iframe.style = `border: none; visibility: visible; width: ${iframe.width}px; height: ${iframe.height}px;`;
        // }
        
        // iframe.href = html;
        // iframe.appendChild(fragment);
        range.insertNode(iframe);

        // if (!a.textContent) {
        //   a.innerText = html;
        // }

        // jodit.synchronizeValues();

        return false;
      }
    },
    { top: true }
  );
}

Jodit.plugins.add("preparePaste", preparePaste);

const AddBlogs = (props) => {
const id = localStorage.getItem("blogID")
 
 const  [service,setService] = useState([]);
 const [article, setArticle] = useState("");
 const editor = useRef(null);
 const [data ,setData] = useState([]);
 const [selectedCat, setSelectedCat] = useState([])



//  const setSelectedCAtegory = (data) =>{
//   Config.post(`/admin/filter-category`,{data})
//   .then((res) => {
//     // alert(data)
//    console.log(res);
//       if (res.status) {
//         setSelectedCat(res.data)
    
//     }else{
//       Config.showToast("Please try after some time.");
//     }
//   })
//   .catch((err) => {
//     Config.showToast("Please try after some time.");
//   });


//  }

const setSelectedCompany = (data) =>{
  Config.get(`/admin/company?_id=${data}`)
  .then((res) => {
    // alert(data)
  //  console.log(res);
      if (res.status) {
        setSelectedCat(res.data)
    
    }else{
      Config.showToast("Please try after some time.");
    }
  })
  .catch((err) => {
    Config.showToast("Please try after some time.");
  });


 }


 const blogData =  () =>{
   
    Config.get(`/admin/article/id?_id=${id}`)
    .then((res) => {
    //  console.log(res);
        if (res.status) {
          document.getElementById('Browse').className = "upload-project-img img_zoom"
          document.getElementById("media").value = res.data.media;
          document.getElementById("mainscr").src = res.data.media;

        setData(res.data)
        //setSelectedCAtegory(res.data.category);

        setSelectedCompany(res.data._id)
        
      }else{
        Config.showToast("Please try after some time.");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });

  }


  // drop down option multiselece 
  // const [options ,setDropDown] = useState([]);
   const options = [
   {name:"All"},
   {name:"Agriculture and Farming"},

   {name:"Architecture and Design"},
   
   {name:"Art and Creative Services"},
   
   {name:"Automotive and Transportation"},
   
   {name:"Aviation and Aerospace"},
   
   {name:"Banking and Finance"},
   
   {name:"Beauty Products and Services"},
   
   {name:"Biotechnology and Pharmaceuticals"},
   
   {name:"Building"},
   
   {name:"Business Consultancy"},
   
   {name:"Cannabis and Hemp Products"},
   
   {name :"Care Sector"},
   
   {name:"Chemicals and Plastics"},
   
   {name:"Children’s Activities"},
   
   {name:"Construction and Engineering"},
   
   {name:"Consumer Goods and Services"},
   
   {name:"Consulting"},
   
   {name:"Cryptocurrency and Blockchain Technology"},
   
   {name:"Defence and Military"},
   
   {name:"Dental Services"},
   
   {name:"Education and Training"},
   
   {name:"Energy and Utilities"},
   
   {name:"Entertainment and Media"},
   
   {name:"Environmental Services"},
   
   {name:"eSports and Gaming"},
   
   {name:"Event Planning and Management"},
   
   {name:"Financial Technology"},
   
   {name:"Fitness and Wellness"},
   
   {name:"Food and Beverage"},
   
   {name:"Franchises"},
   
   {name:"Gambling"},
   
   {name:"Government and Public Administration"},
   
   {name:"Government Relations and Public Affairs"},
   
   {name:"Healthcare and Medical"},
   
   {name:"Hire"},
   
   {name:"Home and Garden"},
   
   {name:"Hospitality"},
   
   {name:"Human Resources and Staffing"},
   
   {name:"Information Technology and Services"},
   
   {name:"Insurance"},
   
   {name:"Interior Design and Decoration"},
   
   {name:"Investment Management"},
   
   {name:"Legal Services"},
   
   {name:"Logistics and Supply Chain Management"},
   
   {name:"Luxury Goods and Services"},
   
   {name:"Management Consulting"},
   
   {name:"Manufacturing and Production"},
   
   {name:"Media and Communications"},
   
   {name:"Mining and Metals"},
   
   {name:"Music and Audio Production"},
   
   {name:"Non-profit Organizations"},
   
   {name:"Occupational Therapy"},
   
   {name:"Personalised Nutrition and Wellness"},
   
   {name:"Pet Technology and Products"},
   
   {name:"Pharmaceuticals"},
   
   {name:"Printing and Publishing"},
   
   {name:"Product Rentals"},
   
   {name:"Professional Coaching and Training"},
   
   {name:"Property Development"},
   
   {name:"Property Management"},
   
   {name:"Public Relations and Communications"},
   
   {name:"Publishing"},
   
   {name:"Quality Control and Assurance"},
   
   {name:"Real Estate"},
   
   {name:"Research and Development"},
   
   {name:"Retail and E-Commerce"},
   
   {name:"Science and Technology"},
   
   {name:"Social Services"},
   
   {name:"Software and Technology"},
   
   {name:"Special Education Services"},
   
   {name:"Sports and Recreation"},
   
   {name:"Sustainable and Eco-Friendly Products and Services"},
   
   {name:"Technology"},
   
   {name:"Tutoring"},
   
   {name:"Fitness"},
   
   {name:"Telecommunications and Networking"},
   
   {name:"Telecommunications Services"},
   
   {name:"Textile and Apparel Design"},
   
   {name:"Textiles and Apparel"},
   
   {name:"Tourism and Travel"},
   
   {name:"Transport and Logistics"},
   
   {name:"Translation and Localization Services"},
   
   {name:"Veterinary and Animal Services"},
   
   {name:"Virtual and Augmented Reality"},
   
   {name:"Waste Management and Recycling"},
   
   {name:"Web Development and Design"},
   
   {name:"Wedding Planning and Services"},
   
   {name:"Writing and Editing Services"} ]

  const  categoriesDropDown =  () =>{
         
    Config.get(`/admin/lawyer-categories`)
    .then((res) => {
     console.log(res);
        if (res.status) {
        // setDropDown(res.data)
          // alert()
    
      }else{
        Config.showToast("Please try after some time.");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });



  Config.get(`/admin/legal-services`)
    .then((res) => {
       console.log(res);
        if (res.status) {
          setService(res.data)
          
         }else{
        Config.showToast("Please try after some time.");
       }
      })
     .catch((err) => {
      Config.showToast("Please try after some time.");
      });

    
    }


    // lawyer list 
    const [lawyer,setLawyer]= useState([])

    const lawyerData =  () =>{
   
      Config.get('/admin/author')
      .then((res) => {
       console.log(res);
        if (res.status) {
         
          setLawyer(res.data)
        
        }else{
          Config.showToast("server error!!! ");
        }
      })
      .catch((err) => {
        Config.showToast("Please try after some time.");
      });
  
    }

   const setIdinLocal = (id)=>{
    localStorage.setItem("blogID",id);
    localStorage.setItem("deleteType","blog");
   
    }
   


    const back = () =>{
      history.push("/blogs");
    }

  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/js/main.js';   //(This is external js url)   
    document.body.appendChild(script);
    lawyerData()
    categoriesDropDown()
  
    if(props.type== "Edit"){
      blogData()
     document.getElementById("deleteButton").style.display = "block"
    }
  }, [])

  const history = useHistory();

     // add edit data
    async function AddBlog(e) {
          e.preventDefault();
          var drop = e.target.DropdoenData.value.split(',')
          // let mycategory = drop)
            
          //  console.log("drop", drop)
            //  return drop
             var name = "test", 
             title = e.target.title.value,
             media = e.target.media.value,
             lawyerId = e.target.lawyerId.value,
             service = e.target.service.value,
             category = drop,
             text = article

        if(props.type == "Add"){
         var data = {name,title,media,lawyerId,service,category,text};
            Config.post('/admin/article',data)
              .then((res) => {
              console.log(res);
                if (res.status) {
                  history.push("/blogs");
                  }else{
                  Config.showToast("somthing wrong");
                  }
                })
              .catch((err) => {
                Config.showToast("Please try after some time.");
              });

            }else {
              
          // call edit api 
          var data = {_id:id,name,title,media,service,lawyerId,category,text};

                Config.put('/admin/article',data)
                .then((res) => {
                  console.log(res);
                  if (res.status) {
                     
                    history.push("/blogs");
                    }else{
                    Config.showToast("somthing wrong ");
                    }
                  })
                .catch((err) => {
                  Config.showToast("Please try after some time.");
                });



         }

      }
  


  return (
    <>
      <Jsfile />
      <Header />
 <main id="main" className="main">
  <Popup />

  <section className="section">
  {/*  
  <div id="imgLoader"  style={{display:"none"}} className="lds-spinner"><div></div><div>
    </div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{props.type} Article </h5>
            {/* Multi Columns Form */}

          

            <form className="row g-3" method='post' onSubmit={AddBlog}>
              {/* <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                 Name  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="name" name="name" onChange={(e) => setData(e.target.value)} value={data.name}  required/>
              </div> */}
              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                 Title  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="title" name="title" onChange={(e) => setData(e.target.value)} value={data.title} required/>
              </div>
              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                Legal Services <span style={{color:"red"}} >* </span>
                </label>
                <select  className="pointer form-control form-select" id="service" name="service" onChange={(e) => setData(e.target.value)} value={data.service}    >
               
                 {service.map(law => (
                  <option value={law._id}>{law.name} </option>

                 ))}
                </select>
               </div>

              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                 Author  <span style={{color:"red"}} >* </span>
                </label>
                <select  className="pointer form-control form-select" id="layerId" name="lawyerId" onChange={(e) => setData(e.target.value)} value={data.lawyerId}    >
               
                 {lawyer.map(law => (
                  <option value={law._id}>{law.name} </option>

                 ))}
                </select>
               </div>
                 {/* categories dropdown */}
           
                 <div className="form-group col-lg-4">
                 <label htmlFor="categories" className=" form-label">
                 {/* Categories  */}
                 Industry
                 </label>
               
           <input type="text"  style={{display:"none"}} className="form-control" id="DropdoenData" name="DropdoenData"  value={data.category} />
            <Multiselect
              options={options} // Options to display in the dropdown
              onSelect={(event)=>{
              var arr = [];
              for(let i=0 ; i<event.length ; i++){
                arr.push(event[i].name)
              
              }
              document.getElementById("DropdoenData").value = arr;
              }} // Function will trigger on select event
              onRemove={(event)=>{ 
              var arr = [];
              for(let i=0 ; i<event.length ; i++){
                arr.push(event[i].name)
              
              }  
              // console.log(arr)
              document.getElementById("DropdoenData").value = arr; }} 
              // Function will trigger on remove event
               selectedValues={selectedCat}
              displayValue={"name"}
              showCheckbox
              />
              
              </div>

               <MediaUpload data = {props.type} />

               <label htmlFor="categories" className=" form-label">
                Blog Content 
                </label>
            
                {/* <CKEditor
                        editor={ ClassicEditor }
                        // config={ {
                        //     plugins: [ Paragraph, Bold, Italic, Essentials ],
                        //     toolbar: [ 'bold', 'italic' ]
                        //    } }
                        // data="<p>Hello from the first editor working with the context!</p>"
                        // onReady={ editor => {
                        //     // You can store the "editor" and use when it is needed.
                        //     console.log( 'Editor1 is ready to use!', editor );
                        // } }
                    /> */}
                {/* </CKEditorContext> */}

                <JoditEditor
			          ref={editor}
			          value={data.text}
		           	// config={config}
                tabIndex={1} // tabIndex of textarea
                onChange={newContent => setArticle(newContent)} // preferred to use only this option to update the content for performance reasons
                // onChange={newContent => {}}
                />

       
        
        
                 <div className="text-center">
                  
                  <button type="submit" className="btn submit-btn1">
                  {props.type== "Edit" ? "Update": "Submit" }
                  </button>
 
                  <span onClick={()=>{back()}} className="btn submit-btn2">
                   Go Back</span>
             

                  <span className="btn  submit-btn4" style={{float:"right", display:"none"}} data-bs-toggle="modal" 
                   id ="deleteButton"
                   onClick={()=>{setIdinLocal(data._id)}} data-bs-target="#verticalycentered">Delete</span>
              
              </div>
            </form>
            {/* End Multi Columns Form */}
            {/* <div>{article}</div> */}
          </div>
        </div>
      </div>
    </div>
  </section>

</main>
 
      <Asidebar />
      <Footer /> 
    
</>

 )
}

export default AddBlogs
