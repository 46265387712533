import React, { lazy } from "react";
import { useState, useEffect } from "react"
import Config from '../../config';
import { useHistory,useParams } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';



const Header= lazy(() => import("../widgets/Header.js"));

const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
const Footer = lazy(() => import('../widgets/Footer.js'))
const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
const Popup = lazy(() => import('../widgets/DeletePop.js'))

const EditLegalDocuments = (props) => {




  const {id} = useParams();

  //  const [toast, setToast] = useState(true);
  var data2;
  const [data ,setData] = useState([]);
  const [category ,setCat] = useState([]);
  const [subCategory ,setSubCat] = useState([]);
  const [loading ,setLoading] = useState(false);

  const [subcatId ,setSubCatId] = useState([]);
  const [subcatName ,setSubCatName] = useState([]);
  const [selectedCat,setSelectedSub] = useState([]);


  // set industry constant value
  const [subIndustryId ,setSubIndustryId] = useState([]);
  const [subIndustryName ,setSubIndustryName] = useState([]);
  const [selectedIndustry,setSelectedIndustry] = useState([]);


  const [industryOptions, setIndustryOptions] = useState([
    {"id":1,"name":"All"},
    {"id":2,"name":"Advertising and Marketing"},
    {"id":3,"name":"Agriculture and Farming"},
    {"id":4,"name":"Architecture and Design"},
    {"id":5,"name":"Art and Creative Services"},
    {"id":6,"name":"Automotive and Transportation"},
    {"id":7,"name":"Aviation and Aerospace"},
    {"id":8,"name":"Banking and Finance"},
    {"id":9,"name":"Beauty Products and Services"},
    {"id":10,"name":"Biotechnology and Pharmaceuticals"},
    {"id":11,"name":"Building"},
    {"id":12,"name":"Business Consultancy"},
    {"id":13,"name":"Cannabis and Hemp Products"},
    {"id":14,"name":"Care Sector"},
    {"id":15,"name":"Chemicals and Plastics"},
    {"id":16,"name":"Children’s Activities"},
    {"id":17,"name":"Construction and Engineering"},
    {"id":18,"name":"Consumer Goods and Services"},
    {"id":19,"name":"Consulting"},
    {"id":20,"name":"Cryptocurrency and Blockchain Technology"},
    {"id":21,"name":"Defence and Military"},
    {"id":22,"name":"Dental Services"},
    {"id":23,"name":"Education and Training"},
    {"id":24,"name":"Energy and Utilities"},
    {"id":25,"name":"Entertainment and Media"},
    {"id":26,"name":"Environmental Services"},
    {"id":27,"name":"eSports and Gaming"},
    {"id":28,"name":"Event Planning and Management"},
    {"id":29,"name":"Financial Technology"},
    {"id":30,"name":"Fitness"},
    {"id":31,"name":"Fitness and Wellness"},
    {"id":32,"name":"Food and Beverage"},
    {"id":33,"name":"Franchises"},
    {"id":34,"name":"Gambling"},
    {"id":35,"name":"Government and Public Administration"},
    {"id":36,"name":"Government Relations and Public Affairs"},
    {"id":37,"name":"Healthcare and Medical"},
    {"id":38,"name":"Hire"},
    {"id":39,"name":"Home and Garden"},
    {"id":40,"name":"Hospitality"},
    {"id":41,"name":"Human Resources and Staffing"},
    {"id":42,"name":"Information Technology and Services"},
    {"id":43,"name":"Insurance"},
    {"id":44,"name":"Interior Design and Decoration"},
    {"id":45,"name":"Investment Management"},
    {"id":46,"name":"Legal Services"},
    {"id":47,"name":"Logistics and Supply Chain Management"},
    {"id":48,"name":"Luxury Goods and Services"},
    {"id":49,"name":"Management Consulting"},
    {"id":50,"name":"Manufacturing and Production"},
    {"id":51,"name":"Media and Communications"},
    {"id":52,"name":"Mining and Metals"},
    {"id":53,"name":"Music and Audio Production"},
    {"id":54,"name":"Non-profit Organizations"},
    {"id":55,"name":"Occupational Therapy"},
    {"id":56,"name":"Personalised Nutrition and Wellness"},
    {"id":57,"name":"Pet Technology and Products"},
    {"id":58,"name":"Pharmaceuticals"},
    {"id":59,"name":"Printing and Publishing"},
    {"id":60,"name":"Product Rentals"},
    {"id":61,"name":"Professional Coaching and Training"},
    {"id":62,"name":"Property Development"},
    {"id":63,"name":"Property Management"},
    {"id":64,"name":"Public Relations and Communications"},
    {"id":65,"name":"Publishing"},
    {"id":66,"name":"Quality Control and Assurance"},
    {"id":67,"name":"Real Estate"},
    {"id":68,"name":"Research and Development"},
    {"id":69,"name":"Retail and E-Commerce"},
    {"id":70,"name":"Science and Technology"},
    {"id":71,"name":"Social Services"},
    {"id":72,"name":"Software and Technology"},
    {"id":73,"name":"Special Education Services"},
    {"id":74,"name":"Sports and Recreation"},
    {"id":75,"name":"Sustainable and Eco-Friendly Products and Services"},
    {"id":76,"name":"Technology"},
    {"id":77,"name":"Tutoring"},
    {"id":78,"name":"Telecommunications and Networking"},
    {"id":79,"name":"Telecommunications Services"},
    {"id":80,"name":"Textile and Apparel Design"},
    {"id":81,"name":"Textiles and Apparel"},
    {"id":82,"name":"Tourism and Travel"},
    {"id":83,"name":"Transport and Logistics"},
    {"id":84,"name":"Translation and Localization Services"},
    {"id":85,"name":"Veterinary and Animal Services"},
    {"id":86,"name":"Virtual and Augmented Reality"},
    {"id":87,"name":"Waste Management and Recycling"},
    {"id":88,"name":"Web Development and Design"},
    {"id":89,"name":"Wedding Planning and Services"},
    {"id":90,"name":"Writing and Editing Services"}
  ]);

  

  

  

  const  getCategoryCat =  async(catId) =>{ 
    Config.get(`/user/legal-doc-subCategory?catId=${catId}`)
    .then((res) => {
     console.log(res);
      if (res.status) {
        //console.log(res.data)
        setSubCat(res.data)
        
        }else{
        Config.showToast("server error!!!");
      }
     })
    .catch((err) => {
      Config.showToast("server error!!! ");
     });

  }

  

  
  const  getCategory =  () =>{
   Config.get('/user/legal-doc-category')
    .then((res) => {
     console.log(res);
      if (res.status) {
        //console.log(res.data)
         setCat(res.data)
         data2 = res.data
        
        getCategoryCat(res.data[0]._id)


       }else{
        Config.showToast("server error!!!");
      }
     })
    .catch((err) => {
      Config.showToast("server error!!! ");
     });

  }




  const back = () =>{
    history.push("/legaldoc");
  }

  const documentData =  () =>{

    setLoading(true);
    Config.get(`/admin/legal-document-id?_id=${id}`)
      .then((res) => {
        setLoading(false);
        if (res.status) {

          setData(res.data)
          setSubCatId(res.data.subCategoryId)
          if (res.data.SubCatDetail ===null ) {
            setSelectedSub(res.data.SubCatDetail);
          }
          setSubCatName(res.data.subCategory.split(','))
          var tmpIndustryArrayData = res.data.industry.split(',');

          var tmpIndustryArray = [];
          tmpIndustryArrayData.forEach((items)=>{
            var checkIndustry = industryOptions.find((data)=> data.name === items);
            if(checkIndustry)
            tmpIndustryArray.push({'name':items})
          })

          setSelectedIndustry(tmpIndustryArray)

        }else{
          Config.showToast("server error!!! ");
        }
      })
      .catch((err) => {
        Config.showToast("Please try after some time.");
      });

  }

  
 
  
  // const onload =  async (da)=>{
    
  //   var dataArray = []
    
  //    subCategory.map((m)=>{
  //     //  alert()
  //       console.log()
  //     if(subcatId.includes(`${m._id}`)){
  //       dataArray.push(m)
  //     }
      

  //    })
  //    setSelectedSub(dataArray)

  
  // }

  //console.log("sckcjk"  , selectedCat)

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/js/main.js';   //(This is external js url)
    document.body.appendChild(script);
    // for layer dropdown

    documentData();
    document.getElementById("deleteButton").style.display = "block"
    getCategory();
   

  }, [])

  const setIdinLocal = (id)=>{
    localStorage.setItem("documentId",id);
    localStorage.setItem("deleteType","legalDocument");

  }



  const history = useHistory();


  async function AddServices(e) {
    e.preventDefault();


    let data = Object.assign({});
    let catSelect =  document.querySelector('#category');
    let subCatSelect = document.querySelector('#subCategory');
    // alert(e.target.category.text)

    var industryName = '';
    if(subIndustryName.toString()){
      industryName = subIndustryName.toString();
    }else{
      var tmpIndustryArray = [];
      industryOptions.forEach((items,index)=>{
        // insert only first item if user has not selected any
        if(index === 0)
        tmpIndustryArray.push(items.name)
      })
      industryName = tmpIndustryArray.toString();
    }


    data["_id"] = id;
    data["name"] = e.target.name.value;
    data["categoryId"] = e.target.category.value;
    data["subCategoryId"] = subcatId;
    data["category"] = catSelect.options[catSelect.selectedIndex].text;
    data["subCategory"] = subcatName.toString();
    data["industry"] = industryName;
    //data["industry"] = e.target.industry.value;
    data["price"] = e.target.price.value;
    data["documentFor"] = e.target.documentFor.value;
    data["included"] = e.target.included.value;
    data["documentType"] = e.target.documentType.value;
    data["keywords"] = e.target.keywords.value;
    data["shortDescription"] = 'short description';
    data["longDescription"] = 'long description';



    Config.put('/admin/legal-document',data)
      .then((res) => {
        console.log(res);
        if (res.status) {

          history.push("/legaldoc");
        }else{
          Config.showToast(res.message);
        }
      })
      .catch((err) => {

        var stausCode = (JSON.stringify(err.response.data.code));
       // console.log(parseInt(stausCode))
        if(parseInt(stausCode) === 11000){
          Config.showToast('Document already exists');
        }else{
          Config.showToast("Please try after some time.");
        }

      });





  }


  function handleChange(e) {
      setData(e.target.value);
     var id =   document.getElementById("category").value
      // alert(id)
      getCategoryCat(id)   
  }


// console.log(data.subCategory.split(','))



  return (
    <>

      <Header />
      <Asidebar />
      <main id="main" className="main">
        <Popup />
        <section className="section">
          <div id="imgLoader" style={{display:"none"}} className="lds-spinner"><div></div><div>
          </div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">

                {loading ?
                  <span className={"go-back d-flex"} style={{height:'26px;'}}>
                  </span>
                  :
                <span className={"go-back d-flex"} onClick={() => history.goBack()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M15 8C15 7.72386 14.7761 7.5 14.5 7.5L2.70711 7.5L5.85355 4.35355C6.04882 4.15829 6.04882 3.84171 5.85355 3.64645C5.65829 3.45118 5.34171 3.45118 5.14645 3.64645L1.14645 7.64645C0.951184 7.84171 0.951184 8.15829 1.14645 8.35355L5.14645 12.3536C5.34171 12.5488 5.65829 12.5488 5.85355 12.3536C6.04882 12.1583 6.04882 11.8417 5.85355 11.6464L2.70711 8.5H14.5C14.7761 8.5 15 8.27614 15 8Z"
                          fill="black" class="pointer"/>
                  </svg>
                  <div>{data.name}</div>
                </span>
                }

                <ul className="nav nav-tabs nav-tabs-bordered">
                  <li className="nav-item">
                    <button
                      className="nav-link active"
                      onClick={()=>{history.push(`/legal-document/${id}/information`)}}
                    >
                      Information
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      onClick={()=>{history.push(`/legal-document/${id}/questionnaire`)}}
                    >
                      Questionnaire
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      onClick={()=>{history.push(`/legal-document/${id}/document`)}}
                    >
                      Document
                    </button>
                  </li>
                </ul>

                {loading ?
                  <div className="card-body">
                    <div className={"loading-icon"}>
                      <center>
                        <img src="/assets/img/loading-icon.gif" alt="img"  />
                      </center>
                    </div>
                  </div>
                  :
                  <div className="card-body">
                    <h5 className="card-title">Edit legal documents  </h5>
                    {/* Multi Columns Form */}



                    <form className="row g-3" method='post' onSubmit={AddServices}>

                      {/* Secation One Banner */}
                      <div className="col-md-6">
                        <label htmlFor="inputName5" className="form-label">
                          Name  <span style={{color:"red"}} >* </span>
                        </label>
                        <input type="text" className="form-control" id="name" name="name" onChange={(e) => setData(e.target.value)} value={data.name}   required />

                      </div>

                      <div className="col-md-6">
                        <label htmlFor="inputName5" className="form-label">
                          Category  <span style={{color:"red"}} >* </span>
                        </label>
                        <select id="category"  name="category"  className="pointer form-select" onChange={handleChange} value={data.categoryId} >
                        {category.map(cat => (
                         <option value={cat._id}>{cat.name} </option>

                          ))}
                          {/* <option value={"Employment Law & HR"}>Employment Law & HR</option>
                          <option value={"Business Law"}>Business Law</option>
                          <option value={"Wills"}>Wills</option>
                          <option value={"General Consultation"}>General Consultation</option> */}
                        </select>
                        {/*<input type="text" className="form-control" id="category" name="category" onChange={(e) => setData(e.target.value)} value={data.category}   required />*/}

                      </div>

                      <div className="col-md-6">
                        <label htmlFor="inputName5" className="form-label">
                          Sub category  <span style={{color:"red"}} >* </span>
                        </label>

                        <Multiselect
                          options={subCategory} // Options to display in the dropdown
                          onSelect={(event)=>{
                          var arr = [];
                          var arr2 = [];
                          for(let i=0 ; i<event.length ; i++){
                            if(event[i]) {
                              arr.push(event[i]._id)
                              arr2.push(event[i].name)
                            }

                           }
                           setSubCatId(arr)
                           setSubCatName(arr2)
                          // document.getElementById("DropdoenData").value = arr;
                          }} // Function will trigger on select event
                          onRemove={(event)=>{
                          var arr = [];
                          var arr2 = [];
                          for(let i=0 ; i<event.length ; i++){
                            if(event[i]) {
                              arr.push(event[i]._id)
                              arr2.push(event[i].name)
                            }
                          }
                          // console.log(arr)
                          setSubCatId(arr)
                          setSubCatName(arr2)
                        //  document.getElementById("DropdoenData").value = arr;
                          }} // Function will trigger on remove event
                          selectedValues={selectedCat}
                          displayValue={"name"}
                          showCheckbox
                          />


                        {/* <select id="subCategory"  name="subCategory"  className="pointer form-select"  onChange={(e) => setData(e.target.value)} value={data.subCategoryId} >


                        {subCategory.map(cat => (
                         <option value={cat._id}>{cat.name} </option>

                          ))} */}
                          {/* <option>Sub category 1</option>
                          <option>Sub category 2</option>
                          <option>Sub category 3</option>
                          <option>Sub category 4</option> */}
                        {/* </select> */}

                      </div>

                      <div className="col-md-6">
                        <label htmlFor="inputName5" className="form-label">
                          Industry
                        </label>
                        {/*<input type="text" className="form-control" id="industry" name="industry" value={data.industry}   required />*/}
                        {/*<select id="industry"  name="industry"  className="pointer form-select"  onChange={(e) => setData(e.target.value)} value={data.industry}>*/}
                        {/*  <option>Option 1</option>*/}
                        {/*  <option>Option 2</option>*/}
                        {/*  <option>Option 3</option>*/}
                        {/*</select>*/}

                        <Multiselect
                          options={industryOptions} // Options to display in the dropdown
                          onSelect={(event)=>{
                            var arr = [];
                            var arr2 = [];
                            for(let i=0 ; i<event.length ; i++){
                              arr.push(event[i].id)
                              arr2.push(event[i].name)

                            }
                            setSubIndustryId(arr)
                            setSubIndustryName(arr2)
                            // document.getElementById("DropdoenData").value = arr;
                          }} // Function will trigger on select event
                          onRemove={(event)=>{
                            var arr = [];
                            var arr2 = [];
                            for(let i=0 ; i<event.length ; i++){
                              arr.push(event[i].id)
                              arr2.push(event[i].name)

                            }
                            // console.log(arr)
                            setSubIndustryId(arr)
                            setSubIndustryName(arr2)
                            //  document.getElementById("DropdoenData").value = arr;
                          }} // Function will trigger on remove event
                          //  selectedValues={mapDatas.lawyers}
                          displayValue={"name"}
                          selectedValues={selectedIndustry}
                          showCheckbox
                        />

                      </div>

                      <div className="col-md-6">
                        <label htmlFor="inputName5" className="form-label">
                          Keywords  <span style={{color:"red"}} >* </span>
                        </label>
                        <input type="text" className="form-control" id="keywords" name="keywords" onChange={(e) => setData(e.target.value)} value={data.keywords}  required />

                      </div>

                      <div className="col-md-6">
                        <label htmlFor="inputName5" className="form-label">
                          Document Type  <span style={{color:"red"}} >* </span>
                        </label>
                        <select id="documentType"  name="documentType"  className="pointer form-select"  onChange={(e) => setData(e.target.value)} value={data.documentType}>
                          <option>Letter</option>
                          <option>Agreement</option>
                          <option>Court Document</option>
                        </select>

                      </div>

                      <div className="col-md-12">
                        <label htmlFor="inputName5" className="form-label">
                          Standard Price  <span style={{color:"red"}} >* </span>
                        </label>
                        <input type="number" className="form-control" id="price" name="price" onChange={(e) => setData(e.target.value)} value={data.price}   required />

                      </div>

                      <div className="col-md-12">
                        <label htmlFor="inputEmail5" className="form-label">What is this document for?    <span style={{color: 'red'}}>* </span></label>
                        <textarea type="text" className="form-control" id="documentFor" onChange={(e) => setData(e.target.value)}  name="documentFor" rows= {5}
                                  value={data.documentFor} required>

                        </textarea>
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="inputEmail5" className="form-label">What's included <span style={{color: 'red'}}>* </span></label>
                        <textarea type="text" className="form-control" id="included" onChange={(e) => setData(e.target.value)}  name="included" rows= {5}
                                  value={data.included}
                        required>
                        </textarea>
                      </div>


                      <div className="text-center">
                        <button type="submit" className="btn submit-btn1">
                          Update
                        </button>

                        <span onClick={()=>{back()}} className="btn  submit-btn2">
                     Go Back
                    </span>


                        <span className="btn  submit-btn4" style={{float:"right"}} data-bs-toggle="modal"
                              id ="deleteButton"
                              onClick={()=>{setIdinLocal(data._id)}} data-bs-target="#verticalycentered"
                        >Delete</span>
                        {/* <span onClick={()=>{deletPlan()}}  className="btn submit-btn3">
                      Delete
                    </span> */}

                      </div>
                    </form>
                    {/* End Multi Columns Form */}
                  </div>
                }
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
      {/*<Jsfile />*/}


    </>
  );
};

export default EditLegalDocuments;
