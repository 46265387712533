import React, { lazy } from "react";
import { useState, useEffect } from "react"

/*import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";*/

//import MainChartExample from "../charts/MainChartExample.js";

const Header= lazy(() => import("../widgets/Header.js"));
 const MainDiv = lazy(() => import('../widgets/Maindiv.js'))
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
const Jsfile = lazy(() => import('../widgets/Jsfile.js'))

const Dashboard = () => {

  useEffect(() => {
      const script = document.createElement('script');
      script.src = 'assets/js/main.js';   //(This is external js url)
     
      document.body.appendChild(script);
    }, [])

  return (
    <>

      <Header />
      <MainDiv />
      <Asidebar />
      
      <Footer /> 
      <Jsfile />
     
      {/* 

        <WidgetsBrand />

      <CCard>
        <CCardBody>
          <CRow>
            <CCol sm="5">
              <h4 id="traffic" className="card-title mb-0">
                Monthly Orders
              </h4>
              <div className="small text-muted">Feb 2021</div>
            </CCol>
            <CCol sm="7" className="d-none d-md-block">
              <CButton color="primary" className="float-right">
                <CIcon name="cil-cloud-download" />
              </CButton>
              <CButtonGroup className="float-right mr-3">
                {["Day", "Month", "Year"].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === "Month"}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol>
          </CRow>
          <MainChartExample style={{ height: "300px", marginTop: "40px" }} />
        </CCardBody>
        <CCardFooter></CCardFooter>
      </CCard> */}
    </>
  );
};

export default Dashboard;
