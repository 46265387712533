import React, { useState } from "react";
import PropTypes from 'prop-types';

export const Question = ({ index, question,questionNo, onCopy, onEdit, onDelete, allQuestions, saveJumpTo, type, onToggleLogic, language,isAction,documentQuestion }) => {

  const [otherText, setOtherText] = useState("");

  const handleOtherTextChange = (value) => {
    const newOtherTexts = [...otherText];
    newOtherTexts[index] = value;
    setOtherText(newOtherTexts);
  };

  return (
    <>
      <div className={`lightgraybox questionbox-s newsingle-question ${(documentQuestion.find(c => c === question._id))?'is_selected_question':''}`}>
        <div className={`d-flex questionbox-top `}>
          <div className="d-flex question-topleftbox">
            <div className="question-middlebox">
              {question.questionType ==
              "free" ? (
                <p className="subtitle-2 semi-bold-font">
                  <b>
                  Question {questionNo} -
                  {question.question} {" "}
                    {question.is_logic?'(Has conditions applied)':''}
                  {/*{question.text_help?'('+question.text_help+')':''}*/}
                  </b>
                </p>
              ) : question.questionType ==
              "singlechoice" ? (
                <p className="subtitle-2 semi-bold-font">
                  <b>
                  Question {questionNo} -
                  {question.question} {" "}
                  {question.is_logic?'(Has conditions applied)':''}
                  {/*{question.text_help?'('+question.text_help+')':''}*/}
                  </b>
                </p>
              ):question.questionType ==
              "pagebreak" ? (
                <p className="subtitle-2 semi-bold-font page-break">
                  Page break
                </p>
              ) : (
                <p className="subtitle-2 semi-bold-font">
                  <b>
                  Question {questionNo} -
                  {question.question} {" "}
                    {question.is_logic?'(Has conditions applied)':''}
                  {/*{question.text_help?'('+question.text_help+')':''}*/}
                  </b>
                </p>)}

            </div>
          </div>
          {isAction &&
          <div className="d-flex question-topbuttonbox">
            {question.questionType !== "pagebreak" &&
              <>
                <div className="question-readmode-icon">
                    <span className="btn  submit-btn1"
                          onClick={() => {
                      if (onCopy) {
                        onCopy(index)
                      }
                    }
                    }>
                    <svg width={18} height={22} viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M12.75 16.25V19.625C12.75 20.246 12.246 20.75 11.625 20.75H1.875C1.57663 20.75 1.29048 20.6315 1.0795 20.4205C0.868527 20.2095 0.75 19.9234 0.75 19.625V6.875C0.75 6.254 1.254 5.75 1.875 5.75H3.75C4.25257 5.74966 4.7543 5.79114 5.25 5.874M12.75 16.25H16.125C16.746 16.25 17.25 15.746 17.25 15.125V10.25C17.25 5.79 14.007 2.089 9.75 1.374C9.2543 1.29114 8.75257 1.24966 8.25 1.25H6.375C5.754 1.25 5.25 1.754 5.25 2.375V5.874M12.75 16.25H6.375C6.07663 16.25 5.79048 16.1315 5.5795 15.9205C5.36853 15.7095 5.25 15.4234 5.25 15.125V5.874M17.25 12.5V10.625C17.25 9.7299 16.8944 8.87145 16.2615 8.23852C15.6286 7.60558 14.7701 7.25 13.875 7.25H12.375C12.0766 7.25 11.7905 7.13148 11.5795 6.9205C11.3685 6.70952 11.25 6.42337 11.25 6.125V4.625C11.25 4.18179 11.1627 3.74292 10.9931 3.33345C10.8235 2.92397 10.5749 2.55191 10.2615 2.23852C9.94809 1.92512 9.57603 1.67652 9.16656 1.50691C8.75708 1.3373 8.31821 1.25 7.875 1.25H6.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                     </svg>

                        Duplicate
                    </span>
                </div>

                <div className="question-readmode-icon">
                      <span className="btn  submit-btn1" onClick={() => {
                        if (onEdit) {
                          onEdit(index)
                        }
                      }
                      }>
                  <svg width={15} height={18} viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.845 12.3337L11.2967 3.88206L10.1183 2.70373L1.66667 11.1554V12.3337H2.845ZM3.53583 14.0004H0V10.4646L9.52917 0.935395C9.68544 0.779169 9.89736 0.691406 10.1183 0.691406C10.3393 0.691406 10.5512 0.779169 10.7075 0.935395L13.065 3.29289C13.2212 3.44917 13.309 3.66109 13.309 3.88206C13.309 4.10303 13.2212 4.31495 13.065 4.47123L3.53583 14.0004ZM0 15.6671H15V17.3337H0V15.6671Z" fill="white" />
                  </svg>
                   Edit
                  </span>
                </div>
              </>
            }
            <div className="ans-close-row question-readmode-icon">
                <span className="btn  submit-btn4" onClick={() => {
                  if (onDelete) {
                    onDelete(index)
                  }
                }
                }>
                <svg width={17} height={18} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M12.666 3.99935H16.8327V5.66602H15.166V16.4993C15.166 16.7204 15.0782 16.9323 14.9219 17.0886C14.7657 17.2449 14.5537 17.3327 14.3327 17.3327H2.66602C2.445 17.3327 2.23304 17.2449 2.07676 17.0886C1.92048 16.9323 1.83268 16.7204 1.83268 16.4993V5.66602H0.166016V3.99935H4.33268V1.49935C4.33268 1.27834 4.42048 1.06637 4.57676 0.910093C4.73304 0.753813 4.945 0.666016 5.16602 0.666016H11.8327C12.0537 0.666016 12.2657 0.753813 12.4219 0.910093C12.5782 1.06637 12.666 1.27834 12.666 1.49935V3.99935ZM13.4993 5.66602H3.49935V15.666H13.4993V5.66602ZM5.99935 2.33268V3.99935H10.9993V2.33268H5.99935Z" fill="white" />
               </svg>
                    Delete
                </span>
            </div>
          </div>
          }
        </div>
        <div className={`questiondescription-s`}>
          {/*<p className="survey-question-added-info">*/}
          {/*  {question.question} {" "}*/}

          {/*  /!*{(question.questionType === "free" && question.text_document) &&*!/*/}
          {/*  /!*  <>*!/*/}
          {/*  /!*    <span className="text-document">*!/*/}
          {/*  /!*      ({question.text_document})*!/*/}
          {/*  /!*    </span>*!/*/}
          {/*  /!*  </>*!/*/}
          {/*  /!*}*!/*/}

          {/*</p>*/}

          {isAction && question.text_help &&
          <span className={'question-text-help'}>
              <img className={"question-info-icon"} src="/assets/img/question-info-icon.svg" alt="img"  />
              {question.text_help}
            </span>}

        </div>

          
        {(question.questionType == "singlechoice") && question.options.map(
          (option, i) => (
              <div className={`question-optionbox singlechoice-boxes `}>
              <div className="questionreadmode-left">
                <input type="radio" id="html" name="fav_language" disabled={true} value={option} />
                <div className={`option-value`}>
                  {option} {" "}

                  {/*{(question.textDocumentOption && question.textDocumentOption[i]) &&*/}
                  {/*  <>*/}
                  {/*    <span className="text-document">*/}
                  {/*      ({question.textDocumentOption[i]})*/}
                  {/*    </span>*/}
                  {/*  </>*/}
                  {/*}*/}

                </div>
              </div>
            </div>
          )
        )}
        {(question.questionType == "multiplechoice") && question.options.map(
          (option, i) => (
            <div className="question-optionbox multiplechoice-boxes">
              <input type="checkbox" disabled={true} name="vehicle1" value={option} />

              <div className={`option-value`}>
                {option} {" "}

              </div>
            </div>
          )
        )} 
        {question?.is_add_other &&  !isAction &&
        <div className="question-optionbox my-2">
          <input placeholder="Enter Free Text" disabled/>
          </div>
        }                
      </div>
    </>
  )
}
