import React, { Component } from "react";
import { useState, useEffect } from "react"
import { BrowserRouter, Route, Switch  } from "react-router-dom";
import Login from "./views/auth/login";
import Dashboard from "./views/dashboard/Dashboard";
import Customer from "./views/user/Customer";
import Plan from "./views/user/Plan";
import AddCust from "./views/user/AddCust";
import ViewCust from "./views/user/CustmerView.js";
import Lawyer from "./views/user/Lawyers";
import ViewLawyer from "./views/user/ViewLawyer";
import LawyerAdd from "./views/user/LawyerAddEdit";

import AddPlans from "./views/user/PlanAddEdit";
import AdminProfile from "./views/admin/AdminView";

import OrderAndCuns from "./views/admin/OrderAndCuns";
import Consultations from "./views/admin/Consultations";

import LegalServices from "./views/services/LegalServices";
import AddServices from "./views/services/AddServices";
import EditServices from "./views/services/EditServices";

import LegalDocuments from "./views/legalDocuments/LegalDocuments";
import AddLegalDocuments from "./views/legalDocuments/AddlegalDocuments";
import EditLegalDocuments from "./views/legalDocuments/EditLegalDocuments";
import ViewLegalDocuments from "./views/legalDocuments/ViewLegalDocuments";
import ViewLegalDocumentQuestionnaire from "./views/legalDocuments/ViewLegalDocumentQuestionnaire";
import ViewCategories from "./views/category/ViewCategories";
import AddEditCat from "./views/category/AddCategories";

//blogs
 import Blogs from "./views/blogs/Blogs";
 import AddBlogs from "./views/blogs/AddBlogs";
 import UpcomingPases from "./views/upcomingPages/Uplocoing.js";
 import Pricing from "./views/pricing/Pricing.js";
 import AddEditPricing from "./views/pricing/AddEditPricing.js";

 import AddBlogVideos from "./views/blogs/blogVideo/AddBlogVideo.js";
 import ListBlogVideos from "./views/blogs/blogVideo/ListBlogVideo.js";


 import LegalDocAddEditCat from "./views/legalDocuments/Category/AddCategory";
 import LegalDocCategories from "./views/legalDocuments/Category/ViewCategories";
 import LegalDocAddEditSubCat from "./views/legalDocuments/Category/AddSubCategory";
 import LegalDocSubCat from "./views/legalDocuments/Category/ViewSubCategory";

 import OrderHistory from "./views/orderHistory/Order";
 import Consultation from "./views/consultaion/Consultation";
 import CompleteConsultation from "./views/consultaion/CompeteConsultion";
 import BookingDetail from "./views/consultaion/BookingDetail";

 import ViewCoupon from "./views/coupon/ViewCoupon";
 import AddCoupon from "./views/coupon/AddCoupon";

 import Reviews from "./views/reviews/Reviews";
 import AddReviews from "./views/reviews/AddReview";
import IdleLogout from "./views/common/IdleLogout"




// import "./scss/style.scss";
import "./config";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);


  const App = () => {

    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'assets/vendor/bootstrap/js/bootstrap.bundle.min.js';   //(This is external js url)
      document.body.appendChild(script);

     }, [])

// IdleLogout 15mins
const handleLogout = () => {
  // Perform your logout action here
  console.log("User logged out");
  localStorage.clear();
  sessionStorage.clear();
};

    return (


      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <IdleLogout timeout={600} onLogout={handleLogout} />;

          <Switch>
            <Route exact={true} path="/" component={() => <Login />} />
            <Route path="/dashboard"   component={() => <Dashboard  />} />
            <Route path="/customer"   component={() => <Customer  />} />
            <Route path="/lawyer"   component={() => <Lawyer  />} />
            <Route path="/membership"   component={() => <Plan  />} />
            <Route path="/addcustomer"   component={() => <AddCust type="Add" />} />
            <Route path="/viewcustomer"   component={() => <ViewCust />} />
            <Route path="/editcustomer"   component={() => <AddCust type="Edit" />} />
            <Route path="/addlawyer"   component={() => <LawyerAdd type="Add" />} />
            <Route path="/viewlawyer"   component={() => <ViewLawyer  />} />
            <Route path="/addplans"   component={() => <AddPlans type="Add" />} />
            <Route path="/editplans"   component={() => <AddPlans type="Edit" />} />
            <Route path="/usersprofile"   component={() => <AdminProfile />} />
            <Route path="/legalservice"   component={() => <LegalServices  />} />
            <Route path="/addservices"   component={() =>  <AddServices type="Add"/>} />
            <Route path="/editservices"   component={() =>  <EditServices type="Edit"/>} />

            <Route path="/order"   component={() => <OrderAndCuns />} />
            {/* <Route path="/consultations" component={() => <Consultations />} /> */}
            <Route path="/category" component={() => <ViewCategories />} />
            <Route path="/add-cat" component={() => <AddEditCat  type="Add"/>} />
            <Route path="/edit-cat" component={() => <AddEditCat  type="Edit"/>} />

            <Route path="/blogs" component={() => <Blogs />} />
            <Route path="/add-blogs" component={() => <AddBlogs type="Add"/>} />
            <Route path="/edit-blog" component={() => <AddBlogs type="Edit"/>} />

            <Route path="/add-blog-video" component={() => <AddBlogVideos type="Add"/>} />
            <Route path="/edit-blog-video" component={() => <AddBlogVideos type="Edit"/>} />
            <Route path="/blog-video" component={() => <ListBlogVideos />} />



            <Route path="/legaldoc" component={() => <LegalDocuments />} />
            <Route path="/add-legal-document" component={() => <AddLegalDocuments type="Add"/>} />
            <Route path="/edit-legal-document" component={() => <EditLegalDocuments type="edit"/>} />
            <Route path="/legal-document/:id/information" component={() => <EditLegalDocuments />} />
            <Route path="/legal-document/:id/questionnaire" component={() => <ViewLegalDocumentQuestionnaire />} />
            <Route path="/legal-document/:id/document" component={() => <ViewLegalDocuments />} />

            <Route path="/BlogCategory" component={() => <UpcomingPases type=""/>} />

            <Route path="/pricing" component={() => <Pricing />} />
            <Route path="/add-pricing" component={() =>  <AddEditPricing type="Add"  />} />
            <Route path="/DocCategory"  component={() =>  <LegalDocCategories />} />
            <Route path="/add-doc-cat"  component={() =>  <LegalDocAddEditCat type="Add" />} />
            <Route path="/edit-doc-cat/:id/info"  component={() =>  <LegalDocAddEditCat type="Edit" />} />
            <Route path="/docSubCategory/:id/info"  component={() =>  <LegalDocSubCat type="Add" />} />
            <Route path="/add-doc-sub-cat/:id/info"  component={() =>  <LegalDocAddEditSubCat type="Add" />} />
            <Route path="/edit-doc-sub-cat/:id/info"  component={() =>  <LegalDocAddEditSubCat type="Edit" />} />
            <Route path="/order-history"  component={() =>  <OrderHistory />} />
            <Route path="/consultations"  component={() =>  <Consultation />} />
            <Route path="/complete-consultations"  component={() =>  <CompleteConsultation/>} />
            <Route path="/booking-detail/:id/info"  component={() =>  <BookingDetail/>} />

            {/* // coupon */}
            <Route path="/coupon"  component={() =>  <ViewCoupon/>} />
            <Route path="/add-coupon"  component={() =>  <AddCoupon type="Add" />} />
            <Route path="/edit-coupon/:id/info"  component={() =>  <AddCoupon type="Edit" />} />

            <Route path="/reviews"  component={() =>  <Reviews/>} />
            <Route path="/add-review"  component={() =>  <AddReviews type="Add" />} />
            <Route path="/edit-review/:id/info"  component={() =>  <AddReviews type="Edit" />} />



            </Switch>
        </React.Suspense>

      </BrowserRouter>

    );

}

export default App;
