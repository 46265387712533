import React, { useEffect, useState } from "react";

export const QuestionCard = ({questiontype, onClick}) => {
  return (
    <div className="question-card-wrapper m-3" onClick={onClick}>
      <div className="question-card-inner">
        <p>{questiontype}</p>
      </div>
    </div>
  )
}
