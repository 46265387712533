import React , { lazy } from 'react'
import { useState, useEffect } from "react"
import Config from '../../config';
import { useHistory,useParams } from "react-router-dom";
 const Popup = lazy(() => import('../widgets/DeletePop.js'))
 const Header= lazy(() => import("../widgets/Header.js"));
 const Asidebar = lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))

const ViewCoupon = (props) => {

 const {id} = useParams();
 const [data ,setData] = useState([]);
 //const [toast, setToast] = useState(true);
 const [MemberType,setMemberType] = useState(1);

 const catData =  () =>{
    Config.get(`/payment/coupon/info?id=${id}`)
    .then((res) => {
    //  console.log(res);
        if (res.status) {
        setData(res.data)
        document.getElementById("validity").value = Config.date(res.data.validity);
        }else{
        Config.showToast("Please try after some time.");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });

  }




  function handleChange(e) {
    setData(e.target.value);
    // alert(e.target.value)
    if(e.target.value==="Membership" || e.target.value === "All"){
      setMemberType(1)
    }else{  setMemberType(0)}
  }

    const setIdinLocal = (id)=>{    
    localStorage.setItem("couponId",id);
    localStorage.setItem("deleteType","coupon");
    }
      const back = () =>{
      history.push("/coupon");
      }

  
   useEffect(() => {
    // alert()
    const script = document.createElement('script');
    script.src = '/assets/js/main.js';   //(This is external js url)   
    document.body.appendChild(script);
    if(props.type== "Edit"){
      catData()
     document.getElementById("deleteButton").style.display = "block"
    }
    }, [])
    

  const history = useHistory();
  // add edit data
    async function AddPlan(e) {
          e.preventDefault();

           //    alert()  
          //     if(e.target.duration_in_months.value<0 ||  e.target.duration_in_months.value>12){
         //      return Config.showToast("Invalid duration_in_months");
        //       }  
       //  productType,name,couponCode,discountType,discount,validity,numberOfUsages
             var name = e.target.name.value, 
             productType =  e.target.productType.value,
             couponCode =  e.target.code.value,
             discountType = e.target.discountType.value,
             discount = parseFloat(e.target.discount.value),     
             stripeId = e.target.stripeId.value,
             validity =  new Date(e.target.validity.value).getTime(),
             numberOfUsages = parseInt(e.target.numberOfUsages.value)
   

        if(props.type == "Add"){
         var data = {productType,name,couponCode,discountType,discount,validity,numberOfUsages};
            Config.post('/payment/coupon',data)
              .then((res) => {
              console.log(res);
                if (res.status) {
                  history.push("/coupon");
                  }else{
                  Config.showToast("somthing wrong");
                  }
                })
              .catch((err) => {
                Config.showToast("Please try after some time.");
              });

            }else {
            
              
              // call edit api 
               var data = {id,productType,name,couponCode,discountType,discount,validity,numberOfUsages,stripeCouponId:stripeId};

                Config.put('/payment/coupon',data)
                .then((res) => {
                  console.log(res);
                  if (res.status) {
                     
                    history.push("/coupon");
                    }else{
                    Config.showToast("somthing wrong ");
                    }
                  })
                .catch((err) => {
                  Config.showToast("Please try after some time.");
                });



         }

      }
  


  return (
    <>
      <Jsfile />
      <Header />
 <main id="main" className="main">
  <section className="section">
  <Popup />
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{props.type} Coupon </h5>
            {/* Multi Columns Form */}

          

            <form className="row g-3" method='post' onSubmit={AddPlan}>
            <div className="col-md-4">
                 <label htmlFor="inputName5" className="form-label">
                   Product Type
                  <span style={{color:"red"}} >* </span>
                </label>
                <select onChange={handleChange} className="pointer form-control form-select" id="productType" name="productType"  value={data.productType} >
                 <option value='Membership'> Membership </option>
                 <option value='Cart'>Cart </option>
                 <option value='All'> All  </option>
                 {/* <option value='Document'> All </option> */}
                 {/* <option value='Renewal'> Renewal </option>*/}
               </select>
              </div>
            
              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                 Name  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="name" onChange={(e) => setData(e.target.value)} value={data.name}  required/>
              </div>

              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                 Code  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="code" onChange={(e) => setData(e.target.value)} value={data.couponCode}  required disabled={props.type == "Add"?false:true}/>
                </div>

              <div className="col-md-4">
                 <label htmlFor="inputName5" className="form-label">
                 Discount Type
                <span style={{color:"red"}} >* </span>
                </label>
                <select onChange={(e)=> setData(e.target.value)} className="pointer form-control form-select" id="discountType" name="discountType"  value={data.discountType} disabled={props.type == "Add"?false:true} >
                 <option value='In%'> In % </option>
                 <option value='FixedPrice'> Fixed Price </option>
                </select>
              </div>
             
              <div className="col-md-4">
                 <label htmlFor="totalAmt" className="form-label">
                 Discount
                 <span style={{color:"red"}} >* </span>
                </label>
                <input type="number" min="1" max="100"  className="form-control" id="discount" onChange={(e) => setData(e.target.value)} value={data.discount} required disabled={props.type == "Add"?false:true}/>
              </div>

              <div className="form-group col-lg-4 ">
                 <label htmlFor="Member Since" className=" form-label">
                  Validity
                </label>
                <input
                  placeholder="Select date"
                  type="date"
                  id="validity"
                  onChange={(e) => setData(e.target.value)} 
                  // value={data.validity}
                  min="2022-09-27"
                  className="form-control datepicker"
                  // onChange={(e) => setData(e.target.value)}
                  defaultValue={Config.date(data.validity)}
                  // disabled
                  
                />
              </div>

              {/* <div className="col-md-4">
                <label htmlFor="totalAmt" className="form-label">
                validity
                  <span style={{color:"red"}} >* </span>         
                </label>
                <input type="number" step="any" className="form-control" id="validity" onChange={(e) => setData(e.target.value)} value={data.validity} required/>
              </div> */}


              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                Number of Usages
                  <span style={{color:"red"}} >* </span>
                </label>
                <input  type="number" min="1" max="500000" className="form-control" id="numberOfUsages" onChange={(e) => setData(e.target.value)} value={data.numberOfUsages} required disabled={props.type == "Add"?false:true}/>
              </div>

              <input style={{display:"none"}}  type="text" className="form-control" id="stripeId" name="stripeId" onChange={(e) => setData(e.target.value)} value={data.stripeCouponId}  />
       
        
                  <div className="text-center">
                  <button type="submit" className="btn submit-btn1">
                  {props.type== "Edit" ? "Update": "Submit" }
                  </button>
                  <span onClick={()=>{back()}} className="btn  submit-btn2"> Go Back</span>
                  <span className="btn  submit-btn4" style={{float:"right", display:"none"}} data-bs-toggle="modal" 
                   id ="deleteButton"
                   onClick={()=>{setIdinLocal(id)}} data-bs-target="#verticalycentered"
                   >Delete</span>
              
              </div>
            </form>
            {/* End Multi Columns Form */}
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
 
      <Asidebar />
      <Footer /> 
    
</>

 )
}

export default ViewCoupon
