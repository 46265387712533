import React, { lazy } from "react";
import { useState, useEffect } from "react"
import { useParams , useHistory} from "react-router-dom";
import Config from '../../config';
import Asidebar from '../widgets/Asidebar.js'
import TimeSlot from "./TimeSlot";

const Header= lazy(() => import("../widgets/Header.js"));
//  const Asidebar = lazy(() => import('../widgets/Asidebar.js'))
const Footer = lazy(() => import('../widgets/Footer.js'))
const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
// cosst TimeSlot = lazy(() => import('.widgets/Jsfile.js'))


const BookingDetail = () => {
  const {id} = useParams();
  const [Page,SetPage] = useState("Booking");
  const [data ,setData] = useState({});
  var monthNames = [ "Fagun", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
  
 
  const customerData =   () =>{
   
    Config.get(`/admin/get-consultation-id?_id=${id}`)
      .then((res) => {
        if (res.status) {

          console.log(res.data)
          setData(res.data)
          //displayClock()
         

        }else{
          Config.showToast("server error ");
        }
      })
      .catch((err) => {
        Config.showToast("Please try after some time.");
      });

  }

  
  useEffect(() => {
    
    const script = document.createElement('script');
    script.src = '/assets/js/main.js';   //(This is external js url)   
    document.body.appendChild(script);
    customerData()
  }, [])
  
  return (
    <>
      <Jsfile />
      <Header />

      <>

      <main id="main" className="main">
        <section className="section profile mt-20">

          <div className={Page == "Booking" ?"row": "d-none" } id="edit_customer">
            <div className="col-lg-12">
              <h5 className="card-title"> Booking Details </h5>
              <div className="pagetitle">
                <div>
                  <a href="#" onClick={()=>{SetPage("Booking")}} className="view_customers_btn"> Booking Information </a>
                  <a href="#" onClick={()=>{SetPage("Customer")}} className="Consultation_btn"> Customer Information </a>
                </div>
              </div>{/* End Page Title */}
              <div className="card">
                <div className="card-body booking">
                  {/* Multi Columns Form */}
                  <form className="row booking_box">
                    <div className="col-md-4 booking_div">
                      <label htmlFor="inputName5" className="form-label">Date of booking</label>
                      <p>{data.bookingDate===undefined?'':Config.myDate(new Date(data.bookingDate).toDateString())} </p>
                    </div>
                    <div className="col-md-4 booking_div">
                      <label htmlFor="inputName5" className="form-label">Lawyer Name</label>
                      <p>{data.lawyerName===undefined?'':data.lawyerName+ ' ' + data.lawyerLastName}</p>
                    </div>
                    <div className="col-md-4 booking_div">
                      <label htmlFor="inputName5" className="form-label">Booking ID</label>
                      <p>{data.orderId===undefined?'':data.orderId}</p>
                    </div>
                    <div className="col-lg-4 booking_div">
                      <label htmlFor="inputName5" className="form-label">Appointment Date</label>
                      <p>{data.selectedDay===undefined?'':data.selectedDay+' '+ monthNames[data.selectedMonth] +' '+ data.selectedYear}</p>
                    </div> 
                    <div className="col-lg-4 booking_div">
                      <label htmlFor="inputName5" className="form-label">Appointment time</label>
                      <p>{data.bookingTime===undefined?'':data.bookingTime}</p>
                    </div>
                    <div className="col-lg-4 booking_div">
                      <label htmlFor="inputName5" className="form-label">Payment Details</label>
                      <p><b>Total</b>: £  {data.totalAmount===undefined?'':data.totalAmount}</p>
                    </div>
                    
                    {/* <div style={data.activity=="complete"? {display:"none"}: {display:"block"}} className="col-lg-10 booking_div">
                    <div className="col-md-3 col-sm-4 col-xs-12 agreement_down">
                    <label htmlFor="inputName5" className="form-label">Meeting link will appear here</label>
                    <a className="btn  btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3 build_connect_btn Status_pending" href="#" target="_self">
                    <svg xmlns="http://www.w3.org/2000/svg" width={6} height={7} viewBox="0 0 6 7" fill="none">
                    <rect y="0.0996094" width={6} height={6} rx={3} fill="#FE1DB3" />
                    </svg>{Math.floor(fakeHour)}:{Math.floor(fakeMin)}:{Math.floor(fakeSec)}</a></div> */}

                   
                      {/* <label htmlFor="inputName5" className="form-label">Meeting Link</label>
                      <p>{data.zoomLink == undefined || !data.zoomLink? <a>Link appeare before 10 min of consultaion time </a> : data.zoomLink}</p> */}
                    {/* </div> */}

    { data.activity=="booked" && <div className="col-lg-10 booking_div">        
   <TimeSlot Month={data.selectedMonth} Day={data.selectedDay} Year={data.selectedYear} slotId={data.slotId} zoomLink={data.zoomHostLink}/>
      
       </div> }

           </form>{/* End Multi Columns Form */}
                </div>
              </div>
            </div>
          </div>
          <div className={Page == "Customer" ?"row": "d-none" } id="Consultation">
            <div className="col-lg-12">
              <h5 className="card-title"> Booking Details </h5>
              <div className="pagetitle">
                <div>
                  <a href="#" onClick={()=>{SetPage("Booking")}} className="view_customers_btn"> Booking Information </a>
                  <a href="#" onClick={()=>{SetPage("Customer")}}  className="Consultation_btn"> Customer Information </a>
                </div>
              </div>{/* End Page Title */}
              {/* Left side columns */}
              <div className="card">
                <div className="card-body booking">
                  <form className="row booking_box">
                    <div className="col-md-4 booking_div">
                      <label htmlFor="inputName5" className="form-label">Customer Name</label>
                      <p>{data.userName===undefined?'':data.userName}</p>
                    </div>
                    <div className="col-md-4 booking_div">
                      <label htmlFor="inputName5" className="form-label">Email Id</label>
                      <p>{data.userEmail===undefined?'':data.userEmail}</p>
                    </div>
                    <div className="col-md-4 booking_div">
                      <label htmlFor="inputName5" className="form-label">Contact Number</label>
                      <p>{data.userMobile===undefined?'':data.userMobile}</p>
                    </div>
                    <div className="col-lg-4 booking_div">
                      <label htmlFor="inputName5" className="form-label">Customer Address</label>
                      <p>{data.userAddress===undefined?'':data.userAddress}</p>
                    </div>
                  </form>{/* End Multi Columns Form */}
                </div>
              </div>
              {/* End Left side columns */}
            </div>
          </div>
        </section>
      </main>
      </>


      <Asidebar />
      <Footer />

    </>
  );
};

export default BookingDetail;
