import React, { lazy } from "react";
import { useState, useEffect ,useRef} from "react"
import { Link , useHistory} from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import JoditEditor from 'jodit-react';
import Config from '../../config';

 const Header= lazy(() => import("../widgets/Header.js"));
 const ViewButton = lazy(() => import("../widgets/LawyerviewButton.js"));
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
 const Popup = lazy(() => import('../widgets/DeletePop.js'))
 const ProfileImage = lazy(() => import('../widgets/ProfileImage'))
 const Videopreview = lazy(() => import('../widgets/Videopreview'))



 const ViewLawyer = () => {



  const [options ,setDropDown] = useState();
  const [qualfication,setQualification] = useState("");
  const [qualificationOverView, setQuliyOver] = useState("");
  const [shortBio, setShortBio] = useState("");
  const editor = useRef(null);
  const [consultationHistory,setHistory] = useState([]);


  const lawyerConHistory = () =>{
    const id = localStorage.getItem("lawyerID")

    Config.get(`/admin/lawyer-time?lawyerId=${id}`)
    .then((res) => {
     console.log(res);
        if (res.status) {
          setHistory(res.data)
          // alert()

      }else{
        Config.showToast("Please try after some time.");
      }
      })
    .catch((err) => {
      Config.showToast("Please try after some time.");
     });

    }





  const  categoriesDropDown =  () =>{

    Config.get(`/admin/legal-services?limit=${50}&page=${1}`)
    .then((res) => {
     console.log(res);
        if (res.status) {
        setDropDown(res.data)
          // alert()

      }else{
        Config.showToast("Please try after some time.");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
     });

    }



     const [val,setVal] = useState([{content:"",clientName:"",companyName:""}])
     const handleAdd = ()=>{
      const abc = [...val,[]]
      setVal(abc)
      }


  const handleChange = (onChangeValue,i)=>{
  const inputData = [...val]
  const testi = {}

  testi.content =  document.getElementById("testimonials"+i).value;
  testi.clientName =  document.getElementById("clientName"+i).value;
  testi.companyName = document.getElementById("companyName"+i).value;

  inputData[i] = testi;
  setVal(inputData)

     }

const handleDelete = (i)=>{

    if(val.length>1){
      const deleteVal = [...val]
      deleteVal.splice(i,1)
      setVal(deleteVal)
      }

    }



   const editSevice = (id)=>{
    localStorage.setItem("servicesId",id);
    history.push('/editservices');
    }

          const history = useHistory();
         //const history = useHistory();
         const id = localStorage.getItem("lawyerID")


         var testimonials  = JSON.stringify(val)
         async function editlawyer(e) {
                e.preventDefault();
//                 var phoneno = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/
//                  if(e.target.mobile.value.match(phoneno) ){

//                  }
//                 else
//                 {
//                 return  Config.showToast("Please enter a valid mobile number."
// );

//                  }

        var drop = e.target.DropdoenData.value.split(',')
        let category = JSON.stringify(drop)
        let formData = new FormData();
        formData.append('_id' ,id)
        formData.append('name' ,e.target.name.value)
        formData.append('lastName' , e.target.lastName.value)
        formData.append('email', e.target.email.value)
        formData.append('phone' , e.target.mobile.value)
        formData.append('qualfication' , qualfication)
        formData.append('qualificationOverView' , qualificationOverView)
        formData.append('expertise' , "not in use")
        formData.append('expertiseContent' ,  e.target.expertiseContent.value)
        formData.append('password' , '12345')
        formData.append('position' , e.target.position.value)
        formData.append('secondaryPosition' , e.target.secPosition.value)
        formData.append('shortbio' ,shortBio)
        formData.append('testimonials',testimonials)
        formData.append('category',category)
        formData.append('city', e.target.city.value)
        formData.append('state', e.target.state.value)
        formData.append('zipCode', e.target.zip.value)
        formData.append('addressTwo', e.target.address2.value)
        formData.append('correspondence' , e.target.correspondence.value)
        formData.append('profilePicture',  e.target.profile.value)
        // formData.append('multi', e.target.bannerVideo.files[0])
        formData.append('bannerVideo' , e.target.bannerVideourl.value)
        formData.append('services' , e.target.services.value)




          Config.put('/admin/lawyers',formData)
             .then((res) => {
              console.log(res);
                if (res.status) {


                history.push("/lawyer")

                }else{
                Config.showToast("somthing wrong .");
                }
                })
              .catch((err) => {
                Config.showToast("Email or contact already exists .");
            });

            }



          const [data ,setData] = useState([]);
          const [services ,setServices] = useState([]);
          var data2 = data;
          const categoriesList = [];


          if(data.category!=undefined){
            data.category.map((m)=>{
              categoriesList.push({name:m})

            })
          }



               const customerData =  () =>{
               const id = localStorage.getItem("lawyerID")

                Config.get(`/admin/lawyers/id?_id=${id}`)
                .then((res) => {
                console.log(res);
                if (res.status) {
                  document.getElementById('Browse').className = "upload-project-img img_zoom"
                  document.getElementById("mainscr").src = res.data.profilePicture==''?"assets/img/preview_1.png":res.data.profilePicture
                  document.getElementById("ifram").src = res.data.bannerVideo==''?"assets/img/video.gif": res.data.bannerVideo
                  document.getElementById("bannerVideourl").value = res.data.bannerVideo
                  document.getElementById("bannerVideourl").disabled = res.data.bannerVideo == ''? false : true ;
                  document.getElementById("profile").value = res.data.profilePicture;
                  document.getElementById("image").disabled = true
                  document.getElementById("flexSwitchCheckDefault").disabled = res.data.bannerVideo == ''? false : true ;
                  setData(res.data)
                  setVal(res.data.testimonials)

                     }else{

                    Config.showToast("server error!!! ");
                   }

                  })
                .catch((err) => {
                Config.showToast("Please try after some time.");
                });



                Config.get(`/admin/legal-services-lawyerId?lawyerId=${id}`)
                .then((res) => {
                 console.log(res);
                  if (res.status) {

                    // localStorage.setItem("userInfo", JSON.stringify(res.data));
                    // localStorage.setItem("token", res.data.token);
                    // history.push("/dashboard");

                    console.log(res.data)
                    setServices(res.data)

                  }else{
                    Config.showToast("server error!!! ");
                  }
                })
                .catch((err) => {
                  Config.showToast("Please try after some time.");
                });





           }

           const setIdinLocal = (id)=>{
            localStorage.setItem("lawyerID",id);
            localStorage.setItem("deleteType","l");

            }






           const sendMail = (email,name)=>{
            const data = {_id:id , email,name}
            Config.post('/admin/resend-lawyer-email', data)
            .then((res) => {
            console.log(res);
            if (res.status) {
             Config.showToast("The activation link has been sent to the user's registered email. ")
              }else{
               Config.showToast("server error!!! ");
            }
            })
            .catch((err) => {
            Config.showToast("Please try after some time.");
            });
           }

           const getAddress = async (zipCode) => {
            const rawResponse = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${Config.MapAPI}`,
              {
                method: "GET",
              }
            );
            const data = await rawResponse.json();
            console.log("data", data);
            let city = document.getElementById("city");
            let state = document.getElementById("state");
            let correspondence = document.getElementById("correspondence");
            let addressTwo = document.getElementById("address2");
            if (data.status === "OK" && data?.results[0]?.address_components.length) {
              addressTwo.value = "";
              data.results[0].address_components.map((item) => {
                if (item.types.includes("postal_town")) {
                  console.log("val1", item.long_name, city);
                  city.value = item.long_name;
                } else if (item.types.includes("administrative_area_level_1")) {
                  console.log("val2", item.long_name);
                  state.value = item.long_name;
                }
              });
              if (data?.results[0]?.formatted_address) {
                correspondence.value = data?.results[0]?.formatted_address;
              }
            } else if(data.status === "ZERO_RESULTS") {
              Config.showToast("Please enter a valid Zip Code.", "danger");
              city.value = "";
              state.value = "";
              correspondence.value = "";
              addressTwo.value = "";
            }
          }





        useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/main.js';   //(This is external js url)
        document.body.appendChild(script);

        customerData()
        categoriesDropDown()
        lawyerConHistory()


        }, [])

  return (
    <>

      <Header />

      <>
  <main id="main" className="main">

    {/* End Page Title */}
    <section className="section profile mt-20 dashboard">

    <Popup />


      <div className="row" id="edit_customer">
        <div className="col-lg-12">
          <h5 className="card-title"> Lawyer Details - {data2.name} </h5>
          <ViewButton />
          <div className="card">
            <div className="card-body">
              {/* Multi Columns Form */}
              <form className="row g-3 lawyer_div"  onSubmit={editlawyer}>

              <div className="col-md-12">
              <h4>Personal Details</h4>
              </div>

              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                   First Name  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="name" name="name"   onChange={(e) => setData(e.target.value)}  value={data.name} required/>
              </div>

              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                  Last Name <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="lastName" name="lastName"  onChange={(e) => setData(e.target.value)} value={data.lastName}  required />
              </div>
              <div className="col-lg-4 ">
                <label htmlFor="Phone" className=" form-label">
                  Phone  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text"  className="form-control" id="mobile" name="mobile"  onChange={(e) => setData(e.target.value)} value={data.phone}  required/>
              </div>

              <div className="col-md-6">
                <label htmlFor="inputEmail5" className="form-label">
                  Email  <span style={{color:"red"}} >* </span>
                </label>
                <input type="email" className="form-control" id="email"  onChange={(e) => setData(e.target.value)} value={data.email} required/>
              </div>

              <ProfileImage />

              {/* section 2  */}
              <div className="col-md-12">
               <h4>Educational Details </h4>
              </div>

              <div className="form-group col-lg-6">
                <label htmlFor="categories" className=" form-label">
                Qualification
                </label>

                <JoditEditor
			          ref={editor}
			          value={data.qualfication}
		           	// config={config}
                tabIndex={1} // tabIndex of textarea
                onChange={newContent => setQualification(newContent)} // preferred to use only this option to update the content for performance reasons
                // onChange={newContent => {}}
                />

                {/* <input type="text" className="form-control " id="qualfication" name="qualfication"  onChange={(e) => setData(e.target.value)} value={data.qualfication}  /> */}

              </div>

             {/* mark  */}
              <div className="form-group col-lg-6">
                <label htmlFor="categories" className=" form-label">
                Qualification Overview
                </label>
                <JoditEditor
			          ref={editor}
			          value={data.qualificationOverView}
		           	// config={config}
                tabIndex={1} // tabIndex of textarea
                onChange={newContent => setQuliyOver(newContent)} // preferred to use only this option to update the content for performance reasons
                // onChange={newContent => {}}
                />
                {/* <input type="text" className="form-control " id="qualificationOverView" name="qualificationOverView" onChange={(e) => setData(e.target.value)} value={data.qualificationOverView} /> */}
               </div>


                 {/* section 3  */}
               <div className="col-md-12">
               <h4>Area Of Expertise</h4>
              </div>

              <div className="col-md-6">
                <label htmlFor="inputName5" className="form-label">
                  Position <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="position"  onChange={(e) => setData(e.target.value)} value={data.position} required/>
              </div>

              <div className="col-md-6">
                <label htmlFor="inputName5" className="form-label">
                Secondary Position <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="secPosition"  onChange={(e) => setData(e.target.value)} value={data.secondaryPosition} required/>
              </div>

              {/* <div className="form-group col-lg-8">
                <label htmlFor="categories" className=" form-label">
                Area Of Expertise
                </label>
                <input type="text" className="form-control" id="expertise" name="expertise"   onChange={(e) => setData(e.target.value)}
                 value={data.expertise} />

              </div> */}



            {/* multi select dropdown */}

                <div className="form-group col-lg-6">
                <label htmlFor="categories" className=" form-label">
                Areas of Practice
                </label>

                <input type="text" style={{display:"none"}}  className="form-control" id="DropdoenData" name="DropdoenData"   onChange={(e) => setData(e.target.value)}
                 value={data.category}  />

              <Multiselect

              options={options} // Options to display in the dropdown
              onSelect={(event)=>{
              var arr = [];
              for(let i=0 ; i<event.length ; i++){
                arr.push(event[i].name)

              }
              document.getElementById("DropdoenData").value = arr;
              }} // Function will trigger on select event
              onRemove={(event)=>{
              var arr = [];
              for(let i=0 ; i<event.length ; i++){
                arr.push(event[i].name)

              }
              // console.log(arr)
              document.getElementById("DropdoenData").value = arr; }} // Function will trigger on remove event
              selectedValues={categoriesList}
              displayValue={"name"}
              showCheckbox
              />

              </div>

              <div className="form-group col-lg-6">
                <label htmlFor="categories" className=" form-label">
                Services
                </label>
                <input type="text" className="form-control" id="services" name="services"
                onChange={(e) => setData(e.target.value)} value={data.services} />

              </div>






              <div className="col-lg-12 ">
                <label htmlFor="Phone" className=" form-label">
                Short Bio
                </label>

                <JoditEditor
			          ref={editor}
			          value={data.shortbio}
		           	// config={config}
                tabIndex={1} // tabIndex of textarea
                onChange={newContent => setShortBio(newContent)} // preferred to use only this option to update the content for performance reasons
                // onChange={newContent => {}}
                />
                {/* <textarea type="text"  className="form-control" id="shortBio" name="shortBio" onChange={(e) => setData(e.target.value)} value={data.shortbio}  >
                </textarea> */}
              </div>


              <div className="form-group col-lg-12">
                <label htmlFor="categories" className=" form-label">
                Expertise Content
                </label>
                <textarea type="text" className="form-control" id="expertiseContent" name="expertiseContent"   onChange={(e) => setData(e.target.value)} value={data.expertiseContent} >
                </textarea>

              </div>

                {/* section 5  */}
                <div className="col-md-12">
                  <h4>Address</h4>
                  </div>



              <div className="col-lg-6 ">
                <label htmlFor="Phone" className=" form-label">
                Correspondence Address
                </label>
                  <input type="text" id="correspondence" name="correspondence" className="form-control"  onChange={(e) => setData(e.target.value)} value={data.correspondence} />
               </div>
              <div className="col-6">
                <label htmlFor="inputAddress2" className="form-label">
                  Street Address 2
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address2"
                  placeholder="Apartment, studio, or floor"
                  value={data.addressTwo}
                  onChange={(e) => setData(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputCity"  className="form-label">
                  City
                </label>
                <input type="text" className="form-control" id="city" onChange={(e) => setData(e.target.value)}
                value={data.city}/>
              </div>
              <div className="col-md-6">
                <label htmlFor="inputState" className="form-label">
                      Country
                </label>
                <input type="text" className="form-control" id="state" onChange={(e) => setData(e.target.value)} value={data.country}/>
                {/* <label htmlFor="inputState" className="form-label">
                  State
                </label>
                <select id="state" className="form-select"  onChange={(e) => setData(e.target.value)} value={data.state}>
                  <option selected="">Aberdeen</option>
                  <option>Armagh</option>
                  <option>Wales</option>
                </select> */}
              </div>
              <div className="col-md-6">
                <label htmlFor="zip" className="form-label">
                  Zip 
                </label>
                <input type="text" className="form-control" id="zip"    onChange={(e) => {setData(e.target.value);if(e.target.value.length>3)getAddress(e.target.value);}} value={data.zipCode}/>
              </div>



                   {/* section 5  */}
                  <div className="col-md-12">
                  <h4>Testimonial Details</h4>
                  </div>


                 <div className="col-lg-12" id="testi">
                   <label htmlFor="Testimonials" className=" form-label">
                 Testimonials  <span style={{color:"red"}} >* </span>
                 <span onClick={()=>{handleAdd()}} className="btn">
                 <i className="bi bi-plus-square-fill"></i>
                  </span>

                </label>
               {val.map((data,i)=>{
                return ( <>
                  <div className="row">
               <div className="col-lg-5">
                <textarea value={data.content} onChange={e=>handleChange(e,i)} rowspan='2' type="text"
                id={"testimonials"+i} name={"testimonials"+i} className="form-control"   placeholder="testimonials" required>
                </textarea>
                </div>
                <div className="col-lg-3">
                <input type="text" value={data.clientName}   onChange={e=>handleChange(e,i)}
                 id={"clientName"+i} name={"clientName"+i}   className="form-control" placeholder="client name"  required/>
                </div>
                <div className="col-lg-3">
                <input type="text" value={data.companyName}   onChange={e=>handleChange(e,i)}
               id={"companyName"+i} name={"companyName"+i} placeholder="company name" className="form-control"  required/>
                </div>
                {/* <div className="col-lg-11"></div> */}


               <div className="col-lg-1">
               {i==0?'':<span onClick={()=>{handleDelete(i)}} className="btn" type="bitton"><i className="bi bi-trash3-fill"></i></span> }

               </div>
               </div><br></br>
               </>


                )
             })}
             </div>

                {/* tow data add  */}









              <Videopreview />



                <div className="text-center">



                  <button type="submit" className="btn submit-btn1">
                   Update
                  </button>

                  <span className="btn submit-btn2">
                  <Link style={{color: 'white'}} to="/lawyer"> Go Back </Link>
                  </span>

                  <span className="btn submit-btn3">
                  <Link style={{color: 'white'}}  onClick={()=>{sendMail(data.email,data.name)}} > Re-Send Activation Email  </Link>
                  </span>

                  <span  className="btn submit-btn4"
                   data-bs-toggle="modal" onClick={() => { setIdinLocal(data._id) }}
                   data-bs-target="#verticalycentered">Delete this Lawyer </span>


                </div>
              </form>
              {/* End Multi Columns Form */}
            </div>
          </div>
        </div>
      </div>
      <div className="row d-none" id="order_history">
        <div className="col-lg-12">
          <h5 className="card-title">Order History - Coming Soon </h5>
          <ViewButton />
          {/* Left side columns */}
          <div className="col-lg-6">
            <div className="card recent-sales overflow-auto">
              <div className="card-body">
                {/* Progress Bars with Striped Backgrounds*/}
                <div className="progress mt-3">
                  <div
                    className="progress-bar progress-bar-striped bg-info"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                {/* End Progress Bars with Striped Backgrounds */}
              </div>
            </div>
          </div>
          {/* End Left side columns */}
        </div>
      </div>

      <div className="row d-none" id="Consultation">
        <div className="col-lg-12">
          <h5 className="card-title">Legal Services</h5>
          <ViewButton />
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="card recent-sales overflow-auto">
              <div className="card-body">
                {/* Progress Bars with Striped Backgrounds*/}
                  {/* {services.map(ser => ( <tr>
                    <td>{ser.name}</td>
                    <td>{ser.price}</td>

                  </tr>
                ))} */}

                <table id="myTable" className="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th className="shorting">
                        Price
                        </th>
                        {/* <th className="shorting">
                        Experts
                        </th> */}
                        <th className="shorting">
                        Documents
                        </th>
                        <th className="shorting">
                        Blogs
                        </th>

                        <th className="shorting">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                     {services.map((pln,i)=> (  <tr> {console.log(i)}
                        <td><div><span onClick={()=>{}} className="pointer">{pln.name} </span></div></td>
                        <td><div><span>{pln.price}</span></div> </td>
                        <td><span className="pointer view_btn delete_swap">View</span></td>
                         <td><span  className="pointer view_btn delete_swap">View</span></td>


                          <td><span onClick={()=>{editSevice(pln._id)}} className="pointer view_btn">
                           Edit
                          </span>


                        </td>
                      </tr> ))}

                    </tbody>
                  </table>


                {/* End Progress Bars with Striped Backgrounds */}
              </div>
            </div>
          </div>
          {/* End Left side columns */}
        </div>
      </div>

      <div className="row d-none" id="timeHistory">
        <div className="col-lg-12">
          <h5 className="card-title">Consultation History</h5>
          <ViewButton />
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="card recent-sales overflow-auto">
              <div className="card-body">
                {/* Progress Bars with Striped Backgrounds*/}
                  {/* {services.map(ser => ( <tr>
                    <td>{ser.name}</td>
                    <td>{ser.price}</td>

                  </tr>
                ))} */}

                <table id="myTable" className="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">Sr.No</th>

                        <th className="shorting">
                        Customer Name
                        </th>
                        {/* <th className="shorting">
                        Experts
                        </th> */}
                        <th className="shorting">
                        Customer Email
                        </th>
                        <th className="shorting">
                         Description of work
                        </th>
                        {/* <th className="shorting">
                        Deduct Hours
                        </th> */}

                        <th className="shorting">
                        Time Spent
                        </th>



                        <th className="shorting">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                     {consultationHistory.map((pln,i)=> (  <tr> {console.log(i)}
                        <td>{i+1}</td>
                        <td><div><span>{pln.userId.name}</span></div> </td>
                        <td><div><span>{pln.userId.email}</span></div> </td>
                        <td style={{width:350}}><div><span>{pln.description}</span></div> </td>
                        {/* <td><div><span>{pln.deductedTime}</span></div> </td> */}
                        <td><div><span>{pln.timeSend}</span></div> </td>
                        <td><div><span>{Config.date(pln.dateAndTime)}</span></div> </td>

                      </tr> ))}

                    </tbody>
                  </table>


                {/* End Progress Bars with Striped Backgrounds */}
              </div>
            </div>
          </div>
          {/* End Left side columns */}
        </div>
      </div>


    </section>
  </main>
  {/* End #main */}
</>


      <Asidebar />
      <Footer />
      <Jsfile />


    </>
  );
};

export default ViewLawyer;
