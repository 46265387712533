import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const IdleLogout = ({ timeout, onLogout }) => {
  const [remainingTime, setRemainingTime] = useState(timeout);
  const history=useHistory();

  const resetTimer = () => {
    setRemainingTime(timeout);
  };

  useEffect(() => {
      const resetTimerOnInteraction = () => {
        resetTimer();
      };

      const startTimer = () => {
        const interval = setInterval(() => {
          // console.log("counting",remainingTime)
          if (remainingTime > 0) {
            setRemainingTime((prevTime) => prevTime - 1);
          } else {
            clearInterval(interval);
            onLogout(); // Perform logout action here
            history.push("/"); // Redirect to login page
          }
        }, 1000);

        return interval;
      };

      const interactionEvents = [
        "mousedown",
        "mousemove",
        "keydown",
        "touchstart",
      ];
      interactionEvents.forEach((event) => {
        document.addEventListener(event, resetTimerOnInteraction);
      });

      const interval = startTimer();

      return () => {
        clearInterval(interval);
        interactionEvents.forEach((event) => {
          document.removeEventListener(event, resetTimerOnInteraction);
        });
      };

  }, [remainingTime, history, onLogout, timeout]);

  return null;
};

export default IdleLogout;
