import React, { lazy } from "react";
import { useState, useEffect } from "react"
import { Link , useHistory} from "react-router-dom"; 
import Config from '../../config';



 const Header= lazy(() => import("../widgets/Header.js"));
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
 const PaginationButton  = lazy(() => import('../common/PaginationButton.js'))
 

const Pricing = () => {
   
  const [data ,setData] = useState({});
  const history = useHistory();
  const customerData =  (data) =>{
    
   
    Config.get('/admin/pricing')
    .then((res) => {
     console.log(res);
      if (res.status) {

        console.log(res.data)
        setData(res.data)

      }else{
        Config.showToast("server error!!! ");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });

  }
  
  useEffect(() => {
    alert()
    const script = document.createElement('script');
    script.src = 'assets/js/main.js';   //(This is external js url)   
    document.body.appendChild(script);
    customerData(5)
  }, [])
  

  
  const editBlog = (id)=>{
  localStorage.setItem("pricingID",id);
  history.push('/edit-pricing');
  }


  const clear= ()=>{
    document.getElementById("myInput").value = '';
    seachTable()
   }
      
   const seachTable = () =>{
    
     
   var input, filter, table, tr, td, i, txtValue;
     input = document.getElementById("myInput");
     filter = input.value.toUpperCase();
     table = document.getElementById("myTable");
     tr = table.getElementsByTagName("tr");
     for (i = 0; i < tr.length; i++) {
       td = tr[i].getElementsByTagName("td")[0];
       if (td) {
         txtValue = td.textContent || td.innerText;
         if (txtValue.toUpperCase().indexOf(filter) > -1) {
           tr[i].style.display = "";
         } else {
           tr[i].style.display = "none";
         }
       }       
     }
   }



  return (
    <>
      <Jsfile />
      <Header />
      
      <>
  <main id="main" className="main">
 
    

    <section className="section dashboard">
      <div className="row">
        {/* Left side columns */}
        <div className="col-lg-12">
          <div className="row">
            {/* Recent Sales */}
            <div className="col-12">
              <div className="card recent-sales overflow-auto">
                <div className="card-body" id="plan">
                  <h5 className="card-title"> Pricing  </h5>
                  <div className="dataTable-top">
                  <PaginationButton />
                    <div className="dataTable-search d-flex">
                      <div className="search-bar">
                        <div
                          className="search-form d-flex align-items-center"
                          method="POST"
                          
                        >
                          <input
                            type="text"
                            id="myInput"
                            name="query"
                            placeholder="Search"
                            title="Enter search keyword"
                            className="search_input"
                            onKeyUp={()=>{seachTable()}}
                            
                          />
                          <button
                            type="submit"
                            className="search_data_btn"
                            title="Search"
                          >
                            <i className="bi bi-search" />
                          </button>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="clear_data_btn"
                        title="Clear"
                        onClick={()=>{clear()}}
                      >
                        Clear
                      </button>{" "}
                       <a className="add_lawyers_btn">
                       <Link to="/add-pricing" style={{color: 'white'}}> Add Pricing</Link>
                       </a>
                    </div>
                  </div>
                  <table id="myTable" className="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Discription</th>
                        {/* <th scope="col">Blog Content </th> */}
                        <th scope="col" className="shorting">
                        commectTitle
                        </th>
                        {/* <th scope="col">Author</th>

                        <th scope="col">Date</th> */}
                     
       
                        <th className="shorting">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                       <tr>
                        <td><div><span>{data.title} </span></div></td>
                        <td><div><span>{data.text}</span></div> </td>
                        {/* <td><div><span>{data.text} </span></div></td> */}
                        <td><div><span>{data.commectTitle}</span></div> </td>
                        {/* <td><div><span>{data.} </span></div></td> */}
                        
                        {/* <td><div><span>{blog.code}</span></div> </td>
                        <td><div><span>{blog.name} </span></div></td>
                        <td><div><span>{blog.code}</span></div> </td> */}

                        
                        <td><span onClick={()=>{editBlog(data._id)}} className="pointer view_btn">
                           Edit
                          </span> 
                        
                         
                        </td>
                      </tr> 
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* End Recent Sales */}
          </div>
        </div>
      </div>
    </section>
  </main>
  {/* End #main */}
</>


      <Asidebar />
      <Footer /> 
    
     
      
    </>
  );
};

export default Pricing;
