import React, { lazy } from "react";
import { useState, useEffect ,useRef } from "react"
import Config from '../../config';
import JoditEditor from 'jodit-react';
import { useHistory } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import ServiceMediaUpload from './ServiceMediaUpload';
// import ServiceVideo from './ServiceVideo';



 const Header = lazy(() => import("../widgets/Header.js"));
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
 const Popup = lazy(() => import('../widgets/DeletePop.js'))
//  const ServicesMedai = lazy(() => import('../widgets/ServicesMedai.js'))

 const AddLegalServices = (props) => {
    const options23 = [
    { label: "coming Soon ", value: "coming Soon" }
    ];

    const id = localStorage.getItem("servicesId")
    // service intro 
    const editor = useRef(null);
    const [introText, setIntroText] = useState("");
        
        // const [options] = useState(optionsdata);
         const [options ,setDropDown] = useState();
         const  LayersDropDown =  () =>{
         
          Config.get(`/admin/lawyers?limit=100`)
          .then((res) => {
           console.log(res);
              if (res.status) {
              setDropDown(res.data)
             
                // alert()
          
            }else{
              Config.showToast("Please try after some time.");
            }
          })
          .catch((err) => {
            Config.showToast("Please try after some time.");
          });
      
         }

         const [opDocs ,setDocs] = useState();
         const  documentsDropdown =  () =>{
        //  alert()
           Config.get(`/admin/legel-doc-list`)
          .then((res) => {
           console.log(res);
              if (res.status) {
                setDocs(res.data)
                blogDropdown()
                // alert()
          
            }else{
              Config.showToast("Please try after some time.");
            }
          })
          .catch((err) => {
            Config.showToast("Please try after some time.");
          });
      
         }


         const [opBlog ,setBlog] = useState();
         const  blogDropdown =  () =>{
           //alert()
           Config.get(`/admin/article?limit=100`)
          .then((res) => {
          //console.log(res);
              if (res.status) {
                setBlog(res.data)
                // alert()
          
            }else{
              Config.showToast("Please try after some time.");
            }
          })
          .catch((err) => {
            Config.showToast("Please try after some time.");
          });
      
         }


        // Add srvice Points 
         const [val,setVal] = useState([])
         const handleAdd = ()=>{
          const abc = [...val,[{servicePoints:""}]]
          setVal(abc)
          }

         const handleChange = (onChangeValue,i)=>{
         const inputData = [...val]
         const testi = {}
       
         testi.servicePoints =  document.getElementById("testimonials"+i).value;
         inputData[i] = testi;
         setVal(inputData)
     

         }

        const  handleDelete = (i)=>{
       
          const deleteVal = [...val]
          deleteVal.splice(i,1)
          setVal(deleteVal)
        
        }


       const back = () =>{
        history.push("/legalservice");
        }
        
        
        useEffect(() => {
        LayersDropDown()
        documentsDropdown()
        // handleAdd()
        const script = document.createElement('script');
        script.src = 'assets/js/main.js';   //(This is external js url)   
        document.body.appendChild(script);
         // for layer dropdown
         
          
        }, [])

        
      
          const history = useHistory();
          async function AddServices(e) {
                   e.preventDefault();

                   const lawyers = [];
                   let drop = e.target.Droplawyers.value.split(',')
                   let blogs = e.target.DropBlog.value.split(',')
                   let docs = e.target.Dropdoc.value.split(',')
                   drop.map((m)=>{
                    lawyers.push(m)
                   })

                   if(introText == ''){
                    
                   return Config.showToast("Service Into  Is Required .");

                   }

                   var keyPoints  = JSON.stringify(val)
                  //  console.log(keyPoints ," event")
                  //  console.log(lawyers)
                   let formData = new FormData();
                   formData.append('name' ,  e.target.services.value)
                   formData.append('price' ,  '100')
                   formData.append('bannerText' ,  e.target.bannerText.value)
                   formData.append('bannerMedia' , e.target.bannerMediaURL.value)

                   formData.append('introTitle' , "editor-used")
                   formData.append('introText' ,introText)
                   formData.append('introMedia'  , "editor-used")

                   formData.append('sec3Text' , e.target.sec3Text.value)
                   formData.append('sec3Media' , e.target.sec3Media.value)
                   formData.append('sec3Title' , e.target.sec3Title.value)

                   formData.append('expertsTitle' , e.target.expertsTitle.value)
                   formData.append('expertsText' , e.target.expertsText.value)
                   formData.append('lawyers' , drop);
                   formData.append('documentsTitle' , e.target.documentsTitle.value)
                   formData.append('documentsText' , e.target.documentsText.value)
                   formData.append('documents' , docs)
                   formData.append('blogsTitle' , e.target.blogsTitle.value)
                   formData.append('blogsText' , e.target.blogsText.value)
                   formData.append('blogs' , blogs)
                   formData.append('keyPoints' ,keyPoints)

                   formData.append('videoText' , "notused")
                   formData.append('videoMedia' , "notused")

                   formData.append('sec5Title' , e.target.sec5Title.value)
                   formData.append('sec5Media' , "notused")

            
  
                  Config.post('/admin/legal-services',formData)
                    .then((res) => {
                    console.log(res);
                      if (res.status) {
                        history.push("/legalservice");
                        }else{
                        Config.showToast("somthing wrong . ");
                        }
                      })
                    .catch((err) => {
                      Config.showToast("Please try after some time.");
                    });
      
                 
            }

  return (
     <>

      <Header />
      <Asidebar />
      <main id="main" className="main">
    
      <Popup />
    <section className="section">
    <div id="imgLoader" style={{display:"none"}} className="lds-spinner"><div></div><div>
    </div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

      <div className="row">
       <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{props.type} Services  </h5>
            {/* Multi Columns Form */}


    <form className="row g-3" method='post' onSubmit={AddServices}>
            

     {/* Secation One */}   
     <div className="col-lg-6">
          <h1>Section: Banner</h1>
          <div className="col-md-12">
                <label htmlFor="inputName5" className="form-label">
                  Service Title  <span style={{color:"red"}} >* </span>
                </label>
              <input type="text" className="form-control" id="services" name="services"   required />
                
          </div>
          <div className="col-md-12">
            <label htmlFor="inputEmail5" className="form-label">Service Banner Text     <span style={{color: 'red'}}>* </span></label>
            <textarea type="text" className="form-control" id="bannertext" name="bannerText" rows= {5}   required>
            </textarea>
          </div>
        </div>

  
        <div className="col-lg-6 image-prev-div">
           <ServiceMediaUpload data={{id:"bannermedia",ulrImage:"bannerMediaURL",mainscr:"mainscr",label:"Banner Media",type:"Add"}} />
        </div>
        

          {/* secation two intro */}

         <div className="col-lg-6">
            <h1>Section: Service Intro</h1>
        </div>
               <JoditEditor
			          ref={editor}
			          // value={data.text}
		           	// config={config}
                tabIndex={1} // tabIndex of textarea
                onChange={newContent => setIntroText(newContent)} // preferred to use only this option to update the content for performance reasons
                // onChange={newContent => {}}
                />

  
      


          {/* expets section */}
          <div className="col-lg-12">
          <h1>Section: Lawyers</h1>
          </div>
          <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Experts Section Title    <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="expertsTitle"   required>
                </textarea>
              </div> 
              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Experts Description  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="expertsText"    required>
                </textarea>
              </div> 
              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Experts  
                </label>
            
            <input type="text" style={{display:"none"}} className="form-control" id="Droplawyers" name="Droplawyers"  />

             <Multiselect
           
             options={options} // Options to display in the dropdown
             onSelect={(event)=>{
              let arr = [];
               for(let i=0 ; i<event.length ; i++){
               arr.push(event[i]._id)
              
             }
             document.getElementById("Droplawyers").value = arr;
             }} // Function will trigger on select event
               onRemove={(event)=>{ 
              let arr = [];
              for(let i=0 ; i<event.length ; i++){
               arr.push(event[i]._id)
              
             }  
            //  console.log(arr)
             document.getElementById("Droplawyers").value = arr; }} // Function will trigger on remove event
            //  selectedValues={mapDatas.lawyers}
             displayValue={"name"}
             showCheckbox
             />
                {/* <input type="text" className="form-control" id="lawyers"  value={data.lawyers}  required/> */}
              </div> 
             


               





              {/* <div className="col-md-3"> */}
                {/* <label htmlFor="inputName5" className="form-label">
                  Service Title  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="services" name="services"   required>
                </textarea>
              </div>
        
              <div className="col-md-5">
                <label htmlFor="inputEmail5" className="form-label"> Service Banner Text 
                 <span style={{color:"red"}} >* </span> 
                </label>
                <textarea type="text" className="form-control" id="bannertext" name="bannerText"   required>
                </textarea>
             </div>

             <ServiceMediaUpload data={{id:"bannermedia",ulrImage:"bannerMediaURL",mainscr:"mainscr",label:"Banner Media"}} /> */}
             
          
            

             



           
              {/* <div className="form-group  col-lg-3">
                <label htmlFor="categories" className=" form-label">
                Intro Title  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="introTitle"   required>
                </textarea>
              </div>
               <div className="form-group col-lg-5">
                <label htmlFor="categories" className=" form-label">
                Intro Text  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="introText"    required>
                </textarea>
                </div>
                <ServiceMediaUpload data={{id:"intromedia",ulrImage:"introMediaURL",mainscr:"mainscrInto", label:"Intro Media"}} /> */}
              
            
              
           

              {/* secation 4 CEO and containt */}
           

          <div className="col-lg-6">
            <h1>Section: SEO Content</h1>
             <div className="col-md-12">
                  <label htmlFor="inputName5" className="form-label">
                  Section Title 
                  </label>
                  <input type="text" className="form-control" id="sec3Title"  />
             
             </div>
            <div className="col-md-12">
              <label htmlFor="inputEmail5" className="form-label"> 
              Section Content</label>
              <textarea type="text" className="form-control" id="sec3Text" rows={5}  >
                </textarea>
            </div>
        </div>

  
        <div className="col-lg-6 image-prev-div">
        <ServiceMediaUpload data={{id:"sec3media",ulrImage:"sec3Media",mainscr:"mainscrSec3", label:"Media",type:"Add"}} />
        </div> 




              
           {/* 
              <div className="form-group  col-lg-3">
                <label htmlFor="categories" className=" form-label">
                SEO Content Title <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="sec3Title" required>
                </textarea>
              </div>
              <div className="form-group col-lg-5">
                 <label htmlFor="categories" className=" form-label">
                 SEO Content  <span style={{color:"red"}} >* </span>
                 </label>
                <textarea type="text" className="form-control" id="sec3Text"  required>
                </textarea>
             </div>

             <ServiceMediaUpload data={{id:"sec3media",ulrImage:"sec3Media",mainscr:"mainscrSec3", label:" SEO Media"}} />

            */}

         
             
           
           {/* other fields  */}

                  <div className="col-lg-12">
                    <h1>Section: Legal Documents</h1>
                  </div>
                <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                  Title  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="documentsTitle"   required>
                </textarea>
              </div> 
              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                 Content  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="documentsText"    required>
                </textarea>
              </div> 
            
              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Documents
                </label>
                <input type="text" style={{display:"none"}}  className="form-control" id="Dropdoc" name="Dropdoc"  />
                {/* <input type="text" className="form-control" id="documents"  value={data.documents}  required/> */}
            <Multiselect
            
            options={opDocs} // Options to display in the dropdown
            // onSelect={options23} // Function will trigger on select event
            // onRemove={options23} // Function will trigger on remove event
            // selectedValues={options23}
              onSelect={(event)=>{
               let arr = [];
               for(let i=0 ; i<event.length;i++){
               arr.push(event[i]._id)
                }
                document.getElementById("Dropdoc").value = arr;
               }} // Function will trigger on select event

              onRemove={(event)=>{ 
              let arr = [];
              for(let i=0 ; i<event.length ; i++){
               arr.push(event[i]._id)
                }  
            //  console.log(arr)
             document.getElementById("Dropdoc").value = arr; }}   

               
           
            displayValue={"name"}
            showCheckbox
            />
        </div> 

                 <div className="col-lg-12">
                    <h1>Section: Blogs</h1>
                  </div>

              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Blog Section Title  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="blogsTitle"    required>
                </textarea>
              </div> 
              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Blog Section Content <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="blogsText"  required>
                </textarea>
              </div> 
              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Related Blog Articles </label>
                {/* style={{display:"none"}} */}
              <input type="text" style={{display:"none"}} className="form-control" id="DropBlog" name="DropBlog"  />
              <Multiselect
            
               options={opBlog} // Options to display in the dropdown

               onSelect={(event)=>{
               let arr = [];
               for(let i=0 ; i<event.length;i++){
                arr.push(event[i]._id)
                }
                document.getElementById("DropBlog").value = arr;
                }} // Function will trigger on select event

               onRemove={(event)=>{ 
              let arr = [];
              for(let i=0 ; i<event.length ; i++){
               arr.push(event[i]._id)
                }  
            //  console.log(arr)
               document.getElementById("DropBlog").value = arr; }}   
               displayValue={"title"}
               showCheckbox
            />  
            </div> 

             {/* Section 4 */}



               {/* <div className="form-group  col-lg-4">
                <label htmlFor="categories" className=" form-label">
                Video Title  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="videoText"  name="videoText" required>
                </textarea>
              </div>
              <ServiceVideo /> */}

               {/* Plan section  */}

             {/* <div className="form-group  col-lg-8">
                <label htmlFor="categories" className=" form-label">
                Plan Title  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="sec5Title"  name="sec5Title"  required>
                </textarea>
              </div>
            <ServiceMediaUpload data={{id:"sec5media",ulrImage:"sec5Media",mainscr:"mainscrSec5" , label: "Plan Media"}} /> */}
          

              {/* <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Video Text  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="videoText"    required/>
              </div>  */}
            
          {/* <ServicesMedai /> */}

           {/* secation 5 service point */}
          
              <div className="col-lg-12">
               <h1>Section: Service Points</h1>
             </div>

             <div className="form-group  col-lg-4">
                <label htmlFor="categories" className=" form-label">
                  Title  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="sec5Title"  name="sec5Title" />
               
              </div>


                 <div className="col-lg-12" id="testi">
                    <label htmlFor="Testimonials" className=" form-label">
                     Service Points <span style={{color:"red"}} >* </span>
                     <span onClick={()=>{handleAdd()}} className="btn ">
                     <i className="bi bi-plus-square-fill"></i>
                  </span>
                
              </label>
                
                {val.map((data,i)=>{
                return ( <>
                <div className="row">
                 <div className="col-lg-8">
                  <input  value={data.servicePoints} onChange={e=>handleChange(e,i)} rowspan='2' type="text" 
                  id={"testimonials"+i} name={"testimonials"+i} className="form-control"   placeholder="Add Name" required/>
                  {/* </textarea> */}
                </div>
   
                 {/* <div className="col-lg-11"></div> */}

                 <div className="col-lg-4">
                 <span  className="btn" onClick={()=>{handleDelete(i)}}   type="bitton"><i className="bi bi-trash3-fill"></i></span> 
               
                 </div>
               </div><br></br>
               </>
                )
              })}
           </div> 

        
                <div className="text-center">
                   <button type="submit" className="btn submit-btn1">
                     Submit
                   </button>

                   <span onClick={()=>{back()}} className="btn  submit-btn2">
                    Go Back
                   </span>

                
                  {/* <span onClick={()=>{deletPlan()}}  className="btn submit-btn3">
                    Delete
                  </span> */}
              
              </div>
            </form>
            {/* End Multi Columns Form */}
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

      <Footer /> 
      <Jsfile />
     
  
    </>
  );
};

export default AddLegalServices;
