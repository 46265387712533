import React , { lazy } from 'react'
import { useState, useEffect , useRef} from "react"
import Config from '../../config';
import CommentMedia from './CommentMedia';

import ServiceMediaUpload from '../services/ServiceMediaUpload';


// import  CKEditor  from '@ckeditor/ckeditor5-react';
//  import  ClassicEditor  from '@ckeditor/ckeditor5-build-classic';
import { useHistory } from "react-router-dom";
import { Edit } from '@material-ui/icons';
// const Popup = lazy(() => import('./DeletePop.js'))
 const Header= lazy(() => import("../widgets/Header.js"));
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
//  const MediaUpload = lazy(() => import('./MediaUpload'))
 const Popup = lazy(() => import('../widgets/DeletePop.js'))

const AddEditPricing = (props) => {
const id = localStorage.getItem("pricingID")
 
 
       const [data ,setData] = useState([]);



        // pricing 
       const [val,setVal] = useState([])

        const handleAdd = ()=>{
        const abc = [...val,[{comment:"",commentMedia:""}]]
        setVal(abc)
        }

         const handleChange = (i)=>{
           
         const inputData = [...val]
         const testi = {}
       
         testi.comments =  document.getElementById("comment"+i).value;
         testi.commectMedia =  document.getElementById("commentMediaUrl"+i).value;
         
        //  testi.companyName = document.getElementById("companyName"+i).value;

         inputData[i] = testi;
         setVal(inputData)
     

         }

        const  handleDelete = (i)=>{
          if(val.length>1){
          const deleteVal = [...val]
          deleteVal.splice(i,1)
          setVal(deleteVal)
          }
        }


// edit section 
//id:"pricingMedia",ulrImage:"pricingMediaUrl",mainscr:"pricingMediaSrc",label:"Pricing Media"
 
 const blogData =  () =>{
   
    Config.get(`/admin/article/id?_id=${id}`)
    .then((res) => {
     console.log(res);
        if (res.status) {
          document.getElementById("pricingMediaUrl").value = res.data.media;
          document.getElementById("pricingMediaSrc").src = res.data.media;
          document.getElementById("pricingMedia").disabled = true;

          setVal(res.data.commentSection)
          setData(res.data)

      }else{
        Config.showToast("Please try after some time.");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });

  }


  // drop down option multiselece 
 


    

   const setIdinLocal = (id)=>{
    localStorage.setItem("blogID",id);
    localStorage.setItem("deleteType","blog");
   
    }
  


    const back = () =>{
      history.push("/pricing");
    }

  
  useEffect(() => {
    handleAdd() 
  
  
    if(props.type== "Edit"){
      blogData()
     document.getElementById("deleteButton").style.display = "block"
    }
  }, [])

  const history = useHistory();

  // add edit data
    async function AddBlog(e) {
          e.preventDefault();
        
             var text = e.target.text.value, 
             title = e.target.title.value,
             media = e.target.pricingMediaUrl.value,
             commentSection = val,
             commectTitle = "What Out CUstomer Says About Law From Me "

        if(props.type == "Add"){
         var data = {text,title,media,commentSection,commectTitle};
            Config.post('/admin/pricing',data)
              .then((res) => {
              console.log(res);
                if (res.status) {
                  history.push("/pricing");
                  }else{
                  Config.showToast("somthing wrong");
                  }
                })
              .catch((err) => {
                Config.showToast("Please try after some time.");
              });

            }else {
              
          // call edit api 
          var data = {text,title,media,commentSection,commectTitle};

                Config.put('/admin/pricing',data)
                .then((res) => {
                  console.log(res);
                  if (res.status) {
                     
                    history.push("/blogs");
                    }else{
                    Config.showToast("somthing wrong ");
                    }
                  })
                .catch((err) => {
                  Config.showToast("Please try after some time.");
                });



         }

      }  

  
        

            const imageUploadonserver2 = (i)=>{
        
            var  [file]  = document.getElementById("commentMedia"+i).files
            let formData = new FormData();
            formData.append('photo', file)
            alert()
            Config.post('/admin/media-uploader',formData)
            .then((res) => {
             console.log(res);
                if (res.status) {
                   document.getElementById("commentMediaSrc"+i).src = res.data
                  document.getElementById("commentMediaUrl"+i).value = res.data
                //   document.getElementById("commentMedia").disabled = "true"
                handleChange(i)
              
                }else{
                Config.showToast("somthing wrong .");
                }
                })
             .catch((err)=> {
                Config.showToast("Unable to upload a file ");
               });
    
    
    
             }


         const RemoveImagePreview = () =>{
         document.getElementById("pricingMediaSrc").src = "assets/img/preview_1.png"
         // document.getElementById("mainscr").src = data.profile
         document.getElementById("pricingMedia").value = null
         document.getElementById("pricingMedia").disabled = false
         }
  


  return (
    <>
      <Jsfile />
      <Header />
 <main id="main" className="main">
  <Popup />

  <section className="section">
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{props.type} Pricing  </h5>
            {/* Multi Columns Form */}

          

            <form className="row g-3" method='post' onSubmit={AddBlog}>

            <div className="col-lg-6">
              <h1  className="card-title" >Section: Banner </h1>
                <div className="col-md-12">
                <label htmlFor="inputName5" className="form-label">
                 Title  <span style={{color:"red"}} >* </span>
                </label>
              <input type="text" className="form-control" id="title" name="title" onChange={(e) => setData(e.target.value)} value={data.title}   required />
                
            </div>
            <div className="col-md-12">
              <label htmlFor="inputEmail5" className="form-label"> Description     <span style={{color: 'red'}}>* </span></label>
              <textarea type="text" className="form-control" id="text"  name="text"  onChange={(e) => setData(e.target.value)}  value={data.title}    required>
              </textarea>
              </div>
            </div>
            <div className="col-lg-6 image-prev-div">
             <ServiceMediaUpload data={{id:"pricingMedia",ulrImage:"pricingMediaUrl",mainscr:"pricingMediaSrc",label:"Pricing Media"}} />
            </div>

            <div className="col-lg-12">
            <h1  className="card-title" >Section: Comments</h1>
            </div>


                 <div className="col-lg-12" id="testi">
                   <label htmlFor="Testimonials" className=" form-label">
                     Customer Review  <span style={{color:"red"}} >* </span>
                    <span onClick={()=>{handleAdd()}} className="btn ">
                    <i className="bi bi-plus-square-fill"></i>
                     </span>
                   </label>
                
              {val.map((data,i)=>{
                return ( <>
                <div className="row">
                 <div className="col-lg-6">
                  <textarea value={data.content} onChange={e=>handleChange(i)} rows={2} type="text" 
                   id={"comment"+i} name={"comment"+i} className="form-control"   placeholder="Review" required>
                 </textarea>
                 <br></br>
                 <input  value={data.content} onChange={e=>handleChange(i)}  type="text" 
                   id={"user"+i} name={"user"+i} className="form-control"   placeholder="user" required/>
                  <br></br>
                 <input  value={data.content} onChange={e=>handleChange(i)}  type="text" 
                   id={"rating"+i} name={"rating"+i} className="form-control"   placeholder="rating" required/>
                  <br></br>
                 <input  value={data.content} onChange={e=>handleChange(i)}  type="text" 
                   id={"heading"+i} name={"heading"+i} className="form-control"   placeholder="Logoipsum" required/>
              
                </div>
                <div className="col-lg-5">

                <div id="ImageField2" className="col-lg-12">
                    <div className="project-modal-img legalSevicesPrevie">
                        <div className="upload-project-img" id="Browse" >
                        <img  id={"commentMediaSrc"+i} src="assets/img/preview_1.png" alt="img"  />
                        </div>
                    <img class="remove-img" onClick={()=>{RemoveImagePreview()}} src="https://appuat.userq.com/images/cross.svg" alt="img"/>
                </div>
                </div>
                    <div className="col-lg-12">
                        <label htmlFor="Phone" className=" form-label">
                        Pricing Media <span style={{ color: "red" }} >* </span>
                        </label>
                        {/* onChange={()=> imageUploadonserver()} */}
                        <input type="file"  onChange={()=> imageUploadonserver2(i)}  className="form-control" 
                        id={"commentMedia"+i}    required />
                       <input  type="text" name={"commentMediaUrl"+i}  id={"commentMediaUrl"+i} className="form-control" />
                    </div>



                {/* <CommentMedia data={{id:"pricingMedia",ulrImage:"pricingMediaUrl",mainscr:"pricingMediaSrc",label:"Pricing Media",function:handleChange(i),id:i}} /> */}
                {/* <input type="text" value={data.clientName}   onChange={e=>handleChange(e,i)}
                 id={"clientName"+i} name={"clientName"+i}   className="form-control" placeholder="client name"  required/> */}
                </div>
             
            
               <div className="col-lg-1">
               {i==0?'':<span  className="btn" onClick={()=>{handleDelete(i)}}   type="bitton"><i className="bi bi-trash3-fill"></i></span> }
              
               </div>
               </div><br></br>
               </>
                )
             })}
             </div> 

       
        
        
               <div className="text-center">
                  
                  <button type="submit" className="btn submit-btn1">
                  {props.type== "Edit" ? "Update": "Submit" }
                  </button>
 
                  <span onClick={()=>{back()}} className="btn  submit-btn2">
                   Go Back</span>
             

                  <span className="btn  submit-btn4" style={{float:"right", display:"none"}} data-bs-toggle="modal" 
                   id ="deleteButton"
                   onClick={()=>{setIdinLocal(data._id)}} data-bs-target="#verticalycentered">Delete</span>
              
              </div>
            </form>
            {/* End Multi Columns Form */}
            {/* <div>{article}</div> */}
          </div>
        </div>
      </div>
    </div>
  </section>

</main>
 
      <Asidebar />
      <Footer /> 
    
</>

 )
}

export default AddEditPricing
