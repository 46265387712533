import React, { lazy } from "react";
import { useState, useEffect,useRef} from "react"
import Config from '../../config';
import JoditEditor from 'jodit-react';
import { useHistory } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import ServiceMediaUpload from './ServiceMediaUpload';

// import ServiceVideo from './ServiceVideo';



 const Header= lazy(() => import("../widgets/Header.js"));
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
 const Popup = lazy(() => import('../widgets/DeletePop.js'))
 const ServicesMedai = lazy(() => import('../widgets/ServicesMedai.js'))

const EditLegalServices = (props) => {
  
  const options23 = [
    { label: "coming soon ", value: "coming soon" }
    ];

    // service intro 
    const editor = useRef(null);
    const [introText, setIntroText] = useState("");
   
    const id = localStorage.getItem("servicesId")

      //  const [toast, setToast] = useState(true);
       const [data ,setData] = useState([]);
       const [mapDatas ,mapData] = useState([]);
       

       
        // Add srvice Points 
        const [val,setVal] = useState([{servicePoints:""}])

        const handleAdd = ()=>{
         const abc = [...val,[{servicePoints:""}]]
         setVal(abc)
         }

        const handleChange = (onChangeValue,i)=>{
        const inputData = [...val]
        const testi = {}
      
        testi.servicePoints =  document.getElementById("testimonials"+i).value;
        inputData[i] = testi;
        setVal(inputData)
    

        }

       const  handleDelete = (i)=>{
       
         const deleteVal = [...val]
         deleteVal.splice(i,1)
         setVal(deleteVal)
        
       }

     const planData =  () =>{
      Config.get(`/admin/legal-services/id?_id=${id}`)
      .then((res) => {
      console.log("service " , res);
       if (res.status) {
            
                document.getElementById("bannerMediaURL").value = res.data.bannerMedia 
                document.getElementById("mainscr").src = res.data.bannerMedia;
                document.getElementById("bannermedia").disabled = true

              //  Section two

                // document.getElementById("introMediaURL").value = res.data.introMedia 
                // document.getElementById("mainscrInto").src = res.data.introMedia;
                // document.getElementById("intromedia").disabled = true
            
             //  Section three
            
                
                document.getElementById("sec3MediaURL").value = res.data.sec3Media 
                document.getElementById("mainscrSec3").src = res.data.sec3Media;
                document.getElementById("sec3media").disabled = true
                
            
                // Section four video 

                // document.getElementById("videoMediaURL").value = res.data.videoMedia;
                // document.getElementById("videoMediaURL").disabled = true
                // document.getElementById("ifram").src = res.data.videoMedia ==''?"assets/img/video.jpg":res.data.videoMedia
                // document.getElementById("flexSwitchCheckDefault").disabled = true
                // Section five

                // document.getElementById("sec5Media").value = res.data.sec5Media   
                // document.getElementById("mainscrSec5").src = res.data.sec5Media;
                // document.getElementById("sec5media").disabled = true
             

            
                // document.getElementById("introMedia").disabled = true
                // document.getElementById("bannermedia").disabled = true
             
                   setVal(res.data.keyPoints)
                   setData(res.data)
                   mapData(res.data)
                  

        
              }else{
              Config.showToast("server error!!! ");
             }
          })
          .catch((err) => {
            Config.showToast("Please try after some time.");
          });
      
        }

        console.log("val"  , val)

        // const [options] = useState(optionsdata);
        const [options ,setDropDown] = useState();

        const  LayersDropDown =  () =>{
         
          Config.get(`/admin/lawyers?limit=100`)
          .then((res) => {
           console.log(res);
              if (res.status) {
              setDropDown(res.data)
                // alert()
          
            }else{
              Config.showToast("server error!!! ");
            }
          })
          .catch((err) => {
            Config.showToast("Please try after some time.");
          });
      
        }

        const [opDocs ,setDocs] = useState();
        const  documentsDropdown =  () =>{
       //  alert()
          Config.get(`/admin/legel-doc-list`)
         .then((res) => {
          console.log(res);
             if (res.status) {
               setDocs(res.data)
               blogDropdown()
               // alert()
         
           }else{
             Config.showToast("Please try after some time.");
           }
         })
         .catch((err) => {
           Config.showToast("Please try after some time.");
         });
     
        }


        const [opBlog ,setBlog] = useState();
        const  blogDropdown =  () =>{
          //alert()
          Config.get(`/admin/article?limit=100`)
         .then((res) => {
         //console.log(res);
             if (res.status) {
               setBlog(res.data)
               // alert()
         
           }else{
             Config.showToast("Please try after some time.");
           }
         })
         .catch((err) => {
           Config.showToast("Please try after some time.");
         });
     
        }

      
     
          const back = () =>{
            history.push("/legalservice");
          }
      
        
        useEffect(() => {
        LayersDropDown()
        documentsDropdown()
        blogDropdown()
        const script = document.createElement('script');
        script.src = 'assets/js/main.js';   //(This is external js url)   
        document.body.appendChild(script);
         // for layer dropdown
        planData()
        document.getElementById("deleteButton").style.display = "block"
         
        }, [])

          const setIdinLocal = (id)=>{
          localStorage.setItem("servicesId",id);
          localStorage.setItem("deleteType","Services");
         
          }


          const  changeVideoField = () =>{
            var checkBox = document.getElementById("flexSwitchCheckDefault");
             if (checkBox.checked == true){
              document.getElementById("videoMediaurl").style.display = "none"
              document.getElementById("videoMedia").style.display = "block"
             
             } else {
              document.getElementById("videoMediaurl").style.display = "block"
              document.getElementById("videoMedia").style.display = "none"
             }
          }
      
        const history = useHistory();
       
      
          async function AddServices(e) {
                   e.preventDefault();
                 
                  var keyPoints  = JSON.stringify(val)
                  let drop = e.target.Droplawyers.value.split(',')
                  let blogs = e.target.DropBlog.value.split(',')
                  let docs = e.target.Dropdoc.value.split(',')

                    if(introText == ''){
                    
                    return Config.showToast("Service Into  Is Required .");
 
                    }

                  let formData = new FormData();
                  formData.append('_id' ,  id)
                 
                  formData.append('name' ,  e.target.services.value)
                  formData.append('price' ,  '100')
                  formData.append('bannerText' ,  e.target.bannerText.value)
                  formData.append('bannerMedia' , e.target.bannerMediaURL.value)

                  formData.append('introTitle' , "editor - used")
                  formData.append('introText' , introText)
                  formData.append('introMedia'  , "editor used")

                  formData.append('sec3Text' , e.target.sec3Text.value)
                  formData.append('sec3Media' , e.target.sec3MediaURL.value)
                  formData.append('sec3Title' , e.target.sec3Title.value)

                  formData.append('expertsTitle' , e.target.expertsTitle.value)
                  formData.append('expertsText' , e.target.expertsText.value)
                  formData.append('lawyers' , drop);
                  formData.append('documentsTitle' , e.target.documentsTitle.value)
                  formData.append('documentsText' , e.target.documentsText.value)
                  formData.append('documents', docs)
                  formData.append('blogsTitle' , e.target.blogsTitle.value)
                  formData.append('blogsText' , e.target.blogsText.value)
                  formData.append('blogs' , blogs)
                  formData.append('videoText' , "notused")
                  formData.append('videoMedia' , "notused")
                  formData.append('keyPoints' ,keyPoints)

                  formData.append('sec5Title' , e.target.sec5Title.value)
                  formData.append('sec5Media' , "notused")

                  // formData.append('videoText' , e.target.videoText.value)
                  // formData.append('videoMedia' , e.target.videoMediaURL.value)

                  // formData.append('sec5Title' , e.target.sec5Title.value)
                  // formData.append('sec5Media' , e.target.sec5Media.value)
                    
                
                // call edit api 
            
      
                    Config.put('/admin/legal-services',formData)
                      .then((res) => {
                        console.log(res);
                        if (res.status) {
                          
                          history.push("/legalservice");
                          }else{
                          Config.showToast("somthing wrong ");
                          }
                        })
                      .catch((err) => {
                        Config.showToast("Please try after some time.");
                      });
      
      
      
               
      
            }




  
  return (
    <>

      <Header />
      <Asidebar />
      <main id="main" className="main">
      <Popup />
    <section className="section">
    <div id="imgLoader" style={{display:"none"}} className="lds-spinner"><div></div><div>
    </div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{props.type} Services  </h5>
            {/* Multi Columns Form */}

          

    <form className="row g-3" method='post' onSubmit={AddServices}>
                  
        {/* Secation One Banner */}   
       <div className="col-lg-6">
          <h1>Section: Banner</h1>
          <div className="col-md-12">
                <label htmlFor="inputName5" className="form-label">
                  Service Title  <span style={{color:"red"}} >* </span>
                </label>
                <input  type="text" className="form-control" id="services" name="services" onChange={(e) => setData(e.target.value)} value={data.name}  required />
               
                
          </div>
          <div className="col-md-12">
            <label htmlFor="inputEmail5" className="form-label">Service Banner Text  <span style={{color: 'red'}}>* </span></label>
            <textarea type="text" className="form-control" id="bannertext" rows={5} 
            name="bannerText" onChange={(e) => setData(e.target.value)} value={data.bannerText}  required>
                </textarea>
          </div>
        </div>

        <div className="col-lg-6 image-prev-div">
        <ServiceMediaUpload data={{id:"bannermedia",ulrImage:"bannerMediaURL",mainscr:"mainscr" ,label:"Banner Media" ,type:"Edit"}} />
        </div>
              
        {/* secation two  into */}
        <div className="col-lg-6">
           <h1>Section: Service Intro</h1>
            {/* <div className="col-md-12">
                <label htmlFor="inputName5" className="form-label">
                 Title <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="introTitle" onChange={(e) => setData(e.target.value)}
                 value={data.introTitle}  required />
               
             </div> */}
          {/* <div className="col-md-12">
            <label htmlFor="inputEmail5" className="form-label">   Text   <span style={{color: 'red'}}>* </span></label>
            <textarea  type="text" className="form-control" id="introText" rows={5} 
            onChange={(e) => setData(e.target.value)} value={data.introText}  required>
            </textarea>
          </div> */}
        </div>

               <JoditEditor
			          ref={editor}
			          value={data.introText}
		           	// config={config}
                tabIndex={1} // tabIndex of textarea
                onChange={newContent => setIntroText(newContent)} // preferred to use only this option to update the content for performance reasons
                // onChange={newContent => {}}
                />

        {/* <div className="col-lg-6 image-prev-div">
        <ServiceMediaUpload data={{id:"intromedia",ulrImage:"introMediaURL",
          mainscr:"mainscrInto", label:"Intro Media" ,type:"Edit"}} />
        </div> */}


               {/* expert section exprt   */}
               <div className="col-lg-12">
                <h1>Section: Lawyers</h1>
               </div>

               <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Experts Section Title  <span style={{color:"red"}} >* </span>
                </label> 
                <textarea type="text" className="form-control" id="expertsTitle" onChange={(e) => setData(e.target.value)} value={data.expertsTitle}  required>
                </textarea>
              </div> 


              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                 Experts Description  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="expertsText" onChange={(e) => setData(e.target.value)} value={data.expertsText}  required>
                </textarea>
              </div> 
              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Experts
                </label>
            
            <input type="text" style={{display:"none"}} className="form-control" id="Droplawyers" name="Droplawyers"  value={data.lawyersId} />

             <Multiselect
           
             options={options} // Options to display in the dropdown
             onSelect={(event)=>{
              var arr = [];
              for(let i=0 ; i<event.length ; i++){
               arr.push(event[i]._id)
              
             }
             document.getElementById("Droplawyers").value = arr;
             }} // Function will trigger on select event
             onRemove={(event)=>{ 
              var arr = [];
              for(let i=0 ; i<event.length ; i++){
               arr.push(event[i]._id)
              
             }  
             console.log(arr)
             document.getElementById("Droplawyers").value = arr; }} // Function will trigger on remove event
             selectedValues={mapDatas.lawyers}
             displayValue={"name"}
             showCheckbox
             />
                {/* <input type="text" className="form-control" id="lawyers" onChange={(e) => setData(e.target.value)} value={data.lawyers}  required/> */}
              </div> 


              {/* secation 4 CEO CONTAINT  */}

              {/* secation two  into */}
          <div className="col-lg-6 ">
           <h1>Section: SEO Content</h1>
            <div className="col-md-12">
                <label htmlFor="inputName5" className="form-label">
                Section Title
                </label>
                <input type="text" className="form-control" id="sec3Title" onChange={(e) => setData(e.target.value)} value={data.sec3Title}
                 />
             
               
             </div>
          <div className="col-md-12">
            <label htmlFor="inputEmail5" className="form-label"> SEO Content </label>
            <textarea type="text" className="form-control" id="sec3Text"   rows={5}
            onChange={(e) => setData(e.target.value)} value={data.sec3Text} >
                </textarea>
          </div>
        </div>

        <div className="col-lg-6 image-prev-div">
          <ServiceMediaUpload data={{id:"sec3media",ulrImage:"sec3MediaURL",
          mainscr:"mainscrSec3", label:"Media",type:"Edit"}} />
        </div>
              
           
           {/* other Documents  */}

               <div className="col-lg-12">
                <h1>Legal Documents </h1>
               </div>

              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Title  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="documentsTitle" onChange={(e) => setData(e.target.value)} value={data.documentsTitle}  required>
                </textarea>
              </div> 
              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Content  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="documentsText" onChange={(e) => setData(e.target.value)} value={data.documentsText}  required>
                </textarea>
              </div> 
            
              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Documents
                </label>
                <input type="text" style={{display:"none"}} className="form-control" id="Dropdoc" name="Dropdoc"  value={data.docIdInput} />
                {/* <input type="text" className="form-control" id="documents" onChange={(e) => setData(e.target.value)} value={data.documents}  required/> */}
              

             <Multiselect
            
             options={opDocs} // Options to display in the dropdown
             onSelect={(event)=>{
              var arr = [];
              for(let i=0 ; i<event.length ; i++){
               arr.push(event[i]._id)
              
             }
             document.getElementById("Dropdoc").value = arr;
             }} // Function will trigger on select event
             onRemove={(event)=>{ 
              var arr = [];
              for(let i=0 ; i<event.length ; i++){
               arr.push(event[i]._id)
              
             }  
             console.log(arr)
             document.getElementById("Dropdoc").value = arr; }} // Function will trigger on remove event
             selectedValues={mapDatas.documents}
             displayValue={"name"}
             showCheckbox
                  />
           </div> 


              {/* section blog  */}

             <div className="col-lg-12">
             <h1>Section: Blog  </h1>
               </div>
              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Blog Section Title <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="blogsTitle" onChange={(e) => setData(e.target.value)} value={data.blogsTitle}  required>
                </textarea>
              </div> 

              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Blog Section Content  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="blogsText" onChange={(e) => setData(e.target.value)} value={data.blogsText}  required>
                </textarea>
              </div> 

              <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Related Blog Articles</label>

            <input type="text" style={{display:"none"}} className="form-control" id="DropBlog" name="DropBlog" value={data.blogIdInput}  />
                
            <Multiselect
             options={opBlog} // Options to display in the dropdown
             onSelect={(event)=>{
             var arr = [];
            for(let i=0 ; i<event.length ; i++){
             arr.push(event[i]._id)
            }
             document.getElementById("DropBlog").value = arr;
             }} // Function will trigger on select event
             onRemove={(event)=>{ 
              var arr = [];
              for(let i=0 ; i<event.length ; i++){
               arr.push(event[i]._id)
                }  
             console.log(arr)
             document.getElementById("DropBlog").value = arr; }} // Function will trigger on remove event
             selectedValues={mapDatas.blogs}
             displayValue={"title"}
             showCheckbox
            />  </div> 

               {/* Section 4  video */}
               {/* <div className="form-group col-lg-4 " id="id_0">
                <label htmlFor="Sign Up Date" className=" form-label">
                Video Text  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="videoText" onChange={(e) => setData(e.target.value)} value={data.videoText}  required>
                </textarea>
              </div> 

             <ServiceVideo /> */}


             {/* Section5  footer banner */}

             {/* <div className="form-group  col-lg-8">
                <label htmlFor="categories" className=" form-label">
                Plan Title  <span style={{color:"red"}} >* </span>
                </label>
                <textarea type="text" className="form-control" id="sec5Title"  name="sec5Title" 
                onChange={(e) => setData(e.target.value)} value={data.sec5Title}  required>
                </textarea>
                </div>
               <ServiceMediaUpload data={{id:"sec5media",ulrImage:"sec5Media",mainscr:"mainscrSec5" , label:"Plan Media" }} /> */}
               
               
               <div className="col-lg-12">
                    <h1>Section: Service Points</h1>
                  </div>

                <label htmlFor="categories" className=" form-label">
                  Title  <span style={{color:"red"}} >* </span>
                </label>

                <input type="text" className="form-control" id="sec5Title"  onChange={(e) => setData(e.target.value)}  name="sec5Title" value={data.sec5Title}  />

                 <div className="col-lg-12" id="testi">
                  <label htmlFor="Testimonials" className=" form-label">

                    Service Points  <span style={{color:"red"}} >* </span>
                   <span onClick={()=>{handleAdd()}} className="btn ">
                   <i className="bi bi-plus-square-fill"></i>
                  </span>
                
              </label>
                
                {val.map((data,i)=>{
                  {/* alert(data.servicePoints) */}
                return ( <>
                <div className="row">
                 <div className="col-lg-8">
                  <input  value={data.servicePoints} onChange={e=>handleChange(e,i)}  type="text" 
                    id={"testimonials"+i} name={"testimonials"+i} className="form-control"   placeholder="Add key" required/>
                  {/* </textarea> */}
                </div>
   
                {/* <div className="col-lg-11"></div> */}

               <div className="col-lg-4">
                 <span  className="btn" onClick={()=>{handleDelete(i)}}   type="bitton"><i className="bi bi-trash3-fill"></i></span> 
       
               </div>
               </div><br></br>
               </>
                )
                })}
             </div> 

            

                  <div className="text-center">
                   <button type="submit" className="btn submit-btn1">
                    Update
                   </button>
                  <span onClick={()=>{back()}} className="btn  submit-btn2">
                    Go Back
                  </span>
                  <span className="btn  submit-btn4" style={{float:"right", display:"none"}} data-bs-toggle="modal" 
                   id ="deleteButton"
                   onClick={()=>{setIdinLocal(data._id)}} data-bs-target="#verticalycentered"
                   >Delete</span>
                  {/* <span onClick={()=>{deletPlan()}}  className="btn submit-btn3">
                    Delete
                  </span> */}
              
                 </div>
            </form>
            {/* End Multi Columns Form */}
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

      <Footer /> 
      <Jsfile />
     
  
    </>
  );
};

export default EditLegalServices;
