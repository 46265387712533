import React, { lazy } from "react";
import { useState, useEffect } from "react"
import { Link , useHistory} from "react-router-dom"; 
import Config from '../../config';


 const Header= lazy(() => import("../widgets/Header.js"));
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
 const PaginationButton  = lazy(() => import('../common/PaginationButton.js'))
 
 

const ViewCategories = () => {
   
  const [data ,setData] = useState([]);
  const history = useHistory();
  const customerData =  () =>{
   
    Config.get('/admin/lawyer-categories')
    .then((res) => {
     console.log(res);
      if (res.status) {
        // localStorage.setItem("userInfo", JSON.stringify(res.data));
        // localStorage.setItem("token", res.data.token);
        // history.push("/dashboard");
      
        console.log(res.data)
        setData(res.data)

      }else{
        Config.showToast("server error!!! ");
      }
    })
    .catch((err) => {
      // Config.showToast("Please try after some time.");
      console.log("error in get category data")
    });

  }
  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'assets/js/main.js';   //(This is external js url)   
    document.body.appendChild(script);
    customerData()
  }, [])
  

  
  const editPlan = (id)=>{
  localStorage.setItem("catID",id);
  history.push('/edit-cat');
  }


  const clear= ()=>{
    document.getElementById("myInput").value = '';
    seachTable()
   }
      
   const seachTable = () =>{
    
     
    var input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("myInput");
      filter = input.value.toUpperCase();
      table = document.getElementById("myTable");
      tr = table.getElementsByTagName("tr");
 
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            document.getElementById("noRsFound").style.display = "none"
          } else {
           if(i == 1){
            document.getElementById("noRsFound").style.display = "block"
           }
            tr[i].style.display = "none";
          }
        }       
      }
     
    }



  return (
    <>
      <Jsfile />
      <Header />
      
      <>
  <main id="main" className="main">
 
    

    <section className="section dashboard">
      <div className="row">
        {/* Left side columns */}
        <div className="col-lg-12">
          <div className="row">
            {/* Recent Sales */}
            <div className="col-12">
              <div className="card recent-sales overflow-auto">
                <div className="card-body" id="plan">
                  <h5 className="card-title">categories </h5>
                  <div className="dataTable-top">
                  <PaginationButton />
                    <div className="dataTable-search d-flex">
                      <div className="search-bar">
                        <div
                          className="search-form d-flex align-items-center"
                          method="POST"
                          
                        >
                          <input
                            type="text"
                            id="myInput"
                            name="query"
                            placeholder="Search"
                            title="Enter search keyword"
                            className="search_input"
                            onKeyUp={()=>{seachTable()}}
                            
                          />
                          <button
                            type="submit"
                            className="search_data_btn"
                            title="Search"
                          >
                            <i className="bi bi-search" />
                          </button>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="clear_data_btn"
                        title="Clear"
                        onClick={()=>{clear()}}
                      >
                        Clear
                      </button>{" "}
                       <a className="add_lawyers_btn">
                       <Link to="/add-cat" style={{color: 'white'}}> Add Category </Link>
                       </a>
                    </div>
                  </div>
                  <table id="myTable" className="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">Category Name </th>
                        <th scope="col" className="shorting">
                        Category Code
                        </th>
       
                        <th className="shorting">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                     {data.map(pln => (  <tr>
                        <td><div><span>{pln.name} </span></div></td>
                        <td><div><span>{pln.code}</span></div> </td>
                        
                        <td><span onClick={()=>{editPlan(pln._id)}} className="pointer view_btn">
                           Edit
                          </span> 
                        
                         
                        </td>
                      </tr> ))}
                     
                    </tbody>
                  </table>
                  <div id="noRsFound" style={{display:"none",  marginLeft:"416px"}}>   No Result  </div>
                </div>
              </div>
            </div>
            {/* End Recent Sales */}
          </div>
        </div>
      </div>
    </section>
  </main>
  {/* End #main */}
</>


      <Asidebar />
      <Footer /> 
    
     
      
    </>
  );
};

export default ViewCategories;
