import React from 'react'
import { useState, useEffect } from "react"
import Config from '../../config';

// import { useParams , useHistory} from "react-router-dom";
// import { Link , useHistory} from "react-router-dom"

const TimeSlot = ({Month,Day,Year,slotId,zoomLink}) => {

    const hour = [
        "09:00:00",
        "10:00:00",
        "11:00:00",
        "12:00:00",
        "13:00:00",
        "14:00:00",
        "15:00:00",
        "16:00:00",
        "17:00:00",
      ];
      const slots = [
        "09:00 - 10:00 AM",
        "10:00 - 11:00 AM",
        "11:00 - 12:00 AM",
        "12:00 - 01:00 PM",
        "01:00 - 02:00 PM",
        "02:00 - 03:00 PM",
        "03:00 - 04:00 PM",
        "04:00 - 05:00 PM",
        "05:00 - 06:00 PM",
      ];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let fakeDay;
      let fakeHour;
      let fakeMin;
      let fakeSec;
      // let totalInSec;
      // const tempTime = `${month}/${day}/${year} ${hour[slot - 1]}`;
      const tempTime = `${Month}/${Day}/${Year} ${hour[slotId - 1]}`;
    
      const tempTime1 = new Date(tempTime);
      console.log(tempTime1)
    
      // const diffTime = Math.abs(tempTime1 - new Date());
      const diffTime = Math.abs(tempTime1 - Config.ukTimeZone().getTime());
      fakeHour = Math.floor(diffTime / (1000 * 60 * 60));
      const diffMin = Math.floor(diffTime / (1000 * 60));
      const diffSec = Math.floor(diffTime / 1000);
    
      const hrtomin = fakeHour * 60;
      const hrtosec = fakeHour * 60 * 60;
      fakeMin = diffMin - hrtomin;
      const mintosec = fakeMin * 60;
      fakeSec = diffSec - (hrtosec + mintosec);
    
      const ffTime = tempTime1.getTime();
      console.log(ffTime)
      // const [currentTime, setCurrentTime] = useState(Date.now());
      const [currentTime, setCurrentTime] = useState(Config.ukTimeZone().getTime());
      const [bookedTime, setBookedTime] = useState(ffTime);
      const displayClock = () => {
        const display = new Date().toLocaleTimeString();
        // setCurrentTime(Date.now() + 10000);
        setCurrentTime(Config.ukTimeZone().getTime() + 10000);
        setTimeout(displayClock, 1000);
      };
    
      let result = 0;
      if (currentTime > bookedTime + 3600000) result = 5;
      else if (
        currentTime > bookedTime - 630000 &&
        currentTime < bookedTime + 3600000
      ) {
        // getConsultData();
        result = 4;
      } else if (bookedTime > currentTime + 3600000 * 24) {
        fakeDay = (bookedTime - currentTime) / (3600000 * 24);
        result = 3;
      } else {
        let totalTimeInSec;
        totalTimeInSec = bookedTime / 1000 - currentTime / 1000;
    
        result = 2;
      }
      useEffect(() => {
        displayClock();
      }, []);
    
      console.log("tempTime1", tempTime1);
      console.log("result", result);
      console.log("bookedTime", bookedTime);
      console.log("currentTime", currentTime);

  
return ( 
    <div>
    {result === 3 && (
    <div className="col-md-3 col-sm-3 col-xs-12 agreement_down">
      <i>Meeting link will appear here</i>
      <a
        className="btn  btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3 build_connect_btn Status_pending"
        href="#"
        target="_self"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={6}
          height={7}
          viewBox="0 0 6 7"
          fill="none"
        >
          <rect y="0.0996094" width={6} height={6} rx={3} fill="#FE1DB3" />
        </svg>{" "}
        In {Math.floor(fakeDay)} Days
      </a>
    </div>
  )}

  {result === 5 && (
    <div className="col-md-3 col-sm-3 col-xs-12 agreement_down">
      <a
        className="btn  btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3 build_connect_btn Status_pending"
        target="_blank"
      >
        Completed
      </a>
    </div>
  )}

  {}

  {result === 2 && (
    <div className="col-md-3 col-sm-3 col-xs-12 agreement_down">
      <i>Meeting link will appear here</i>
      <a
        className="btn  btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3 build_connect_btn Status_pending"
        href="#"
        target="_self"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={6}
          height={7}
          viewBox="0 0 6 7"
          fill="none"
        >
          <rect y="0.0996094" width={6} height={6} rx={3} fill="#FE1DB3" />
        </svg>{" "}
        {Math.floor(fakeHour)}:{Math.floor(fakeMin)}:{Math.floor(fakeSec)}
      </a>
    </div>
  )}

   {result === 4 && (
    <div className="">
      <a
        className="btn  btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3 build_connect_btn"
        href={zoomLink}
        target="_blank"
      >
        Join Meeting
      </a>
    </div>
    )}
    </div> )
      
};
export default TimeSlot











