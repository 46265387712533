import React   from 'react'
import Config from '../../config';



      const  imageUploadonserver= (probs) => {
          console.log(probs.data.id)
          var id = probs.data.id
          var ulrImage = probs.data.ulrImage;
           var mainscr = probs.data.mainscr;


        const imageUploadonserver = ()=>{
                var fileInput =
                document.getElementById(id);
             
               var filePath = fileInput.value;
         
             // Allowing file type
              var allowedExtensions =
              /(\.jpg|\.jpeg|\.png|\.gif)$/i;
     
               if (!allowedExtensions.exec(filePath)) {
              
                fileInput.value = '';
                return   Config.showToast ('Invalid file type');
              }
             else
            {
        
              var  [file]  = document.getElementById(id).files
            let formData = new FormData();
            formData.append('photo', file)
            
            Config.post('/admin/media-uploader',formData)
            .then((res) => {
             console.log(res);
                if (res.status) {
                  document.getElementById('Browse').className = "upload-project-img img_zoom"
                  document.getElementById(mainscr).src = res.data
               
                  document.getElementById(ulrImage).value = res.data
                  document.getElementById(id).disabled = "true"
              
                }else{
                Config.showToast("somthing wrong .");
                }
                })
             .catch((err)=> {
                Config.showToast("Unable to upload a file ");
               });
    
    
            //  document.getElementById("image").files
            //  document.getElementById("image").disabled = "true"
            // if (file) {
            //  document.getElementById("mainscr").src =  URL.createObjectURL(file)
            //  }
    
              }
             }


         const RemoveImagePreview = () =>{
            document.getElementById('Browse').className = "upload-project-img "
            document.getElementById(mainscr).src = "assets/img/preview_1.png"
            document.getElementById(ulrImage).src = ''  
       
            document.getElementById(id).value = null
            document.getElementById(id).disabled = false
            }
  

       return (
         <>
            
              {/* Service preview */}
            <div id="ImageField2" className="col-lg-12">
              {/* <label htmlFor="Renewal Date" className=" form-label">
                Preview
              </label> */}
            <div className="project-modal-img legalSevicesPrevie">
            <div className={probs.data.type ==='Edit'? "upload-project-img img_zoom" : "upload-project-img"} id="Browse" >
             <img  id={mainscr} src="assets/img/preview_1.png" alt="img"  />
              </div>
              <img class="remove-img" onClick={()=>{RemoveImagePreview()}} src="https://appuat.userq.com/images/cross.svg" alt="img"/>

              </div>
            </div>
            <div className="col-lg-12">
                <label htmlFor="Phone" className=" form-label">
                {probs.data.label}<span style={{ color: "red" }} >{id=="sec3media"?'':'*'}  </span> <label for="alert" class="tooltip_div"><i class="bi bi-info-circle"></i><span class="tooltip_alert_hover">Accept only .jpg, .jpeg, .png, and .gif files  </span></label>
                </label>
                <input type="file" onChange={()=> imageUploadonserver()} className="form-control" 
                id={id}    required={id=="sec3media"?false:true}/>
               <input style={{display:"none"}} type="text" name={ulrImage}  id={ulrImage} className="form-control" />
              </div>
          </>

  )
}



export default  imageUploadonserver
