import React, { lazy } from "react";
import { useState, useEffect } from "react"
import { Link , useHistory} from "react-router-dom"; 
import Config from '../../config';


 const Header= lazy(() => import("../widgets/Header.js"));
 const Asidebar=  lazy(() => import('../widgets/Asidebar.js'))
 const Footer= lazy(() => import('../widgets/Footer.js'))
 const Jsfile= lazy(() => import('../widgets/Jsfile.js'))
 const PaginationButton  = lazy(() => import('../common/PaginationButton.js'))
 
 

const ViewCoupon = () => {
   
   const [data ,setData] = useState([]);
   const  history = useHistory();
   const [currentPage, setCurrentPage] = useState(1);
  const  [pageMap ,setPage] =  useState([]);
  const  [maxshow,setmaxShow ]= useState(4);
  const  [minshow,setminShow]  = useState(0);
  const  [limit ,setLimit] =  useState(10);

const  [search ,setSearch] =  useState("");
const  [prevSearch ,setPrevSearch] =  useState("");

  const customerData = (limit,page) =>{
   
    Config.get(`/payment/coupon?limit=${limit}&page=${page}&search=${search}&key=&shortBy=`)
    .then((res) => {
     console.log(res);
      if (res.status) {
        // localStorage.setItem("userInfo", JSON.stringify(res.data));
        // localStorage.setItem("token", res.data.token);
        // history.push("/dashboard");
        console.log(res.data)
        setData(res.data)
        setpaginatoion(res.count);
        setCurrentPage(page)


      }else{
      Config.showToast("server error!!! ");
      }
    })
    .catch((err) => {
      // Config.showToast("Please try after some time.");
      console.log("error in get coupon data")
    });

  }

  const setpaginatoion = (ct) =>{
    let page = []
    var limitValue = document.getElementById("limit").value;
    setLimit(parseInt(limitValue))
    //var pageCount = ct/limit == 0? ct/limit : ct/limit+1;
    var pageCount = ((ct/limitValue) === 0)? ct/limitValue : ct/limitValue+1;
    if(isNaN(pageCount)){
      setPage([1]);
    }else{

      for(let i=1;i<pageCount;i++){
        page.push(i)
      }
      setPage(page)
    }
  }

  const Pagination = (data) =>{
    var limitValue = document.getElementById(data).value;
    setLimit(parseInt(limitValue))
   /* let page = []
    let pageCount2 = datacount.servCt/parseInt(limitValue) == 0? datacount.servCt/parseInt(limitValue) : datacount.servCt/parseInt(limitValue)+1;
    for(let j=1;j<pageCount2;j++){
      page.push(j)
    }
    setPage(page)*/
    customerData(limitValue,1)
  }

  const move = (count) =>{
    if(count == -1){
      if(currentPage >1){
        let  newPage =    currentPage-1;
        setCurrentPage(newPage)
        customerData(limit,newPage)
        if(maxshow<5){
        }else{    
         setminShow(minshow-1)
         setmaxShow(maxshow-1)
        }

      }
    }else{
      if(currentPage <pageMap.length ){
        let  newPage  =  currentPage+1
        setCurrentPage(newPage)
        customerData(limit,newPage)
        // alert(pageMap)
        if(maxshow === pageMap.length){
         
        }else{    
        setminShow(minshow+1)
        setmaxShow(maxshow+1)
        }
      }
    }
  }



  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'assets/js/main.js';   //(This is external js url)   
    document.body.appendChild(script);
    customerData(limit,1)
  }, [])

  useEffect(() => {
    console.log("search3", search, prevSearch);
    if (search === "" && prevSearch !== "") {
      customerData(limit, 1);
    }
    setPrevSearch(search);
  }, [search]);
  

  
  const editPlan = (id)=>{
  localStorage.setItem("catID",id);
  history.push('/edit-cat');
  }


  const clear= ()=>{
    document.getElementById("myInput").value = '';
    seachTable(limit, 1)
   }
      
   const seachTable = () =>{
    customerData(limit, 1);
    
     
    // var input, filter, table, tr, td, i, txtValue;
    //   input = document.getElementById("myInput");
    //   filter = input.value.toUpperCase();
    //   table = document.getElementById("myTable");
    //   tr = table.getElementsByTagName("tr");
 
    //   for (i = 0; i < tr.length; i++) {
    //     td = tr[i].getElementsByTagName("td")[0];
    //     if (td) {
    //       txtValue = td.textContent || td.innerText;
    //       if (txtValue.toUpperCase().indexOf(filter) > -1) {
    //         tr[i].style.display = "";
    //         document.getElementById("noRsFound").style.display = "none"
    //       } else {
    //        if(i == 1){
    //         document.getElementById("noRsFound").style.display = "block"
    //        }
    //         tr[i].style.display = "none";
    //       }
    //     }       
    //   }
     
    }



  return (
    <>
      <Jsfile />
      <Header />
      
      <>
  <main id="main" className="main">
 
    

    <section className="section dashboard">
      <div className="row">
        {/* Left side columns */}
        <div className="col-lg-12">
          <div className="row">
            {/* Recent Sales */}
            <div className="col-12">
              <div className="card recent-sales overflow-auto">
                <div className="card-body" id="plan">
                  <h5 className="card-title">Coupons </h5>
                  <div className="dataTable-top">
                  <PaginationButton probs={Pagination}/>
                    <div className="dataTable-search d-flex">
                      <div className="search-bar">
                        <div
                          className="search-form d-flex align-items-center"
                          method="POST"
                          
                        >
                          <input
                            type="text"
                            id="myInput"
                            name="query"
                            placeholder="Search"
                            title="Enter search keyword"
                            className="search_input"
                            value={search}
                            onChange={(e)=>{
                                    setSearch(e.target.value);
                                  }}
                            
                             />
                             <span onClick={() => { seachTable() }} className="search_data_btn" title="Search">
                              <i className="bi bi-search" />
                            </span>
                          
                          
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="clear_data_btn"
                        title="Clear"
                         onClick={()=>{
                                setSearch("");
                              }}
                      >
                        Clear
                      </button>{" "}
                       <a className="add_lawyers_btn">
                       <Link to="/add-coupon" style={{color: 'white'}}> Add Coupon </Link>
                       </a>
                    </div>
                  </div>
                  <table id="myTable" className="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">Product Type </th>
                        <th scope="col">Name </th>
                        <th scope="col" className="shorting">
                        Coupon Code
                        </th>

                        <th scope="col" className="shorting">
                        Discount Type
                        </th>
                        <th scope="col" className="shorting">
                        Discount
                        </th>
                        <th scope="col" className="shorting">
                        Validity
                        </th>
                        <th scope="col" className="shorting">
                        No. of Uses
                        </th>
       
                        <th className="shorting">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                     {data.map(pln => (  <tr>
                      <td><div><span>{pln.productType} </span></div></td>
                        <td><div><span>{pln.name} </span></div></td>
                        <td><div><span>{pln.couponCode}</span></div> </td>
                        <td><div><span>{pln.discountType}</span></div> </td>
                        <td><div><span>{pln.discount}</span></div> </td>
                        <td><div><span>{Config.myDate(new Date(pln.validity).toDateString())}</span></div> </td>
                        <td><div><span>{pln.numberOfUsages}</span></div> </td>
                        
                         <td><span onClick={()=>{history.push(`/edit-coupon/${pln._id}/info`)}} className="pointer view_btn">
                           Edit
                          </span> 
                        
                         
                        </td>
                      </tr> ))}
                     
                    </tbody>
                  </table>
                  <div id="noRsFound" style={{display:"none",  marginLeft:"416px"}}>   No Result  </div>
                  <div  id="lowerPageDiv" class="dataTable-bottom"><div class="dataTable-info"></div>

                        <nav className="dataTable-pagination">
                          <ul className="dataTable-pagination-list ">
                           <li>
                            showing {currentPage} page from {pageMap.length} pages
                          </li>
                          </ul>
                        </nav>
                  
                          <nav class="dataTable-pagination">
                            <ul class="dataTable-pagination-list">

                              <li className={currentPage === 1 ? "pager disabled" : "pager"} ><a onClick={()=>{move(-1)}} >‹</a></li>
                                    {pageMap.map(data=> (
                                    <li className={currentPage ==  data? "active": "" } ><a onClick={()=>{customerData(limit,data)}}  data-page={data}>{data}</a></li>
                                    ))}
                              <li className={currentPage === pageMap.length ? "pager disabled" : "pager"} ><a onClick={()=>{move(+1)}} >›</a></li></ul></nav>
                        </div>
                </div>
              </div>
            </div>
            {/* End Recent Sales */}
          </div>
        </div>
      </div>
    </section>
  </main>
  {/* End #main */}
</>


      <Asidebar />
      <Footer /> 
    
     
      
    </>
  );
};

export default ViewCoupon;
