import React, { lazy } from "react";
import { useState, useEffect } from "react"
import { Link , useHistory} from "react-router-dom";


import Config from '../../config';

 const Header= lazy(() => import("../widgets/Header.js"));
 const ViewButton = lazy(() => import("../widgets/ViewButton.js"));
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
 const Popup = lazy(() => import('../widgets/DeletePop.js'))
 const ProfileImage = lazy(() => import('../widgets/ProfileImage'))


const MyConsultation = lazy(() => import('../customer/MyConsultation'))
const MyConsultationTime = lazy(() => import('../customer/ConsultationTime'))

const MyBooking = lazy(() => import('../customer/Mybooking'))

const CustomerView = () => {

  //  const tab = (type) =>{
  //     // document.getElementById('view_customer').className = "row d-none";
  //     document.getElementById('order_history').className = "row d-none";
  //     document.getElementById('Consultation').className = "row d-none";
  //     document.getElementById('edit_customer').className = "row";

  //    }
  const id = localStorage.getItem("customerID")
  let userType =  sessionStorage.getItem("userType");

  const history = useHistory();
  async function AddCust(e) {
    e.preventDefault();

      var phoneno = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/
      if(e.target.mobile.value.match(phoneno) ){

        }
      else
        {
       return  Config.showToast("Please enter a valid mobile number.");
         }
         const regEx = /^[0-9A-Za-z ]+$/;
          if (!e.target.zip.value.match(regEx)) {
            Config.showToast(
              "Please enter a valid Postal Code.",
              "danger"
            );
            return false;
          }

  var memberSincee = new Date(e.target.memberSince.value).getTime();
  var renewalDatee = new Date(e.target.renewaldate.value).getTime();


  let formData = new FormData();
  formData.append('_id', id)
  formData.append('email', e.target.email.value)
  formData.append('name', e.target.fName.value)
  formData.append('mobile', e.target.mobile.value)
  formData.append('address', e.target.address1.value)
  formData.append('myPlans',[e.target.plan.value])
  formData.append('type', parseInt(e.target.type.value))
  formData.append('city', e.target.city.value)
  formData.append('state', "Not in use")
  formData.append('country', e.target.country.value)
  formData.append('zipCode', e.target.zip.value)
  formData.append('addressTwo', e.target.address2.value)
  formData.append('memberSince', memberSincee)
  formData.append('renewalDate', renewalDatee)
  formData.append('profile', e.target.profile.value)


   Config.putWithFile('/admin/customers',formData)
      .then((res) => {
      console.log(res);
        if (res.status) {

          history.push("/customer")
          }else{
          Config.showToast("somthing wrong .");
          }
        })
      .catch((err) => {
        Config.showToast("Email or contact already exists ");
      });

}


const setDate = () =>{

  var myid =  document.getElementById("Plan").value;
  var days = 0
      plan.map((m)=>{
        if(myid == m._id){
          days = parseInt(m.HLSA)
        }
      })

    document.getElementById("memberSince").value   = Config.date(Date.now());

    document.getElementById("renewaldate").value = Config.date(Date.now()+(86400000*days));



  }


    function handleChange(e) {
      setDate()
      setData(e.target.value);

        var val =   document.getElementById("type").value


        if(val=="0"){

          // document.getElementById("renewaldate").disabled = true
          // document.getElementById("memberSince").disabled = true
          document.getElementById("Plan").disabled = true
          document.getElementById("Plan").value= "63687e98d8b64fe1d42654fa"
        }else{
          // document.getElementById("renewaldate").disabled = false
          // document.getElementById("memberSince").disabled = false
          document.getElementById("Plan").disabled = false

        }
    }


    // const id = localStorage.getItem("customerID")
    const [data ,setData] = useState([]);
    const [data2 ,setData2] = useState([]);

    const customerData =  () =>{

        Config.get(`/admin/customers/id?_id=${id}`)
        .then((res) => {
         console.log(res);
          if (res.status) {
            document.getElementById('Browse').className = "upload-project-img img_zoom"
            document.getElementById("mainscr").src = res.data.profile==''?"assets/img/1.jpg":res.data.profile;
            document.getElementById("profile").value = res.data.profile;

            document.getElementById("image").disabled = true
            if(res.data.type=="1"){
            document.getElementById("renewaldate").value = Config.date(res.data.renewalDate);
            document.getElementById("memberSince").value = Config.date(res.data.memberSince);
            // document.getElementById("Plan").disabled = false

          }
            setData(res.data)
            setData2(res.data)

          }else{
            Config.showToast("server error!!!");
          }
        })
        .catch((err) => {
          Config.showToast("Please try after some time.");
        });

      }


        const sendMail = (email,name)=>{
            const data = {_id:id , email,name}
            Config.post('/admin/resend-activation-email/', data)
            .then((res) => {
            console.log(res);
            if (res.status) {
           Config.showToast("The activation link has been sent to the user's registered email.")
            }else{
               Config.showToast("server error!!! ");
            }
            })
            .catch((err) => {
            Config.showToast("Please try after some time.");
            });
           }



               const RemoveImagePreview = () =>{

                  document.getElementById("ImageField").style.display = "block";
                  document.getElementById("ImageField2").style.display = "none";
                    }





                  const [plan ,setPlan] = useState([]);

                  const  getPlan =  () =>{

                    Config.get('/admin/plans')
                    .then((res) => {
                     console.log(res);
                      if (res.status) {


                        console.log(res.data)
                        setPlan(res.data)

                      }else{
                        Config.showToast("server error!!! ");
                      }
                    })
                    .catch((err) => {
                      Config.showToast("Please try after some time.");
                    });

                  }

                  const setIdinLocal = (id) => {
                    localStorage.setItem("customerID", id);
                    localStorage.setItem("deleteType", "c");

                  }
                  const getAddress = async (zipCode) => {
                    const rawResponse = await fetch(
                      `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${Config.MapAPI}`,
                      {
                        method: "GET",
                      }
                    );
                    const data = await rawResponse.json();
                    console.log("data", data);
                    let city = document.getElementById("city");
                    let country = document.getElementById("country");
                    let address = document.getElementById("address1");
                    let addressTwo = document.getElementById("address2");
                    if (data.status === "OK" && data?.results[0]?.address_components.length) {
                      addressTwo.value = "";
                      data.results[0].address_components.map((item) => {
                        if (item.types.includes("postal_town")) {
                          console.log("val1", item.long_name, city);
                          city.value = item.long_name;
                        } else if (item.types.includes("administrative_area_level_1")) {
                          console.log("val2", item.long_name);
                          country.value = item.long_name;
                        }
                      });
                      if (data?.results[0]?.formatted_address) {
                        address.value = data?.results[0]?.formatted_address;
                      }
                    } else if (data.status === "ZERO_RESULTS"){
                      Config.showToast("Please enter a valid Postal Code.", "danger");
                      city.value = "";
                      country.value = "";
                      address.value = "";
                      addressTwo.value = "";
                    }
                  }


        useEffect(() => {
        getPlan()
        const script = document.createElement('script');
        script.src = 'assets/js/main.js';   //(This is external js url)
        document.body.appendChild(script);
        customerData()

        }, [])

  return (
    <>

      <Header />

      <>
  <main id="main" className="main">
  <Popup />

    {/* End Page Title */}
    <section className="section profile mt-20">


      <div className="row" id="edit_customer">
        <div className="col-lg-12">
          <h5 className="card-title"> Customer Details - {data2.name} </h5>
          <ViewButton />
          <div className="card">
            <div className="card-body">
              {/* Multi Columns Form */}
              <form className="row g-3"  onSubmit={AddCust}>

                <div className="col-md-12">
                 <h4>Personal Details</h4>
                </div>
              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                  Full Name  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="fName"     onChange={(e) => setData(e.target.value)} value={data.name} required/>
              </div>
              {/* <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                  Last Name
                </label>
                <input type="text" className="form-control" id="Lname"     onChange={(e) => setData(e.target.value)} value={data.lastName} />
              </div> */}
              <div className="col-md-4">
                <label htmlFor="inputEmail5" className="form-label">
                  Email  <span style={{color:"red"}} >* </span>
                </label>
                <input type="email" className="form-control" id="email"     onChange={(e) => setData(e.target.value)}  value={data.email} required/>
              </div>
              <div className="col-lg-4 ">
                <label htmlFor="Phone" className=" form-label">
                  Phone  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" id="mobile" className="form-control"    onChange={(e) => setData(e.target.value)}  value={data.mobile}  required/>
              </div>

              <ProfileImage link={data2.profile} />



               <div className="col-md-12">
                 <h4>Address </h4>
                </div>

                <div className="col-6">
                <label htmlFor="inputAddress5" className="form-label" >
                  Street Address 1  <span style={{color:"red"}} >* </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address1"
                  placeholder="1234 Main St"
                  value={data.address}
                  onChange={(e) => setData(e.target.value)}
                  required
                />
              </div>
              <div className="col-6">
                <label htmlFor="inputAddress2" className="form-label">
                  Street Address 2
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address2"
                  placeholder="Apartment, studio, or floor"
                  value={data.addressTwo}
                  onChange={(e) => setData(e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputCity"  className="form-label">
                  City  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="city"     onChange={(e) => setData(e.target.value)}  value={data.city} required/>
              </div>
              <div className="col-md-4">
                <label htmlFor="inputState" className="form-label">
                  Country <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="country"     onChange={(e) => setData(e.target.value)}  value={data.country} required/>
                {/* <select id="state" className="form-select"  onChange={(e) => setData(e.target.value)} value={data.state}>
                  <option selected="">Aberdeen</option>
                  <option>Armagh</option>
                  <option>Wales</option>
                </select> */}
              </div>
              <div className="col-md-4">
                <label htmlFor="zip" className="form-label">
                Postal Code  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="zip"     onChange={(e) => {setData(e.target.value); if(e.target.value.length>3)getAddress(e.target.value);}} value={data.zipCode} required/>
              </div>

               {/* memenership  */}
               <div className="col-md-12">
                 <h4>Memenership Details </h4>
                </div>

              <div className="form-group col-lg-4">
                <label htmlFor="categories" className=" form-label">
                  Type
                </label>
                <select className="form-control form-select" id="type" name="type"  onChange={handleChange}  value={data.type}  disabled>
                  <option value="1">Member</option>
                  <option value="0">Non-Member</option>
                </select>
              </div>


              <div className="form-group col-lg-4">
                <label htmlFor="categories" className=" form-label">
                 Select Plan
                </label>
                <select className="pointer form-control form-select" id="Plan" name="plan"  onChange={handleChange} value={data.planId}
                disabled >
                <option value={'63687e98d8b64fe1d42654fa'} >Choose Plan </option>
                 {plan.map(pln => (
                  <option value={pln._id}  >{pln.plan} </option>

                 ))}
                </select>

              </div>





              <div className="form-group col-lg-4 " style={data.type==0 ?{display:"none"}:{display:"block"} } >
                <label htmlFor="Member Since" className=" form-label">
                  Member Since
                </label>
                <input
                  placeholder="Select date"
                  type="date"
                  id="memberSince"
                  min="2022-09-27"
                  className="form-control datepicker"
                  onChange={(e) => setData(e.target.value)}
                  defaultValue={Config.date(data.memberSince)}
                  disabled

                />
              </div>
              <div className="form-group col-lg-4" style={data.type==0 ?{display:"none"}:{display:"block"}} >
                <label htmlFor="Renewal Date" className=" form-label">
                  Renewal Date
                </label>
                <input
                  placeholder="Select date"
                  type="date"
                  id="renewaldate"
                  min="2022-09-27"
                  className="form-control datepicker"
                  onChange={(e) => setData(e.target.value)}
                  defaultValue={Config.date(data.renewalDate)}
                  disabled
                />
              </div>



              {/* <div style={{display:"none"}} className="form-group col-lg-4 " id="ImageField">
                <label htmlFor="Renewal Date" className=" form-label">
                 Profile picture
                </label>
                <input  className="form-control"  type="file" name="image" id="image" />
              </div>

          <div id="ImageField2" className="col-lg-2">
               <label htmlFor="Renewal Date" className=" form-label">
                 Profile picture
                </label>
           <div className="project-modal-img">
            <div className="upload-project-img" id="Browse" >
              <img  id="mainscr" src={data2.profile != "" ? data2.profile : "assets/img/1.jpg"}  alt="img"  />

          </div>
          <img class="remove-img" onClick={()=>{RemoveImagePreview()} } src="https://appuat.userq.com/images/cross.svg" alt="img"/>


        </div>
        </div> */}

                <div className="text-center">


                 <button type="submit" className="btn submit-btn1">
                    Update
                  </button>

                  <span className="btn submit-btn2">
                  <Link style={{color: 'white'}} to="/customer"> Go Back  </Link>
                  </span>


                  {userType === "admin" && ( <span className="btn  submit-btn3">
                  <Link style={{color: 'white'}}  onClick={()=>{sendMail(data.email,data.name)}}> Re-send Activation Mail  </Link>
                  </span>)}

                  {userType === "admin" && ( <span  className="btn  submit-btn4" data-bs-toggle="modal" onClick={() => { setIdinLocal(data._id) }} data-bs-target="#verticalycentered">Delete</span>)}


                </div>
              </form>
              {/* End Multi Columns Form */}
            </div>

          </div>
        </div>
      </div>
      <div className="row d-none" id="order_history">
        <div className="col-lg-12">
          <h5 className="card-title">Order History </h5>
          <div className="card-body">
          <ViewButton />
          <MyBooking />

          {/* End Left side columns */}
          </div>
        </div>
      </div>

      <div className="row d-none" id="Consultation">
        <div className="col-lg-12">
          <h5 className="card-title">Consultation </h5>
          <ViewButton />
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">

              <MyConsultation />


              </div>
            </div>
          </div>
          {/* End Left side columns */}
        </div>
      </div>

      <div className="row d-none" id="freeConsulation">
        <div className="col-lg-12">
          <h5 className="card-title">Manage Hours </h5>
          <ViewButton  />
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">


              <MyConsultationTime   memberScienc ={data.memberSince} renewalDate={data.renewalDate}  />




              </div>
            </div>
          </div>
          {/* End Left side columns */}
        </div>
      </div>


    </section>
  </main>
  {/* End #main */}
</>


      <Asidebar />
      <Footer />
      <Jsfile />


    </>
  );
};

export default CustomerView;
