import React , { lazy } from 'react'
import { useState, useEffect } from "react"
import Config from '../../config';
import { useHistory,useParams } from "react-router-dom";
 const Popup = lazy(() => import('../widgets/DeletePop.js'))
 const Header= lazy(() => import("../widgets/Header.js"));
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
//  const MediaUpload = lazy(() => import('./MediaUpload'))

const AddRevew = (props) => {
    const {id} = useParams();
 const [data ,setData] = useState([]);
 const catData =  () =>{
   
    Config.get(`/admin/reviews-detail?_id=${id}`)
    .then((res) => {
     console.log(res);
        if (res.status) {
        setData(res.data)
        // document.getElementById('Browse').className = "upload-project-img img_zoom"
        // document.getElementById("media").value = res.data.media;
        // document.getElementById("image").disabled = true
        // document.getElementById("mainscr").src = res.data.media==''?'/assets/img/preview_1.png':res.data.media;

      }else{
        Config.showToast("Please try after some time.");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });

  }

    const setIdinLocal = (id)=>{
       
    localStorage.setItem("deleteId",id);
    localStorage.setItem("deleteType","review");
   
    }
   


    

  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/js/main.js';   //(This is external js url)   
    document.body.appendChild(script);
    if(props.type== "Edit"){
      catData()
     document.getElementById("deleteButton").style.display = "block"
    }
  }, [])

  const history = useHistory();

  // add edit data
    async function AddPlan(e) {
             e.preventDefault();

            //  if(e.target.comments.value.length > 251 ){
            //   return  Config.showToast("Comment should be less than 250 Characters." , "danger");
            //    }
      
             var comments = e.target.comments.value, 
             user = e.target.user.value,
             rating = e.target.rating.value,
             media = "not in use ",
             logoipsus = 'test'


        if(props.type == "Add"){
         var data = {comments,user,rating,media,logoipsus};
            Config.post('/admin/reviews',data)
              .then((res) => {
              console.log(res);
                if (res.status) {
                  history.push("/reviews");
                  }else{
                  Config.showToast("somthing wrong");
                  }
                })
              .catch((err) => {
                Config.showToast("Please try after some time.");
              });

            }else {
              
          // call edit api 
          let data = {_id:id,comments,user,rating,media,logoipsus};

                Config.put('/admin/reviews',data)
                .then((res) => {
                  console.log(res);
                  if (res.status) {
                     
                    history.push("/reviews");
                    }else{
                    Config.showToast("somthing wrong ");
                    }
                  })
                .catch((err) => {
                  Config.showToast("Please try after some time.");
                });



         }

      }
  


  return (
    <>
      <Jsfile />
      <Header />
 <main id="main" className="main">
  <Popup />

  <section className="section">
    {/* <div id="imgLoader"  style={{display:"none"}} className="lds-spinner"><div></div><div>
    </div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{props.type} Reviews </h5>
           
          

            <form className="row g-3" method='post' onSubmit={AddPlan}>
              <div className="col-md-8">
                <label htmlFor="inputName5" className="form-label">
                Comment <span style={{color:"red"}} >* </span>
                </label>
                <textarea rows="4" type="text" className="form-control" id="comments" onChange={(e) => setData(e.target.value)} value={data.comments}  required>
                </textarea>
              </div>
              <div className="col-md-2">
                <label htmlFor="inputName5" className="form-label">
                User  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="user" onChange={(e) => setData(e.target.value)} value={data.user} required/>
              </div>
         
              <div className="col-md-2">
                <label htmlFor="inputName5" className="form-label">
                Rating  <span style={{color:"red"}} >* </span>
                </label>
                <input type="number" min="1" max="5" className="form-control" id="rating" onChange={(e) => setData(e.target.value)} value={data.rating} required/>
              </div>
              {/* <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                Logoipsus  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="logoipsus" onChange={(e) => setData(e.target.value)} value={data.logoipsus} required/>
              </div> */}

              {/* <MediaUpload /> */}
         
       
        
            <div className="text-center">
                  
                  <button type="submit" className="btn submit-btn1">
                  {props.type== "Edit" ? "Update": "Submit" }
                  </button>
 
                  <span onClick={()=>{ history.push("/reviews"); }} className="btn  submit-btn2">
                   Go Back</span>
             

                  <span className="btn  submit-btn4" style={{float:"right", display:"none"}} data-bs-toggle="modal" 
                   id ="deleteButton"
                   onClick={()=>{setIdinLocal(id)}} data-bs-target="#verticalycentered"
                   >Delete</span>
              
              </div>
            </form>
            {/* End Multi Columns Form */}
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
 
      <Asidebar />
      <Footer /> 
    
</>

 )
}

export default AddRevew
