import { NetworkLockedOutlined } from "@material-ui/icons";
import React, { lazy } from "react";
import { useEffect,useState } from "react"
import { NavLink , useHistory} from "react-router-dom";


import Config from '../../config';

 const Header= lazy(() => import("../widgets/Header.js"));
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
 const ProfileUploader = lazy(() => import('./ProfileUploader.js'))
 const ProfileUploaderAdmin = lazy(()=> import('./ProfileUploaderAdmin') )


const AdminView = () => {

   const hideShowPw = (data)=>{ 
    let id = data.split(',')
    var data =  document.getElementById(id[0]).type;
    if(data == 'password'){
     document.getElementById(id[0]).type = "text";
     document.getElementById(id[1]).className = "pointer bi bi bi-eye";
    }else{
     document.getElementById(id[0]).type = "password";
     document.getElementById(id[1]).className = "pointer bi bi-eye-slash";
    }
  

   }


   const [data,setData] = useState({});

    const GetLawyerDetails = ()=>{
    let id = sessionStorage.getItem("LawyerId")

    Config.get(`/admin/lawyers/id?_id=${id}`)
    .then((res) => {
    console.log(res);
    if (res.status) {
      // document.getElementById('Browse').className = "upload-project-img img_zoom"
      document.getElementById("mainscr").src = res.data.profilePicture==''?"assets/img/profile-img.jpg":res.data.profilePicture
      // document.getElementById("image").disabled = true
      // document.getElementById("flexSwitchCheckDefault").disabled = res.data.bannerVideo == ''? false : true ;
        setData(res.data)
        // setVal(res.data.testimonials)
        
         }else{

        Config.showToast("server error!!! ");
       }
    
      })
    .catch((err) => {
    Config.showToast("Please try after some time.");
    });
 


   }

        // edit admin 

       async function editAdmin(e){
          
        e.preventDefault();

        let _id = sessionStorage.getItem("AdminId")
        // let formData = new FormData();
     
        let name = e.target.name.value; 
        let username =  e.target.userName.value; 
        //  console.log(formData)
            
       Config.put('/admin/update-profile',{_id,name,username})
            .then((res) => {
              console.log(res);
                if (res.status) {
              
                  Config.showToast("Saved..👍");
                // history.push("/lawyer")

                }else{
                Config.showToast("somthing wrong .");
                }
                })
                .catch((err) => {
                  Config.showToast(err);
                 });

       }


       // edit lawyer 
         async function editlawyer(e) {
          e.preventDefault();
          var phoneno = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/
          if(e.target.phone.value.match(phoneno) ){

          }
          else
          {
          return  Config.showToast("Please enter a valid mobile number.");

          }

        let id = sessionStorage.getItem("LawyerId")
        // let formData = new FormData();
        let _id = id; 
        let name = e.target.firstName.value; 
        let lastName =  e.target.lastName.value; 
        let email = e.target.email.value;
        let phone =  e.target.phone.value;
        let qualfication =  e.target.qualfication.value;    
        let position =  e.target.position.value;
        let secondaryPosition =  e.target.secondryPosition.value;     
        let city = e.target.city.value;
        let state = e.target.state.value;
        let zipCode = e.target.zip.value;
        let addressTwo =  e.target.address2.value;
        let correspondence =  e.target.correspondence.value;
        let country = e.target.country.value;
        //  console.log(formData)
            
       Config.put('/admin/lawyers',{_id,name,lastName,email,phone,qualfication,position,secondaryPosition,city,state,zipCode,addressTwo,correspondence,country})
            .then((res) => {
              console.log(res);
                if (res.status) {
              
                  Config.showToast("Saved..👍");
                // history.push("/lawyer")

                }else{
                Config.showToast("somthing wrong .");
                }
                })
                .catch((err) => {
                  Config.showToast(err);
                 });

                }

    const history = useHistory();
    async function chanhePass(e) {
              e.preventDefault();

       if(sessionStorage.getItem("userType") == 'lawyer'){
     
      let _id = sessionStorage.getItem("LawyerId"), 
      password = e.target.password_confirm.value,
      password_confirm= e.target.password_confirm2.value
      
      var data = {_id,password,password_confirm};
      console.log(data)


      Config.put('/admin/lawyer-up-pass',data)
      .then((res) => {
        console.log(res);
        if (res.status) {
          Config.showToast("Your password has been saved  !!! ");
          history.push("/")
          }else{
          Config.showToast("Somthing wrong !!! ");
          }
        })
      .catch((err) => {
        Config.showToast("Please try after some time.");
      });

        }else{

          var _id = sessionStorage.getItem("AdminId"), 
          password = e.target.password_confirm.value,
          password_confirm= e.target.password_confirm2.value
          
          var data = {_id,password,password_confirm};
          console.log(data)

      Config.put('/admin/password',data)
      .then((res) => {
      console.log(res);
        if (res.status) {
          Config.showToast("Your password has been saved  !!! ");
          history.push("/")
          }else{
          Config.showToast("Somthing wrong !!! ");
          }
        })
      .catch((err) => {
        Config.showToast("Please try after some time.");
      });

      }

    }



        useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/main.js';   //(This is external js url)
        document.body.appendChild(script);
        // document.getElementById("mainscrAdmin").src = sessionStorage.getItem('profile')==null?"assets/img/profile-img.jpg":sessionStorage.getItem('profile')
        if(sessionStorage.getItem("userType") == 'lawyer'){
         GetLawyerDetails()
         }

        }, [])
  
  return (
    <>  <Header /> <>
  <main id="main" className="main">
  <div className="pagetitle">
    <h1>Profile</h1>
    <nav>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <NavLink activeClassName="pointer" to="/dashboard">Home</NavLink>
        </li>
        <li className="pointer breadcrumb-item active">Profile</li>
      </ol>
    </nav>
  </div>
  {/* End Page Title */}
  <section className="section profile">
    <div className="row">
      <div className="col-xl-4">
        <div className="card">
          <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
          {( sessionStorage.getItem("userType")== "admin" ) &&     <img
              id="profile"
              src={sessionStorage.getItem('profile') == ''?"assets/img/profile-img.jpg":sessionStorage.getItem('profile')}
              alt="Profile"
              className="rounded-circle"
            />}
        {( sessionStorage.getItem("userType")== "lawyer" ) &&     <img
              id="profile"
              src={data.profilePicture == ''?"assets/img/profile-img.jpg":data.profilePicture}
              alt="Profile"
              className="rounded-circle"
            />}
            <h2>{sessionStorage.getItem("username")}</h2>
            {/* <h3> London, United Kingdom</h3> */}
            {/* <div class="social-links mt-2">
          <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
          <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
          <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
          <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
        </div> */}
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="card">
          <div className="card-body pt-3">
            {/* Bordered Tabs */}
            <ul className="nav nav-tabs nav-tabs-bordered">
              <li className="nav-item">
                <button
                  className="nav-link active"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-overview"
                >
                  Overview
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-edit"
                >
                  Edit Profile
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-change-password"
                >
                  Change Password
                </button>
              </li>
            </ul>
            <div className="tab-content pt-2">
              <div className="tab-pane fade show active profile-overview" id="profile-overview" >
                {/* <h5 className="card-title">About</h5>
                <p className="small fst-italic">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellat vero perspiciatis eveniet exercitationem obcaecati
                  fugit totam hic, excepturi magnam, consectetur itaque tempore
                  quod rerum doloribus. Perferendis ipsum voluptate asperiores
                  consequuntur.
                </p> */}
                
                <h5 className="card-title">Profile Details</h5>
                <div className="row">
                  <div className="col-lg-3 col-md-4 label ">User Name</div>
                  <div className="col-lg-9 col-md-8">{sessionStorage.getItem("username")}</div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Name</div>
                  <div className="col-lg-9 col-md-8">{sessionStorage.getItem("name")}</div>
                </div>
                {/* <div className="row">
                  <div className="col-lg-3 col-md-4 label">Country</div>
                  <div className="col-lg-9 col-md-8">UK</div>
                </div> */}
                {/* <div className="row">
                  <div className="col-lg-3 col-md-4 label">Address</div>
                  <div className="col-lg-9 col-md-8">
                    Senate House, Malet St, London WC1E 7HU, United Kingdom
                  </div>
                </div> */}
                {/* <div className="row">
                  <div className="col-lg-3 col-md-4 label">Phone</div>
                  <div className="col-lg-9 col-md-8">(436) 999-999-99999</div>
                </div> */}
                {/* <div className="row">
                  <div className="col-lg-3 col-md-4 label">Email</div>
                  <div className="col-lg-9 col-md-8">admin@example.com</div>
                </div> */}
              </div>
              <div className="tab-pane fade profile-edit pt-3"
                id="profile-edit"> 
                {/* Profile Edit Form */}

             
               {/* Admin edit profile form  */}
               {( sessionStorage.getItem("userType")== "admin" ) && <form  onSubmit={editAdmin}>
                  {/* <div className="row mb-3">
                    <label
                      htmlFor="profileImage"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      Profile Image
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <img
                        src="assets/img/profile-img.jpg"
                        alt="Profile"
                        className="up_img"
                      />
                      <div className="d-flex">
                        <div
                          className="pt-2 col-1"
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            overflow: "hidden"
                          }}
                        >
                          <a
                            href="#"
                            className="btn btn-primary btn-sm"
                            title="Upload new profile image"
                            style={{ cursor: "pointer" }}
                          >
                            <i className="bi bi-upload" />
                          </a>
                          <input
                            type="file"
                            name="myfile"
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              opacity: 0
                            }}
                          />
                        </div>
                        <div className="pt-2 col-1">
                          <a
                            href="#"
                            className="btn btn-danger btn-sm delete_img"
                            title="Remove my profile image"
                          >
                            <i className="bi bi-trash" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <ProfileUploaderAdmin probs={sessionStorage.getItem('profile')} />
                  <div className="row mb-3">
                    <label
                      htmlFor="fullName"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      User Name
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <input
                        name="userName"
                        type="text"
                        className="form-control"
                        id="userName"
                        defaultValue={sessionStorage.getItem("username")}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="fullName"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      Name
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        id="name"
                        defaultValue={sessionStorage.getItem("name")}
                       />
                    </div>
                  </div>
                  {/* <div className="row mb-3">
                    <label
                      htmlFor="about"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      About
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <textarea
                        name="about"
                        className="form-control"
                        id="about"
                        style={{ height: 100 }}
                        defaultValue={
                          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati modi sapiente a iusto in deleniti consequuntur distinctio labore illo molestias? Nemo reprehenderit, ratione cumque porro odio vitae! Recusandae, voluptates non."
                        }
                      />
                    </div>
                  </div> */}
                  {/* <div className="row mb-3">
                    <label
                      htmlFor="Country"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      Country
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <input
                        name="country"
                        type="text"
                        className="form-control"
                        id="Country"
                        defaultValue="UK"
                      />
                    </div>
                  </div> */}
                  {/* <div className="row mb-3">
                    <label
                      htmlFor="Address"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      Address
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <input
                        name="address"
                        type="text"
                        className="form-control"
                        id="Address"
                        defaultValue="Senate House, Malet St, London WC1E 7HU, United Kingdom"
                      />
                    </div>
                  </div> */}
                  {/* <div className="row mb-3">
                    <label
                      htmlFor="Phone"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      Phone
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <input
                        name="phone"
                        type="text"
                        className="form-control"
                        id="Phone"
                        defaultValue="(436) 999-999-99999"
                      />
                    </div>
                  </div> */}
                  {/* <div className="row mb-3">
                    <label
                      htmlFor="Email"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      Email
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        id="Email"
                        defaultValue="admin@example.com"
                      />
                    </div>
                  </div> */}
                  <div className="text-center">
                    <button type="submit" className="btn submit-btn1">
                      Save Changes
                    </button>
                  </div>
               </form>}
               {/* Lawyer edit profile form  */}
               {(sessionStorage.getItem("userType")== "lawyer" ) &&  <form   onSubmit={editlawyer}>
                  {/* <div className="row mb-3">
                    <label
                      htmlFor="profileImage"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      Profile Image
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <img
                        src="assets/img/profile-img.jpg"
                        alt="Profile"
                        className="up_img"
                      />
                      <div className="d-flex">
                        <div
                          className="pt-2 col-1"
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            overflow: "hidden"
                          }}
                        >
                          <a
                            href="#"
                            className="btn btn-primary btn-sm"
                            title="Upload new profile image"
                            style={{ cursor: "pointer" }}
                           >
                          <i className="bi bi-upload" />
                          </a>
                          <input type="file"  name="myfile" style={{ position: "absolute", left: 0, top: 0, opacity: 0 }} />
                          </div>
                        <div className="pt-2 col-1">
                           <a
                            href="#"
                            className="btn btn-danger btn-sm delete_img"
                            title="Remove my profile image"
                           >
                            <i className="bi bi-trash" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <ProfileUploader  />

                  <div className="row mb-3">
                    <label
                      htmlFor="fullName"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      First Name
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <input
                        name="firstName"
                        type="text"
                        className="form-control"
                        id="firstName"
                        onChange={(e) => setData(e.target.value)} 
                        value={data.name}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="fullName"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                     Last Name
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <input
                        name="lastName"
                        type="text"
                        className="form-control"
                        id="lastName"
                        onChange={(e) => setData(e.target.value)} 
                        value={data.lastName}

                        // defaultValue={sessionStorage.getItem("name")}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="fullName"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      Email
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <input
                        onChange={(e) => setData(e.target.value)} 
                        name="email"
                        type="text"
                        className="form-control"
                        id="email"
                        value={data.email}
                        // defaultValue={sessionStorage.getItem("name")}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Mobile</label>
                    <div className="col-md-8 col-lg-9">
                      <input name="phone" type="text" className="form-control" onChange={(e) => setData(e.target.value)}   id="phone"  value={data.phone} required/>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Qualificaton</label>
                    <div className="col-md-8 col-lg-9">
                      <input name="qualfication" type="text" className="form-control" onChange={(e) => setData(e.target.value)}   id="qualfication"  value ={data.qualfication} required/>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Position</label>
                    <div className="col-md-8 col-lg-9">
                      <input name="position" type="text" className="form-control" onChange={(e) => setData(e.target.value)}  id="position" value={data.position} required/>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="sec pos" className="col-md-4 col-lg-3 col-form-label">Sec Position</label>
                     <div className="col-md-8 col-lg-9">
                      <input name="secondryPosition" type="text" className="form-control"  onChange={(e) => setData(e.target.value)}  id="secondryPosition"  value={data.secondaryPosition} required/>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Correspondence Address *</label>
                    <div className="col-md-8 col-lg-9">
                      <input name="correspondence" type="text" className="form-control" onChange={(e) => setData(e.target.value)}   id="correspondence"  value={data.correspondence} required/>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Street Address 2 *</label>
                    <div className="col-md-8 col-lg-9">
                      <input name="address2" type="text" className="form-control" onChange={(e) => setData(e.target.value)}  id="address2"  value={data.addressTwo} required/>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">city</label>
                    <div className="col-md-8 col-lg-9">
                      <input name="city" type="text" className="form-control" onChange={(e) => setData(e.target.value)}  id="city"  value={data.city} required/>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">state</label>
                    <div className="col-md-8 col-lg-9">
                      <input name="state" type="text" className="form-control" onChange={(e) => setData(e.target.value)}   id="state"  value={data.state} required/>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label htmlFor="zip" className="col-md-4 col-lg-3 col-form-label">zip</label>
                    <div className="col-md-8 col-lg-9">
                      <input name="zip" type="text" className="form-control" onChange={(e) => setData(e.target.value)}   id="zip"  value={data.zipCode} required/>
                    </div>
                  </div>

                 
                  <div className="row mb-3">
                    <label
                      htmlFor="Country"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      Country
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <input
                       onChange={(e) => setData(e.target.value)}
                        name="country"
                        type="text"
                        value={data.country}
                        className="form-control"
                        id="country"
                        required
                        
                      />
                     </div>
                  </div>
             
                  <div className="text-center">
                    <button type="submit"   className="btn submit-btn1">
                      Save Changes
                    </button>
                  </div>
                </form>}

              




                {/* End Profile Edit Form */}
              </div>
              <div className="tab-pane fade pt-3" id="profile-settings">
                {/* Settings Form */}
                <form>
                  <div className="row mb-3">
                    <label
                      htmlFor="fullName"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      Email Notifications
                    </label>
                    <div className="col-md-8 col-lg-9">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="changesMade"
                          defaultChecked=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="changesMade"
                        >
                          Changes made to your account
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="newProducts"
                          defaultChecked=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="newProducts"
                        >
                          Information on new products and services
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="proOffers"
                        />
                        <label className="form-check-label" htmlFor="proOffers">
                          Marketing and promo offers
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="securityNotify"
                          defaultChecked=""
                          disabled=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="securityNotify"
                        >
                          Security alerts
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn submit-btn1">
                      Save Changes
                    </button>
                  </div>
                </form>
                {/* End settings Form */}
              </div>
              <div className="tab-pane fade pt-3" id="profile-change-password">
                {/* Change Password Form */}
                <form method="post"  onSubmit={chanhePass} >
                  <div className="row mb-3">
                    <label
                      htmlFor="currentPassword"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      Current Password
                    </label>
                    <div
                      className="input-group show_hide_password1 col-md-8 col-lg-9"
                      style={{ width: "75%" }}
                    >
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="currentPassword"
                        defaultValue={sessionStorage.getItem("userType")=='lawyer'?sessionStorage.getItem("OldPw"):''}
                        required=""
                      />
                      <span  onClick={()=>{hideShowPw("currentPassword,f1")}}  className="input-group-text">
                     
                        <i id="f1" className="pointer bi bi-eye-slash" />
                      </span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="newPassword"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      New Password
                    </label>
                    <div
                      className="input-group show_hide_password2 col-md-8 col-lg-9"
                      style={{ width: "75%" }}
                    >
                      <input
                        type="password"
                        name="password_confirm"
                        className="form-control"
                        id="password_confirm"
                        required=""
                      />
                      <span onClick={()=>{hideShowPw("password_confirm,f2")}} className="input-group-text">
                         <i id="f2" className="pointer bi bi-eye-slash" />
                      </span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="renewPassword"
                      className="col-md-4 col-lg-3 col-form-label"
                    >
                      Re-enter New Password
                    </label>
                    <div
                      className="input-group show_hide_password3 col-md-8 col-lg-9"
                      style={{ width: "75%" }}
                    >
                      <input
                        type="password"
                        name="password_confirm2"
                        className="form-control"
                        id="password_confirm2"
                        required=""
                      />
                      <span onClick={()=>{hideShowPw("password_confirm2,f3")}} className="input-group-text">
                     
                        <i  id="f3" className="pointer bi bi-eye-slash" />
                      </span>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn submit-btn1">
                      Change Password
                    </button>
                  </div>
                </form>

                {/* End Change Password Form */}
              </div>
            </div>
            {/* End Bordered Tabs */}
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

</>


      <Asidebar />
      <Footer /> 
      <Jsfile />
     
  
    </>
  );
};

export default AdminView;
