import React, { lazy } from "react";
import { useState, useEffect } from "react"
import Config from '../../config';
import { useHistory,useParams } from "react-router-dom";
import { QuestionCard } from "./Question/QuestionCard";
import { QuestionInput } from "./Question/QuestionInput";
import { EditQuestionInput } from "./Question/EditQuestionInput";
import { Question } from "./Question/Question";
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import { isEmpty } from 'lodash';
import { EditorState,convertToRaw,convertFromRaw,ContentState } from 'draft-js';
import { GetDataFromContext } from "../../context/dataContext.js";



const Header= lazy(() => import("../widgets/Header.js"));
const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
const Footer = lazy(() => import('../widgets/Footer.js'))
const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
const Popup = lazy(() => import('../widgets/DeletePop.js'))

const ViewLegalDocumentQuestionnaire = () => {

  let { id } = useParams();
  let history = useHistory();
  const [documentData, setDocumentData] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questions, setQuestions] = useState([]);
  const [questionIdArray, setQuestionIdArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [edittingQuestions, setEdittingQuestions] = useState(false);
  const [editQuestionId, setEditQuestionId] = useState(null);
  const [isCall, setCall] = useState(false);

  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const [placeholderProps, setPlaceholderProps] = useState({});

  const [textDocumentOptionQuestion, setTextDocumentOptionQuestion] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);

  const cancelQuestionHandler = () => {
    setQuestionType("");
  };

  const removequestion = (index) => {
    setCall(true);

    const list = [...questions];

    //console.log(questionIdArray);
    //return false;
    if(questions[index]._id) {

      // check deleted question is assigned on legal agreement or not
      const checkAssignedQuestion = questionIdArray.find(c => c === questions[index]._id);
      if(checkAssignedQuestion){
        Config.showToast("This question is already assigned in legal agreement docx. Please remove question from legal agreement first. ");
        return  false;
      }

      /// check question is not assigned on questionaire option
      //console.log(questions[index]._id);
      // console.log(textDocumentOptionQuestion);
      var isQuestionExits = false;
      if(textDocumentOptionQuestion) {
        textDocumentOptionQuestion.forEach((items) => {
          items.OptionQuestionId.forEach((item) => {

            if (questions[index]._id == item) {

              isQuestionExits = true;

            }
          })
        })
      }
      // return false;

      if(isQuestionExits){
        Config.showToast("This question is already assigned in Questionnaire option. Please remove question from Questionnaire option first. ");
        return  false;
      }

      Config.delete('/admin/delete-questionnaire?_id='+questions[index]._id+'&type=question&documentId='+id)
        .then((res) => {
          //console.log(res);
          if (res.status) {
            //Config.showToast("Deleted successfully . !!! ");
            // history.push("/legaldoc")
          }else{
            //Config.showToast("server error!!! ");
          }
        })
        .catch((err) => {
          Config.showToast("Bad Api request");
        });
    }

    //const list = [...questions];
    list.splice(index, 1);
    setQuestions(list);
  };

  const cancelEditQuestionHandler = () => {
    setEdittingQuestions(false);
    setEditQuestionId(null);
  };

  const duplicatequestion = (index) => {
    setCall(true);
    if (!edittingQuestions) {
      let list = [...questions];

      let data = Object.assign({});



      var textDocumentOptionNew = [];
      if(questions[index].questionType === 'singlechoice' || questions[index].questionType === 'multiplechoice'){

        questions[index].textDocumentOption.forEach((items)=>{

          textDocumentOptionNew.push(JSON.stringify(convertToRaw(items.getCurrentContent())));

        })

      }

      // some trick logic
      var  QuestionId = questions[index]._id;

      var textDocumentOptionAttachedId = textDocumentOptionQuestion.filter(c=>c.questionId === QuestionId);
      if(textDocumentOptionAttachedId && textDocumentOptionAttachedId.length > 0){
        data["textDocumentOptionQuestion"] = textDocumentOptionAttachedId[0].OptionQuestionId;
      }else{
        data["textDocumentOptionQuestion"] = [];
      }
      // console.log(textDocumentOptionAttachedId)
      // console.log(textDocumentOptionQuestion)
      // console.log(QuestionId)
      // return false

      questions[index].textDocumentOptionNew = textDocumentOptionNew;
      data["documentId"] = id;
      data['newQuestions'] = questions[index];
      data['newQuestionsLength'] = index;
      //data["textDocumentOptionQuestion"] = textDocumentOptionQuestion;

      // setLoading(true);


      Config.post('/admin/add-single-questionnaire',data)
        .then((res) => {
          if (res.data) {


            var textDocumentOptionNews = [];
            if(questions[index].questionType === 'singlechoice' || questions[index].questionType === 'multiplechoice'){

              questions[index].textDocumentOptionNew.forEach((items)=>{

                const rawContentFromStore = convertFromRaw(JSON.parse(items));
                textDocumentOptionNews.push(EditorState.createWithContent(rawContentFromStore));

              })

            }

            res.data.textDocumentOption = textDocumentOptionNews;

            let question = Object.assign({},res.data);
            list.splice(index, 0, question);

            // list[index+1]._id = '';
            //list[index+1].optionsId = [];

            setQuestions(list);
          }else{
            Config.showToast(res.message);
          }
        })
        .catch((err) => {
          Config.showToast("Bad Api request");
        });


      //var oldQuestionDulicateData = questions[index];
      //   let question = Object.assign({},questions[index]);
      //   list.splice(index, 0, question);
      //
      //   list[index+1]._id = '';
      //   list[index+1].optionsId = [];
      //
      //   setQuestions(list);
    } else {
      Config.showToast("Finish editing question");
    }
  };

  const edittingQuestionsHandler = (index) => {
    if (!edittingQuestions) {
      setEdittingQuestions(true);
      setEditQuestionId(index);
    } else {
      Config.showToast("Finish editing earlier question.");
    }
  };


  useEffect(() => {

    const script = document.createElement('script');
    script.src = '/assets/js/main.js';   //(This is external js url)
    document.body.appendChild(script);
    // for layer dropdown

    getQuestions();

  }, [])

  useEffect(() => {
    if(isCall) {
      //saveQuestions();
      console.log(questions)
    }

  }, [questions,isCall]);

  const saveQuestions = () => {


    let data = Object.assign({});

    data["documentId"] = id;
    data['questions'] = questions;

    //setLoading(true);

    Config.post('/admin/add-questionnaire',data)
      .then((res) => {
        //console.log(res)
        //setQuestions(res.data);
        //setLoading(false);
        //getQuestions();
        if (res.data) {

        }else{
          Config.showToast(res.message);
        }
      })
      .catch((err) => {
        Config.showToast("Bad Api request");
      });

  };

  const  checkJSON = (strJson)=> {
    try {
      const parsed = JSON.parse(strJson)
      if (parsed && typeof parsed === "object") {
        return true
      }
    } catch { return false }
    return false
  }

  const getQuestions = ()=>{
    setLoading(true);
    Config.get('/admin/get-questionnaire?_id='+id)
      .then((res) => {

        setLoading(false);
        setDocumentData(res.document)
        setTextDocumentOptionQuestion(res.document.textDocumentOptionQuestion);


        if(res.agreementData) {
          setQuestionIdArray(res.agreementData.questionIdArray);
        }
        //console.log(res.data)
        if (res.data.length > 0) {

          var questionArray = [];
          var questionData = [];
          var questionCount = 1;
          res.data.forEach((items)=>{

            // check option array
            var optionArray = [];
            var jumpToArray = [];
            var optionIdArray = [];
            var textDocumentOptionArray = [];
            if(items.Options) {
              items.Options.forEach((itemsOptions) => {
                optionArray.push(itemsOptions.optionValue)
                optionIdArray.push(itemsOptions._id)
                jumpToArray.push(itemsOptions.jumpToOption)


                //check valid json or not
                if(checkJSON(itemsOptions.textDocumentOption)) {
                  const rawContentFromStore = convertFromRaw(JSON.parse(itemsOptions.textDocumentOption));

                  textDocumentOptionArray.push(EditorState.createWithContent(rawContentFromStore))
                }else{
                  textDocumentOptionArray.push("")
                }
                // textDocumentOptionArray.push(itemsOptions.textDocumentOption)
              })
            }

            questionArray.push({
              _id:items._id,
              is_optional:false,
              question:items.question,
              questionType:items.questionType,
              text_help:items.texHelp,
              is_logic:items.is_logic,
              is_add_other: items.is_add_other,
              logic_option:items.logic_option,
              text_document:items.text_document,
              options:optionArray,
              optionsId:optionIdArray,
              jumpTo:jumpToArray,
              textDocumentOption:textDocumentOptionArray,
              order:items.order,
              questionTag:"Question_",
            })

            //console.log(items.Options)

            if (items.questionType !== 'pagebreak') {

              if(items.questionType === 'free') {
                questionData.push({
                  text: 'Q' + questionCount + ' '+ items.question,
                  value: 'Q' + questionCount + ' '+ items.question,
                  url: items._id,
                  type:'free',
                  questionOptionId:''
                })
                questionCount++;
              }else{

                // set suggestions
                // if(items.Options) {
                //   items.Options.forEach((itemsOptions,i) => {
                //     var OptionCount = i+1;
                //     questionData.push({
                //       text: 'Q' + questionCount + ' '+ items.question + ' (Option '+OptionCount+')',
                //       value: 'Q' + questionCount + ' '+ items.question +  ' (Option '+OptionCount+')',
                //       url: itemsOptions._id,
                //       type:items.questionType,
                //       questionOptionId:items._id
                //     })
                //   })
                //
                //   questionCount++;
                // }

                questionData.push({
                  text: 'Q' + questionCount + ' '+ items.question,
                  value: 'Q' + questionCount + ' '+ items.question,
                  url: items._id,
                  type:items.questionType,
                  questionOptionId:items._id
                })
                questionCount++;

              }
            }


          })

          //console.log(questionArray)
          setQuestions(questionArray);
          setQuestionsData(questionData);
        }else{
          // Config.showToast(res.message);
        }
      })
      .catch((err) => {
        console.log(err)
        Config.showToast("Bad Api request");
      });
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;

  const handleDragStart = (event) => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };

  const handleDragUpdate = (event) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;

    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];


    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };

  const handleDragEnd = result => {
    setPlaceholderProps({});
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let items = reorder(
      questions,
      result.source.index,
      result.destination.index
    );

    //console.log(items)

    // update order of questions
    let data = Object.assign({});

    data["documentId"] = id;
    data['questions'] = items;

    //setLoading(true);
    data['questions'] = data['questions'].map((item , index) =>( {...item , questionTag : `Question_${index+1}`}))

    Config.post('/admin/update-order-questionnaire',data)
    .then((res) => {
        //console.log(res)
        //setQuestions(res.data);
        //setLoading(false);
        //getQuestions();
        if (res.data) {

        }else{
          Config.showToast(res.message);
        }
      })
      .catch((err) => {
        Config.showToast("Bad Api request");
      });

    setQuestions(items);
  };

  const getDraggedDom = (draggableId) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };


const { isAddOther , setQuestionAnswers} = GetDataFromContext()
  const addNewQuestion = (question) =>{
    let data = Object.assign({});

    question["is_add_other"] = isAddOther
    
    data["documentId"] = id;
    data['newQuestions'] = question;
    data['newQuestionsLength'] = questions.length;


    // set question data logic
    // set data for mention suggestion
    var questionData1 = [];
    var questionCount = 1;
    questions.forEach((items)=>{

      if (items.questionType !== 'pagebreak') {

        if(items.questionType === 'free') {
          questionData1.push({
            text: 'Q' + questionCount + ' '+ items.question,
            value: 'Q' + questionCount + ' '+ items.question,
            url: items._id,
            type:'free',
            questionOptionId:''
          })
          questionCount++;
        }else{

          // set suggestions
          // if(items.optionsId) {
          //   items.optionsId.forEach((itemsOptions,i) => {
          //     //console.log(itemsOptions)
          //     var OptionCount = i+1;
          //     questionData1.push({
          //       text: 'Q' + questionCount + ' '+ item  s.question + ' (Option '+OptionCount+')',
          //       value: 'Q' + questionCount + ' '+ items.question +  ' (Option '+OptionCount+')',
          //       url: itemsOptions,
          //       type:items.questionType,
          //       questionOptionId:items._id
          //     })
          //   })
          //
          //   questionCount++;
          // }

          questionData1.push({
            text: 'Q' + questionCount + ' '+ items.question,
            value: 'Q' + questionCount + ' '+ items.question,
            url: items._id,
            type:items.questionType,
            questionOptionId:items._id
          })
          questionCount++;

        }
      }

    })
    
    setQuestionsData(questionData1);

    var textDocumentOptionArray = [];
    question.textDocumentOptionNew = [];
    if(question.questionType === 'singlechoice' || question.questionType === 'multiplechoice'){

      question.textDocumentOption.forEach((items)=>{

        question.textDocumentOptionNew.push(JSON.stringify(convertToRaw(items.getCurrentContent())));

        const contentState = items.getCurrentContent();

        const entities = [];
        contentState.getBlockMap().forEach(block => { // could also use .map() instead
          block.findEntityRanges(character => {
            const charEntity = character.getEntity();
            if (charEntity) { // could be `null`
              const contentEntity = contentState.getEntity(charEntity);
              entities.push(contentEntity);
            }
          });
        });

        //const questionMapArrays = [];
        entities.forEach((entity, i) => {
          //if (entity.get('type') === 'ANNOTATION') {
          const data = entity.get('data');
          if(data){
            // console.log(data)
            // console.log(questionData1)
            var questionMap = questionData1.find(c => c.url === data.url);
            // console.log(questionMap)
            if(questionMap){
              textDocumentOptionArray.push(questionMap.url);
              // check question type is not free
              // if(questionMap.questionOptionId){
              //   textDocumentOptionArray.push(questionMap.questionOptionId);
              // }
            }
            // console.log(data.url)
          }
          // do something
          //}
        })

      })

    }

    // console.log(textDocumentOptionArray);
    //var newTmpArray = arrayDeDuplicate(textDocumentOptionArray,textDocumentOptionQuestion);
    //setTextDocumentOptionQuestion(newTmpArray);
    data["textDocumentOptionQuestion"] = textDocumentOptionArray;
    // setLoading(true);

    Config.post('/admin/add-single-questionnaire',data)
      .then((res) => {
        //console.log(res)
        //setQuestions(res.data);
        //setLoading(false);
        // getQuestions();
        //console.log(res)
        if (res.data) {

          // if response is there -> we store the payload to the context

          setQuestionAnswers(data)

          var textDocumentOptionNew = [];
          if(question.questionType === 'singlechoice' || question.questionType === 'multiplechoice'){

            question.textDocumentOptionNew.forEach((items)=>{

              const rawContentFromStore = convertFromRaw(JSON.parse(items));
              textDocumentOptionNew.push(EditorState.createWithContent(rawContentFromStore));

            })

          }

          res.data.textDocumentOption = textDocumentOptionNew;

          // set assigned question
          setTextDocumentOptionQuestion(res.DocumentsData.textDocumentOptionQuestion);

          setQuestions([...questions, res.data]);
          setQuestionType("");
          setCall(true);
        }else{
          Config.showToast(res.message);
        }
      })
      .catch((err) => {
        Config.showToast("Bad Api request");
      });

    // setQuestions([...questions, question]);
    // setQuestionType("");
    // setCall(true);
  }

  const arrayDeDuplicate= (...args)=>{
    let set = new Set(); // init Set object (available as of ES6)
    for(let arr of args){ // for of loops through values
      arr.map((value) => { // map adds each value to Set object
        set.add(value); // set.add method adds only unique values
      });
    }
    return [...set]; // destructuring set object back to array object
    // alternativly we culd use:  return Array.from(set);
  }

  const updateQuestion = (index,question)=>{
    // setCall(true);
    let data = Object.assign({});

    data["documentId"] = id;
    data['newQuestions'] = question;

    var textDocumentOptionArray = [];
    question.textDocumentOptionNew = [];
    if(question.questionType === 'singlechoice' || question.questionType === 'multiplechoice'){

      question.textDocumentOption.forEach((items)=>{

        question.textDocumentOptionNew.push(JSON.stringify(convertToRaw(items.getCurrentContent())));

        const contentState = items.getCurrentContent();

        const entities = [];
        contentState.getBlockMap().forEach(block => { // could also use .map() instead
          block.findEntityRanges(character => {
            const charEntity = character.getEntity();
            if (charEntity) { // could be `null`
              const contentEntity = contentState.getEntity(charEntity);
              entities.push(contentEntity);
            }
          });
        });

        //const questionMapArrays = [];
        entities.forEach((entity, i) => {
          //if (entity.get('type') === 'ANNOTATION') {
          const data = entity.get('data');
          if(data){
            // console.log(data)
            var questionMap = questionsData.find(c => c.url === data.url);
            if(questionMap){
              textDocumentOptionArray.push(questionMap.url);
              // check question type is not free
              // if(questionMap.questionOptionId){
              //   textDocumentOptionArray.push(questionMap.questionOptionId);
              // }
            }
            // console.log(data.url)
          }
          // do something
          //}
        })

      })

    }

    // var newTmpArray = arrayDeDuplicate(textDocumentOptionArray,textDocumentOptionQuestion);
    // setTextDocumentOptionQuestion(newTmpArray);
    // data["textDocumentOptionQuestion"] = newTmpArray;
    data["textDocumentOptionQuestion"] = textDocumentOptionArray;
    // setLoading(true);

    Config.post('/admin/update-single-questionnaire',data)
      .then((res) => {
        //console.log(res)
        //setQuestions(res.data);
        //setLoading(false);
        // getQuestions();
        //console.log(res)
        if (res.data) {

          var textDocumentOptionNew = [];
          if(question.questionType === 'singlechoice' || question.questionType === 'multiplechoice'){

            question.textDocumentOptionNew.forEach((items)=>{

              const rawContentFromStore = convertFromRaw(JSON.parse(items));
              textDocumentOptionNew.push(EditorState.createWithContent(rawContentFromStore));

            })

          }

          // set assigned question
          setTextDocumentOptionQuestion(res.DocumentsData.textDocumentOptionQuestion);

          res.data.textDocumentOption = textDocumentOptionNew;

          questions.splice(
            index,
            1,
            res.data
          );
          setQuestions([
            ...questions,
          ]);
        }else{
          Config.showToast(res.message);
        }
      })
      .catch((err) => {
        Config.showToast("Bad Api request");
      });

  }

  const [showInputFieldInQuestion , setShowInputFieldInQuestion] = useState(false);

const parentFunction = (data) => {
if(data){setShowInputFieldInQuestion(data)}

}


  return (
    <>
      <Header />
      <Asidebar />
      <main id="main" className="main">

        <Popup />
        <section className="section">
          <div id="imgLoader" style={{display:"none"}} className="lds-spinner"><div></div><div>
          </div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">

                {loading ?
                  <span className={"go-back d-flex"} style={{height:'26px;'}}>
                  </span>
                  :
                  <span className={"go-back d-flex"} onClick={() => history.goBack()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M15 8C15 7.72386 14.7761 7.5 14.5 7.5L2.70711 7.5L5.85355 4.35355C6.04882 4.15829 6.04882 3.84171 5.85355 3.64645C5.65829 3.45118 5.34171 3.45118 5.14645 3.64645L1.14645 7.64645C0.951184 7.84171 0.951184 8.15829 1.14645 8.35355L5.14645 12.3536C5.34171 12.5488 5.65829 12.5488 5.85355 12.3536C6.04882 12.1583 6.04882 11.8417 5.85355 11.6464L2.70711 8.5H14.5C14.7761 8.5 15 8.27614 15 8Z"
                          fill="black"/>
                  </svg>
                  <div>{(documentData) ? documentData.name : ''}</div>
                </span>
                }

                <ul className="nav nav-tabs nav-tabs-bordered">
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      onClick={()=>{history.push(`/legal-document/${id}/information`)}}
                    >
                      Information
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link active"
                      onClick={()=>{history.push(`/legal-document/${id}/questionnaire`)}}
                    >
                      Questionnaire
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      onClick={()=>{history.push(`/legal-document/${id}/document`)}}
                    >
                      Document
                    </button>
                  </li>
                </ul>

                {loading ?
                  <div className="card-body">
                    <div className={"loading-icon"}>
                      <center>
                        <img src="/assets/img/loading-icon.gif" alt="img"  />
                      </center>
                    </div>
                  </div>
                  :
                  <div className="card-body">

                    {/*<div className={'row'}>*/}
                    {/*  <div className={'col-md-12 text-right mt-2'}>*/}
                    {/*    <button type="button" onClick={()=>{*/}
                    {/*      saveQuestions()*/}
                    {/*    }} className="btn submit-btn1">*/}
                    {/*      Update Questionnaire*/}
                    {/*    </button>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    {/*<h5 className="card-title">*/}
                    {/*  {(documentData)?documentData.name:''}*/}
                    {/*</h5>*/}



                    {questions.length === 0 ? (
                      <div className="nodata-wrap"></div>
                    ) : (
                      <div className="added-question-wrap mb-32" style={{ position: 'relative' }}>
                        <DragDropContext
                          onDragEnd={handleDragEnd}
                          onDragStart={handleDragStart}
                          onDragUpdate={handleDragUpdate}
                        >
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {questions.map((question, index) => (
                                  <div key={index}
                                       className="survey-added-question-repeat"
                                  >
                                    <Draggable
                                      draggableId={"draggable-" + index}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={
                                            snapshot.isDragging
                                              ? "question-answers-dragging"
                                              : ""
                                          }
                                        >
                                          {editQuestionId === index ? (
                                              <></>
                                            )
                                            :
                                            <div
                                              className={`question-answer-holder`}>
                                              <div {...provided.dragHandleProps}>
                                                <Question
                                                  key={index}
                                                  index={index}
                                                  showInputFieldInQuestion={showInputFieldInQuestion}
                                                  questionNo={questions.filter((arr,questionLength) => {
                                                    if (arr.questionType !== 'pagebreak' && questionLength<=index) {return true;}
                                                    return false;
                                                  }).length}
                                                  question={question}
                                                  isAction={true}
                                                  onCopy={()=>duplicatequestion(index)}
                                                  onDelete={() => removequestion(index)}
                                                  onEdit={() => edittingQuestionsHandler(index)}
                                                  documentQuestion={[]}
                                                />
                                                {question.is_add_other && (
                                                  <div className="question-optionbox my-2"><input placeholder="Enter Free Text" disabled/></div>
                                                )}
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      )}
                                    </Draggable>

                                    <div className="question-edit">
                                      {edittingQuestions &&
                                      editQuestionId === index && (
                                        <EditQuestionInput
                                          key={index}
                                          questionIdArray={questionIdArray}
                                          questionDetails={
                                            questions[index]
                                          }
                                          cancelEditQuestion={
                                            cancelEditQuestionHandler
                                          }
                                          editQuestions={(question) => {
                                            //console.log(question)
                                            // setCall(true);
                                            // questions.splice(
                                            //   index,
                                            //   1,
                                            //   question
                                            // );
                                            // setQuestions([
                                            //   ...questions,
                                            // ]);
                                            updateQuestion(index,question);
                                          }}
                                          questionno={index + 1}
                                          questionNoIndex={questions.filter((arr,questionLength) => {
                                            if (arr.questionType !== 'pagebreak' && questionLength<=index) {return true;}
                                            return false;
                                          }).length}
                                          questionlist={questions}
                                          //changeQuestionType={(questionType) => { briefingQuestions[index].questionType = questionType }}
                                          // addLikertSubquestions={() => { briefingQuestions[index].subQuestions = [""] }}
                                          onDelete={()=>removequestion(index) }
                                          textDocumentOptionQuestion={textDocumentOptionQuestion}
                                        />
                                      )}
                                    </div>
                                    {provided.placeholder}
                                    {!isEmpty(placeholderProps) && (
                                      <div
                                        className="placeholder"
                                        style={{
                                          top: placeholderProps.clientY,
                                          left: placeholderProps.clientX,
                                          height: placeholderProps.clientHeight,
                                          width: placeholderProps.clientWidth,
                                          position: "absolute",
                                          borderColor: "#000000",
                                        }}
                                      />
                                    )}
                                  </div>
                                ))
                                }
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    )}

                    {/* Multi Columns Form */}
                    {questionType === "" ? (
                      <div className="add-more-question-option">
                        {questions.length>0 ?
                          <div className="add-more-question-heading">
                            <p>Add more question(s)</p>
                          </div>
                          :
                          <div className="add-more-question-heading">
                            <p>Add question(s)</p>
                          </div>
                        }
                        <div className="stud-tiles-hold mt-10">
                          <QuestionCard questiontype="Free Text" onClick={() => { setQuestionType("free") }} />
                          <QuestionCard questiontype="Single choice" onClick={() => { setQuestionType("singlechoice"); }} />
                          <QuestionCard questiontype="Multiple-choice" onClick={() => { setQuestionType("multiplechoice"); }} />
                          {/*<QuestionCard questiontype="Page break" onClick={() => {*/}
                          {/*  // setCall(true);*/}
                          {/*  //setQuestions([...questions, {'is_optional':false,'question':'','questionType':'pagebreak','_id':""}]);*/}
                          {/*  addNewQuestion({'is_optional':false,'question':'','questionType':'pagebreak','_id':""});*/}
                          {/*}} />*/}
                        </div>

                      </div>
                    ) : (
                      <div className={`question`}>
                        <QuestionInput
                        parentFunction={parentFunction}
                          addQuestions={(question) => {
                            //console.log(question)
                            // setQuestions([...questions, question]);
                            // setQuestionType("");
                            // setCall(true);
                            // console.log(question)
                            addNewQuestion(question);
                          }}
                          cancelQuestion={cancelQuestionHandler}
                          questionType={questionType}
                          questionlist={questions}
                          questionno={questions.filter(arr => {
                            if (arr.questionType !== 'pagebreak') {return true;}
                            return false;
                          }).length+1}
                          //questionno={questions.length + 1}
                        />

                      </div>
                    )}

                  </div>
                }
              </div>


            </div>
          </div>
        </section>
      </main>

      <Footer />


    </>
  );
};

export default ViewLegalDocumentQuestionnaire;
