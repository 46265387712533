import React, { lazy } from "react";
import { useState, useEffect } from "react"
import { Link , useHistory} from "react-router-dom";


import Config from '../../config';

 const Header= lazy(() => import("../widgets/Header.js"));
 const ViewButton = lazy(() => import("../widgets/ViewButton.js"));
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))

 const OrderAndCuns = () => {
 const history = useHistory();
  
  useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/main.js';   //(This is external js url)
        document.body.appendChild(script);
      

        }, [])
  
  return (
    <><Header /><>
  <main id="main" className="main">
  
    {/* End Page Title */}
    <section className="section profile mt-20">       
      <div className="row" id="order_history">
        <div className="col-lg-12">
          <h5 className="card-title">Order History - Coming Soon </h5>
      
          
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                {/* Progress Bars with Striped Backgrounds*/}
                <div className="progress mt-3">
                  <div
                    className="progress-bar progress-bar-striped bg-info"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                {/* End Progress Bars with Striped Backgrounds */}
              </div>
            </div>
          </div>
          {/* End Left side columns */}
        </div>
      </div>
      <div className="row d-none" id="Consultation">
        <div className="col-lg-12">
          <h5 className="card-title">Consultation - Coming Soon </h5>
          <ViewButton />
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                {/* Progress Bars with Striped Backgrounds*/}
                <div className="progress mt-3">
                  <div
                    className="progress-bar progress-bar-striped bg-info"
                    role="progressbar"
                    
                    style={{ width: "50%" }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                {/* End Progress Bars with Striped Backgrounds */}
              </div>
            </div>
          </div>
          {/* End Left side columns */}
        </div>
      </div>
    </section>
  </main>
  {/* End #main */}
</>


      <Asidebar />
      <Footer /> 
      <Jsfile />
     
  
    </>
  );
};

export default OrderAndCuns;
