import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import Select from "react-select";
import { EditorState,convertToRaw,convertFromRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Options(props) {
  const [optionError, setOptionError] = useState("");
  const [optionErrorIndex, setOptionErrorIndex] = useState(null);
  const [otherOptionIncluded, setOtherOptionIncluded] = useState(false);
  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const [placeholderProps, setPlaceholderProps] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [data, setData] = useState([]);
  //   const [jumpToList, setJumpToList] = useState(props.jumpToList?props.jumpToList:[]);
  const [logicVal, setLogicVal] = useState(null);

  const [questionsData, setQuestionsData] = useState([]);

  useEffect(()=>{
    var dropdownOptionsArray = [];
    if (props.logicAdded == true) {
      // for (let i = index + 2; i <= allQuestions.length; i++) {
      //     dropdownOptions.push({ label: i == index + 2 ? "Next question" : "Question " + i, value: i });
      // }
      //console.log(props.jumpToList)
      props.jumpToList.forEach((item, i) => (
        <>
          {item == "End Survey" ?
            dropdownOptionsArray.push({ label: "End survey", value: "End Survey" })
            :
            (item - props.questionno == 1)?
              dropdownOptionsArray.push({ label: "Next Question", value: item })
              :
              dropdownOptionsArray.push({ label: "Question "+item, value: item })
          }
        </>
      ));
      dropdownOptionsArray.push({ label: "End survey", value: "End Survey" });
      setDropdownOptions(dropdownOptionsArray);
    }
  },[props.logicAdded,props.jumpToList])





  return (
        <div style={{ position: 'relative' }}>


                  <div>
                    {props.allOptions.map((option, index) =>

                        <div
                          key={index}
                          id={"draggable-" + index}
                          index={index}
                        >
                          <label htmlFor="inputName5" className="form-label">{index == 0 ? "First option" :
                            index == 1 ? "Second option" :
                              index == 2 ? "Third option" :
                                index == 3 ? "Fourth option" :
                                  index == 4 ? "Fifth option" :
                                    index == 5 ? "Sixth option" :
                                      index == 6 ? "Seventh option" :
                                        index == 7 ? "Eighth option" :
                                          index == 8 ? "Ninth option" :
                                            index == 9 ? "Tenth option" :
                                              "Another option"}</label>
                            <div
                              className={`d-flex m-3 ans-list-repeat saved-ans-repeat`}
                              style={{width:(props.logicAdded == true && props.questionType == 'singlechoice')?'38%':''}}
                            >
                              <div className="ans-data">
                                <input
                                  type={"text"}
                                  className="form-control"
                                  placeholder="Please enter an option"
                                  onChange={(e) => {
                                    props.save(index, e.target.value);
                                  }}
                                  required={true}
                                  value={option}
                                  // onKeyDown={(e) => {
                                  //   if (e.key === 'Enter') {
                                  //     props.addOption(index);
                                  //   }
                                  // }}
                                  onBlur={(e) => {
                                    if (props.allOptions[index] == "") {
                                      // e.target.placeholder =
                                      //   "Please enter an option";
                                      // setOptionError("input_error");
                                      // setOptionErrorIndex(index);
                                    } else {
                                      props.save(index, e.target.value);
                                    }
                                  }}
                                  autoFocus={props.allOptions.length==1?false:true}

                                />
                              </div>

                              <div className="ans-close-row">
                                    <span onClick={() => props.close(index)}>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M6 18L18 6M6 6L18 18" stroke="#9C9C9C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                    </span>
                              </div>

                              {props.logicAdded == true && props.questionType == 'singlechoice' &&
                                <div className="small-dropdown add-logic-dropdown" style={{width:"200px"}}>
                                  <select id="documentType"  name="documentType"  className="pointer form-select"
                                          onChange={(item) => {
                                            //console.log(item.target.value)
                                            props.saveJumpTo(index, item.target.value)
                                            setData({logic:item.target.value})
                                          }}
                                          // value={data.logic}>
                                          value={props.allJumpTo[index] != "End Survey" ? (props.allJumpTo[index] - props.questionno == 1 ? "Next question" : props.allJumpTo[index]) : "End Survey"}>
                                          {/*value={props.allJumpTo[index] != "End Survey" ? (props.allJumpTo[index] - props.questionno == 1 ? "Next question" : "Question " + props.allJumpTo[index]) : "End survey"}>*/}
                                    {dropdownOptions.map(item=>{
                                      return (
                                        <option value={item.value}>{item.label}</option>
                                      );
                                    })}
                                  </select>
                                  {/*{console.log({'ljl ':props.allJumpTo[index] != "End Survey" ? (props.allJumpTo[index] - props.questionno == 1 ? "Next question" : "Question " + props.allJumpTo[index]) : "End survey"})}*/}
                                  {/*{console.log({'lk ':props.allJumpTo})}*/}
                                  {/*<Select*/}
                                  {/*  //isClearable={false}*/}
                                  {/*  isSearchable={false}*/}
                                  {/*  name=""*/}
                                  {/*  title=""*/}
                                  {/*  placeholder=""*/}
                                  {/*  options={dropdownOptions}*/}
                                  {/*  onChange={(item) => {*/}
                                  {/*    props.saveJumpTo(index, item.value)*/}
                                  {/*  }}*/}
                                  {/* // ref={dropDownRef}*/}
                                  {/*  value={*/}
                                  {/*    //setLogicVal*/}
                                  {/*    //logicVal?*/}
                                  {/*     // logicVal:*/}
                                  {/*      props.allJumpTo[index] != "End Survey" ? (props.allJumpTo[index] - props.questionno == 1 ? "Next question" : "Question " + props.allJumpTo[index]) : "End survey"*/}
                                  {/*  }*/}
                                  {/* /// isMulti={isMulti}*/}

                                  {/*  //components={{ DropdownIndicator }}*/}
                                  {/*/>*/}
                                </div>
                              }
                            </div>

                            <div className="create-projectmodal-form-group">
                              <label className="form-label">Text to go in the document</label>

                              <Editor
                                editorState={props.allTextDocument[index]}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                            fontFamily: {
                              options: ["Calibri", "Georgia", "Impact", "Tahoma", "Verdana","Open Sans"],
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                            },
                          }}
                                //initialContentState={contentState}
                                onEditorStateChange={(data)=>{
                                  props.saveTextDocument(index, data);
                                }}
                                handlePastedText={() => false}
                                mention={{
                                  separator: ' ',
                                  trigger: '@',
                                  suggestions: props.questionsData,
                                }}
                                editorStyle={{border: "1px solid #C0C0C0", minHeight: '250px'}}
                                hashtag={{}}
                                //defaultEditorState={editorState}
                                //defaultContentState={contentState}
                                //onContentStateChange={setContentState}
                              />
                              {/*<textarea*/}
                              {/*  rows={5}*/}
                              {/*  className="form-control"*/}
                              {/*  type={"text"}*/}
                              {/* /// onChange={setQuestion("text_document")}*/}
                              {/*  required={true}*/}
                              {/*  value={props.allTextDocument[index]}*/}
                              {/*  onChange={(e) => {*/}
                              {/*    props.saveTextDocument(index, e.target.value);*/}
                              {/*  }}*/}

                              {/*/>*/}

                              {/*{props.allTextDocument[index] === '' &&*/}
                              {/*  <span style={{color:"red"}}>Required field</span>*/}
                              {/*}*/}
                            </div>
                        </div>

                    )}

                  </div>

        </div>

  );
}
