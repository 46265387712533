import React, { lazy } from "react";
import { useState, useEffect } from "react"
import { Link , useHistory} from "react-router-dom";
import Config from '../../config';
import Asidebar from '../widgets/Asidebar.js'

const Header= lazy(() => import("../widgets/Header.js"));
//  const Asidebar = lazy(() => import('../widgets/Asidebar.js'))
const Footer = lazy(() => import('../widgets/Footer.js'))
const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
const MainPopup = lazy(() => import('../widgets/Popup.js'))
const PaginationButton  = lazy(() => import('../common/PaginationButton.js'))




const Consultation = () => {

 
  // states
  const [currentPage, setCurrentPage] = useState(1);
  const [datacount ,setDatacount] = useState([]);
  const  [pageMap ,setPage] =  useState([]);
  const  [limit ,setLimit] =  useState(10);
  const  [search ,setSearch] =  useState("");
  const  [prevSearch ,setPrevSearch] =  useState("");
  const  [formValues ,setFormValues] =  useState({sort:"",sortBy:""});
  const  [sortBy ,setSortBy] =  useState("");
  const [maxshow,setmaxShow]= useState(4);
  const [minshow,setminShow]  = useState(0);

  var lawyerId = sessionStorage.getItem("LawyerId")
  
  // alert(lawyerId)



  const [data ,setData] = useState([]);
  
  const history = useHistory();
  const customerData =  (limit,page,lawyerId) =>{
    if(lawyerId === undefined){
      // alert()
      lawyerId = ''

    }

    setCurrentPage(page)

    Config.get(`/admin/consultaion?activity=booked&limit=${limit}&lawyerId=${lawyerId}&page=${page}&search=${search}&key=${formValues.sort}&shortBy=${formValues.sortBy}`)
      .then((res) => {
        if (res.status) {

          setData(res.data)
          setpaginatoion(res.count);

        }else{
          Config.showToast("server error ");
        }
      })
      .catch((err) => {
        // Config.showToast("Please try after some time.");
        console.log("error in get consultation data")
      });

  }


  const setpaginatoion = (ct) =>{
    let page = []
    var limitValue = document.getElementById("limit").value;
    setLimit(parseInt(limitValue))
    //var pageCount = ct/limit == 0? ct/limit : ct/limit+1;
    var pageCount = ((ct/limitValue) === 0)? ct/limitValue : ct/limitValue+1;
    if(isNaN(pageCount)){
      setPage([1]);
    }else{

      for(let i=1;i<pageCount;i++){
        page.push(i)
      }
      setPage(page)
    }
  }





  const Pagination = (data) =>{
    var limitValue = document.getElementById(data).value;
    setLimit(parseInt(limitValue))
   /* let page = []
    let pageCount2 = datacount.servCt/parseInt(limitValue) == 0? datacount.servCt/parseInt(limitValue) : datacount.servCt/parseInt(limitValue)+1;
    for(let j=1;j<pageCount2;j++){
      page.push(j)
    }
    setPage(page)*/
    customerData(limitValue,1)
  }


  const move = (count) =>{
    if(count == -1){
      if(currentPage >1){
        let  newPage =    currentPage-1;
        setCurrentPage(newPage)
        customerData(limit,newPage)
        if(maxshow<5){
        }else{    
         setminShow(minshow-1)
         setmaxShow(maxshow-1)}

      

      }
    }else{
      if(currentPage <pageMap.length ){
        let  newPage  =  currentPage+1
        setCurrentPage(newPage)
        customerData(limit,newPage)
        setminShow(minshow+1)
        setmaxShow(maxshow+1)
      }
    }
  }


  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'assets/js/main.js';   //(This is external js url)
    document.body.appendChild(script);
   
   
    customerData(limit,1,lawyerId)
  }, [])

  useEffect(()=>{

    if(formValues.sort) {
      customerData(limit, currentPage)
    }

  },[formValues])

  useEffect(() => {
    console.log("search3", search, prevSearch);
    if (search === "" && prevSearch !== "") {
      customerData(limit, 1);
    }
    setPrevSearch(search);
  }, [search]);

  const sortData = ()=>{
    customerData(limit, currentPage)
  }

  const AddRemoveKEYDOC = (_id)=>{
    Config.get(`/admin/key-doc?_id=${_id}`)
    .then((res) => {
      if (res.status) {

        // setData(res.data)
        // setpaginatoion(res.count);
        // Config.showToast("👍");

        //document.getElementById(_id).textContent = res.data
          customerData(limit, currentPage)

      }else{
        Config.showToast("server error ");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });

  }



  const seachTable = () =>{

    customerData(limit, 1);

  }


  return (
    <>
      <Jsfile />
      <Header />

      <>

        <main id="main" className="main">
          <section className="section dashboard">
            <div className="row">
              {/* Left side columns */}
              <div className="col-lg-12">
                <div className="row">

                  {/* Recent Sales */}
                  <div className="col-12">
                    <div className="card recent-sales overflow-auto">
                      <div className="card-body" id="plan">
                      <ul className="nav nav-tabs nav-tabs-bordered">
                        <li className="nav-item active">
                        <button
                          className="nav-link active"
                          onClick={()=>{history.push(`/consultations`)}}
                        >
                          Upcoming Consultation
                        </button>
                        </li>
                        <li className="nav-item">
                        <button
                          className="nav-link "
                          onClick={()=>{history.push(`/complete-consultations`)}}
                        >
                          Complete Consultation
                        </button>
                        </li>
                     </ul>

                 {/* <h5 className="card-title">Consultation</h5> */}
                        <div className="dataTable-top">
                          <div id="upperPageDiv">
                            <PaginationButton probs={Pagination}/>
                          </div>
                          <div className="dataTable-search d-flex">
                            <div className="search-bar">
                              <div
                                className="search-form d-flex align-items-center"
                                method="POST"

                              >
                                <input
                                  type="text"
                                  id="myInput"
                                  name="query"
                                  value={search}
                                  onChange={(e)=>{
                                    setSearch(e.target.value);
                                  }}
                                  placeholder="Search"
                                  title="Enter search keyword"
                                  className="search_input"
                                  // onKeyUp={()=>{seachTable()}}

                                />
                                <span onClick={() => { seachTable() }} className="search_data_btn" title="Search">
                              <i className="bi bi-search" />
                            </span>

                              </div>
                            </div>
                            <button
                              type="submit"
                              className="clear_data_btn"
                              title="Clear"
                              onClick={()=>{
                                setSearch("");
                              }}
                            >
                              Clear
                            </button>{" "}

                            {/* <a className="add_lawyers_btn" >
                              <Link to="/add-legal-document" style={{color: 'white'}}> Add legal document </Link>
                            </a> */}
                          </div>
                        </div>

                        <table id="myTable" className="table datatable">
                          <thead>
                          <tr>
                            <th scope="col" onClick={()=>{

                                if(formValues.sortBy === 'ATOZ'){
                                  setFormValues({"sort":"OrderId","sortBy":"ZTOA"});
                                }else{
                                  setFormValues({"sort":"OrderId","sortBy":"ATOZ"});
                                }

                            }}>
                              <a className="pointer dataTable-sorter">
                              OrderId
                              </a>
                            </th>


                            <th className="shorting">
                            Customer Name
                            </th>
                            <th className="shorting">
                            Appointment Date 
                            </th>
                            <th className="shorting">
                            Appointment Time
                            </th>

                            <th className="shorting">
                            Lawyer Name
                            </th>
                   

                            <th className="shorting">Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          {data.map((item,i)=> (  <tr>
                            <td>
                              <div>
                              <span className="pointer" onClick={()=>{history.push("/booking-detail/"+item._id+"/info")}}>
                                {item.orderId==''?"FREECONSULTATION-00":item.orderId}
                              </span>
                            </div>
                            </td>

                            <td><span >{item.userId.name}</span></td>
                            <td><span >{item.selectedDay+'-'+item.selectedMonth+'-'+item.selectedYear}</span></td>
                            <td><span >{item.bookingTime}</span></td>
                            
                            <td><span >{item.lawyerId==null? '' :item.lawyerId.name+' '+item.lawyerId.lastName }</span></td>
                            
                            {/* <td><span id={item._id} className="pointer view_btn"
                             onClick={()=>{AddRemoveKEYDOC(item._id)}} > {(item.keyDoc==0)? "Add" : "Remove"}
                               </span></td> */}

                            <td>
                               <span className="pointer view_btn"
                                onClick={()=>{history.push("/booking-detail/"+item._id+"/info")}}
                               >
                                View
                              </span>
                              
                            </td>
                            
                          </tr> ))}

                          </tbody>
                        </table>

                        {data.length === 0 &&
                          <div id="noRsFound" style={{display:"none",  marginLeft:"416px"}}>  No Result  </div>
                         }

                        <div  id="lowerPageDiv" class="dataTable-bottom"><div class="dataTable-info"></div>

                            <nav className="dataTable-pagination">
                              <ul className="dataTable-pagination-list ">
                                  <li>
                                    showing {currentPage} page from {pageMap.length} 
                                  </li>
                              </ul>
                            </nav>

                          <nav class="dataTable-pagination">
                            <ul class="dataTable-pagination-list">

                              <li className={currentPage === 1 ? "pager disabled" : "pager"} ><a onClick={()=>{move(-1)}} >‹</a></li>
                              {pageMap.map((data,i)=> (
                                (i <maxshow && i>=minshow)?<li className={currentPage ==  data? "active": "" } ><a onClick={()=>{customerData(limit,data)}}  data-page={data}>{data}</a></li>:''


                              ))}
                              <li className={currentPage === pageMap.length ? "pager disabled" : "pager"} ><a onClick={()=>{move(+1)}} >›</a></li></ul></nav>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Recent Sales */}
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
      </>


      <Asidebar />
      <Footer />

    </>
  );
};

export default Consultation;
