import React, { lazy } from "react";
import { useState, useEffect } from "react"

/*import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";*/

//import MainChartExample from "../charts/MainChartExample.js";

const Header= lazy(() => import("../widgets/Header.js"));
//  const MainDiv = lazy(() => import('../widgets/Maindiv.js'))
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
const Jsfile = lazy(() => import('../widgets/Jsfile.js'))

const Uplocoing = () => {

  useEffect(() => {
      const script = document.createElement('script');
      script.src = 'assets/js/main.js';   //(This is external js url)
     
      document.body.appendChild(script);
    }, [])

  return (
    <>
    

      <Header />
      <main id="main" className="main">
  <section className="section dashboard">
    <div className="row">
      {/* Left side columns */}
      <div className="col-lg-6">
      <h5 className="card-title">Coming Soon </h5>
            <div className="card">
              <div className="card-body">
                {/* Progress Bars with Striped Backgrounds*/}
                <div className="progress mt-3">
                  <div
                    className="progress-bar progress-bar-striped bg-info"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                {/* End Progress Bars with Striped Backgrounds */}
              </div>
            </div>
          </div>

      {/* End Left side columns */}
    </div>
    
  </section>
</main>
      

      <Asidebar />
      <Footer /> 
      <Jsfile />
     
      {/* 

        <WidgetsBrand />

      <CCard>
        <CCardBody>
          <CRow>
            <CCol sm="5">
              <h4 id="traffic" className="card-title mb-0">
                Monthly Orders
              </h4>
              <div className="small text-muted">Feb 2021</div>
            </CCol>
            <CCol sm="7" className="d-none d-md-block">
              <CButton color="primary" className="float-right">
                <CIcon name="cil-cloud-download" />
              </CButton>
              <CButtonGroup className="float-right mr-3">
                {["Day", "Month", "Year"].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === "Month"}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol>
          </CRow>
          <MainChartExample style={{ height: "300px", marginTop: "40px" }} />
        </CCardBody>
        <CCardFooter></CCardFooter>
      </CCard> */}
    </>
  );
};

export default Uplocoing;
