import React, { lazy } from "react";
import { useState, useEffect } from "react"
import { Link , useHistory} from "react-router-dom"; 
import Config from '../../config';
import Asidebar from '../widgets/Asidebar.js'

 const Header= lazy(() => import("../widgets/Header.js"));
//  const Asidebar = lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
 
 const MainPopup = lazy(() => import('../widgets/Popup.js'))
 const PaginationButton  = lazy(() => import('../common/PaginationButton.js'))




const LegalServices = () => {

  // states 
  const [currentPage, setCurrentPage] = useState(1);  
  const [datacount ,setDatacount] = useState([]);
  const  [pageMap ,setPage] =  useState([]);
  const  [limit ,setLimit] =  useState(10);



   
  const [data ,setData] = useState([]);
  const history = useHistory();
  const customerData =  (limit,page) =>{
    setCurrentPage(page)
   
    Config.get(`/admin/legal-services?limit=${limit}&page=${page}`)
    .then((res) => {
     console.log(res);
      if (res.status) {
       
        console.log(res.data)
        setData(res.data)

      }else{
        Config.showToast("server error ");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });

  }


    const setpaginatoion = (ct) =>{
      let page = []
      var pageCount = ct/10 == 0? ct/10 : ct/10+1; 
      for(let i=1;i<pageCount;i++){
       page.push(i)
       }
       setPage(page)

      }


      const dataCount =  () =>{
        Config.get(`/admin/dashboars-count`)
        .then((res) => {
         console.log(res);
          if (res.status) {
           setDatacount(res.ct)
           setpaginatoion(res.ct.servCt)
          
          
          }else{
            Config.showToast("opps ! somthing going wrong ..  ");
          }
        })
        .catch((err) => {
          Config.showToast("opps ! somthing going wrong ..  ");
        });
    
      } 

  
  



  const Pagination = (data) =>{
    var limitValue = document.getElementById(data).value; 
    setLimit(parseInt(limitValue))
    let page = []
     let pageCount2 = datacount.servCt/parseInt(limitValue) == 0? datacount.servCt/parseInt(limitValue) : datacount.servCt/parseInt(limitValue)+1; 
    for(let j=1;j<pageCount2;j++){
     page.push(j)
     }
    setPage(page)
    customerData(limitValue,1)
    }
  

    const move = (count) =>{
      if(count == -1){
      if(currentPage >1){
       let  newPage =    currentPage-1;
       setCurrentPage(newPage)
       customerData(limit,newPage)

       }
      }else{
        if(currentPage <pageMap.length ){
        let  newPage  =  currentPage+1
        setCurrentPage(newPage)
        customerData(limit,newPage)
        }
      }
    }


    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'assets/js/main.js';   //(This is external js url)   
      document.body.appendChild(script);
      customerData(10,1)
      dataCount()
    }, [])
  
   
  const editSevice = (id)=>{
  localStorage.setItem("servicesId",id);
  history.push('/editservices');
  }


  const clear= ()=>{
      document.getElementById("lowerPageDiv").style.display = "block"
      document.getElementById("upperPageDiv").style.display = "block"
      document.getElementById("noRsFound").style.display = "none"
      document.getElementById("myInput").value = '';
      customerData(5,1)
   }
      


   const seachTable = () =>{
    var  search = document.getElementById("myInput").value

    Config.get(`/admin/search-services?search=${search}`)
    .then((res) => {
     console.log(res);
      if (res.status) {
      
        setData(res.data)
        document.getElementById("lowerPageDiv").style.display = "none"
        document.getElementById("upperPageDiv").style.display = "none"
        if(res.data.length == 0){
          document.getElementById("noRsFound").style.display = "block"
        }else{
          document.getElementById("noRsFound").style.display = "none"
        }
      
      }else{
        Config.showToast("server error!!! ");
       }
       })
    .catch((err) => {
     Config.showToast("Please try after some time.");
     });
  
    
     
    // var input, filter, table, tr, td, i, txtValue;
    //   input = document.getElementById("myInput");
    //   filter = input.value.toUpperCase();
    //   table = document.getElementById("myTable");
    //   tr = table.getElementsByTagName("tr");
 
    //   for (i = 0; i < tr.length; i++) {
    //     td = tr[i].getElementsByTagName("td")[0];
    //     if (td) {
    //       txtValue = td.textContent || td.innerText;
    //       if (txtValue.toUpperCase().indexOf(filter) > -1) {
    //         tr[i].style.display = "";
    //         document.getElementById("noRsFound").style.display = "none"
    //       } else {
    //        if(i == 1){
    //         document.getElementById("noRsFound").style.display = "block"
    //        }
    //         tr[i].style.display = "none";
    //       }
    //     }       
    //   }
     
    }

   const [index ,setIndex] = useState(0);
  //  var i = 0;

  // const  updateIndex = (index)=>{
  //   i = index
  //   alert(i)
  //   }
 


  return (
    <>
      <Jsfile />
      <Header />
      
      <>
  <main id="main" className="main">
 
  {data.length>0 == true? 
  <MainPopup lawyer={data[index].lawyers} /> :  <MainPopup lawyer={[ 
{position: '', phone: '', _id: '', name: '', email: ''}]} />}
  
 
    

    <section className="section dashboard">
      <div className="row">
        {/* Left side columns */}
        <div className="col-lg-12">
          <div className="row">
            {/* Recent Sales */}
            <div className="col-12">
              <div className="card recent-sales overflow-auto">
                <div className="card-body" id="plan">
                  <h5 className="card-title">Legal Services</h5>

                  <div className="dataTable-top">
                  <div id="upperPageDiv">
                  <PaginationButton probs={Pagination}/>
                  </div>
                    <div className="dataTable-search d-flex">
                      <div className="search-bar">
                        <div
                          className="search-form d-flex align-items-center"
                          method="POST"
                          
                        >
                          <input
                            type="text"
                            id="myInput"
                            name="query"
                            placeholder="Search"
                            title="Enter search keyword"
                            className="search_input"
                            // onKeyUp={()=>{seachTable()}}
                            
                          />
                          <span onClick={() => { seachTable() }} className="search_data_btn" title="Search">
                              <i className="bi bi-search" />
                            </span>

                        </div>
                      </div>
                      <button
                        type="submit"
                        className="clear_data_btn"
                        title="Clear"
                        onClick={()=>{clear()}}
                      >
                        Clear
                      </button>{" "}

                       <a className="add_lawyers_btn">
                       <Link to="/addservices" style={{color: 'white'}}> Add Service </Link>
                       </a>
                    </div>
                  </div>

                  <table id="myTable" className="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                     
                        
                        <th className="shorting">
                        Experts
                        </th>
                        <th className="shorting">
                        Documents
                        </th>
                        <th className="shorting">
                        Blogs
                        </th>
                      
                        <th className="shorting">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                     {data.map((pln,i)=> (  <tr>
                        <td><div><span onClick={()=>{editSevice(pln._id)}} className="pointer">{pln.name} </span></div></td>
                      
                        <td><span data-bs-toggle="modal"  onClick={()=>{setIndex(i);}} data-bs-target="#verticalycenteredNew" className="pointer view_btn delete_swap">View</span></td>
                        <td><span >View</span></td>
                        <td><span >View</span></td>
                    
                        <td><span onClick={()=>{editSevice(pln._id)}} className="pointer view_btn">
                           Edit
                          </span>
                       
                         
                        </td>
                      </tr> ))}
                     
                    </tbody>
                  </table>
                  <div id="noRsFound" style={{display:"none",  marginLeft:"416px"}}>   No Result  </div> 
                  <div  id="lowerPageDiv" class="dataTable-bottom"><div class="dataTable-info"></div>
                      <nav class="dataTable-pagination">
                      <ul class="dataTable-pagination-list">
                      
                      <li className={currentPage === 1 ? "pager disabled" : "pager"} ><a onClick={()=>{move(-1)}} >‹</a></li>
                       {pageMap.map(data=> (
                      <li className={currentPage ==  data? "active": "" } ><a onClick={()=>{customerData(limit,data)}}  data-page={data}>{data}</a></li>
                  
                 
                      ))}
                      <li className={currentPage === pageMap.length ? "pager disabled" : "pager"} ><a onClick={()=>{move(+1)}} >›</a></li></ul></nav>
                    </div>
                </div>
              </div>
            </div>
            {/* End Recent Sales */}
          </div>
        </div>
      </div>
    </section>
  </main>
  {/* End #main */}
</>


      <Asidebar />
      <Footer /> 
    
     
      {/* 

        <WidgetsBrand />

      <CCard>
        <CCardBody>
          <CRow>
            <CCol sm="5">
              <h4 id="traffic" className="card-title mb-0">
                Monthly Orders
              </h4>
              <div className="small text-muted">Feb 2021</div>
            </CCol>
            <CCol sm="7" className="d-none d-md-block">
              <CButton color="primary" className="float-right">
                <CIcon name="cil-cloud-download" />
              </CButton>
              <CButtonGroup className="float-right mr-3">
                {["Day", "Month", "Year"].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === "Month"}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol>
          </CRow>
          <MainChartExample style={{ height: "300px", marginTop: "40px" }} />
        </CCardBody>
        <CCardFooter></CCardFooter>
      </CCard> */}
    </>
  );
};

export default LegalServices;
