import React , { lazy } from 'react'
import { useState, useEffect , useRef} from "react"
import Config from '../../../config';
// import JoditEditor from 'jodit-react';



import { useHistory } from "react-router-dom";
// const Popup = lazy(() => import('./DeletePop.js'))
 const Header= lazy(() => import("../../widgets/Header.js"));
 const Asidebar =  lazy(() => import('../../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../../widgets/Jsfile.js'))
 const MediaUpload = lazy(() => import('./VideoUploader'))
 const Popup = lazy(() => import('../../widgets/DeletePop.js'))



const AddBlogVideo = (props) => {
const id = localStorage.getItem("blogVideoId")
 
 const [article, setArticle] = useState("");
 const editor = useRef(null);
 const [data ,setData] = useState([]);


 const blogData =  () =>{
   
    Config.get(`/admin/article-video-id?_id=${id}`)
    .then((res) => {
     console.log(res);
        if (res.status) {
            document.getElementById("ifram").src = res.data.bannerVideo==''?"assets/img/video.gif": res.data.media
            document.getElementById("bannerVideourl").value = res.data.media

        setData(res.data)

      }else{
        Config.showToast("Please try after some time.");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });

  }


  // drop down option multiselece 
  const [options ,setDropDown] = useState([]);
  const  categoriesDropDown =  () =>{
         
    Config.get(`/admin/lawyer-categories`)
    .then((res) => {
     console.log(res);
        if (res.status) {
        setDropDown(res.data)
          // alert()
    
      }else{
        Config.showToast("Please try after some time.");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });
    
    }


    // lawyer list 
    const [blogs,setblogs]= useState([])
    const lawyerData =  () =>{
   
      Config.get('/admin/article?dropDown=1')
      .then((res) => {
       console.log(res);
        if (res.status) {
         
            setblogs(res.data)
        
        }else{
          Config.showToast("server error!!! ");
        }
      })
      .catch((err) => {
        Config.showToast("Please try after some time.");
      });
  
    }

   const setIdinLocal = (id)=>{
    localStorage.setItem("deleteId",id);
    localStorage.setItem("deleteType","blogVideo");
   
    }
   


    const back = () =>{
      history.push("/blog-video");
    }

  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/js/main.js';   //(This is external js url)   
    document.body.appendChild(script);
    lawyerData()
    categoriesDropDown()
  
    if(props.type== "Edit"){
      blogData()
     document.getElementById("deleteButton").style.display = "block"
    }
    }, [])

  const history = useHistory();

  // add edit data
    async function AddBlog(e) {
          e.preventDefault();
         
          
           var title = e.target.title.value,
             desc = e.target.desc.value,
             blogId = e.target.blogId.value,
             media = e.target.bannerVideourl.value
         

        if(props.type == "Add"){
         var data = {title,desc,blogId,media};
            Config.post('/admin/article-video',data)
              .then((res) => {
              console.log(res);
                if (res.status) {
                  history.push("/blog-video");
                  }else{
                  Config.showToast("somthing wrong");
                  }
                })
              .catch((err) => {
                Config.showToast("Please try after some time.");
              });

            }else {
              
          // call edit api 
          var data = {_id:id,title,desc,blogId,media};

                Config.put('/admin/article-video',data)
                .then((res) => {
                  console.log(res);
                  if (res.status) {
                     
                    history.push("/blog-video");
                    }else{
                    Config.showToast("somthing wrong ");
                    }
                  })
                .catch((err) => {
                  Config.showToast("Please try after some time.");
                });



         }

      }
  


  return (
    <>
      <Jsfile />
      <Header />
 <main id="main" className="main">
  <Popup />

  <section className="section">
  
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{props.type} Article </h5>
            {/* Multi Columns Form */}

          

            <form className="row g-3" method='post' onSubmit={AddBlog}>
              {/* <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                 Name  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="name" name="name" onChange={(e) => setData(e.target.value)} value={data.name}  required/>
              </div> */}
              <div className="col-md-6">
                <label htmlFor="inputName5" className="form-label">
                 Title  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="title" name="title" onChange={(e) => setData(e.target.value)} value={data.title} required/>
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName5" className="form-label">
                 Description  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="desc" name="desc" onChange={(e) => setData(e.target.value)} value={data.desc} required/>
              </div>

              <div className="col-md-6">
                <label htmlFor="inputName5" className="form-label">
                 Blog <span style={{color:"red"}} >* </span>
                </label>
                <select  className="pointer form-control form-select" id="blogId" name="blogId" onChange={(e) => setData(e.target.value)} value={data.lawyerId}    >
                 <option value={"6363cbe356c8027a7e43f7e0"}>Upload Without Blog</option>

                 {blogs.map(law => (
                  <option value={law._id}>{law.title} </option>

                 ))}
                </select>
               </div>
                 {/* categories dropdown */}
           

               <MediaUpload />

                 <div className="text-center">
                  
                  <button type="submit" className="btn submit-btn1">
                  {props.type== "Edit" ? "Update": "Submit" }
                  </button>
 
                  <span onClick={()=>{back()}} className="btn submit-btn2">
                   Go Back</span>
             

                  <span className="btn  submit-btn4" style={{float:"right", display:"none"}} data-bs-toggle="modal" 
                   id ="deleteButton"
                   onClick={()=>{setIdinLocal(data._id)}} data-bs-target="#verticalycentered">Delete</span>
              
              </div>
            </form>
            {/* End Multi Columns Form */}
            {/* <div>{article}</div> */}
          </div>
        </div>
      </div>
    </div>
  </section>

</main>
 
      <Asidebar />
      <Footer /> 
    
</>

 )
}

export default AddBlogVideo
