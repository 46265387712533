import React, { useState, useEffect, useRef } from "react";
import Config from "../../../config";
import Options from "./Options";
import { Dropdown } from "react-bootstrap";

import { EditorState,convertToRaw,convertFromRaw,ContentState } from 'draft-js';

export const EditQuestionInput = (props) =>{
  const [questionEditFormValues, setQuestionEditFormValues] = useState({
    question: props.questionDetails.question,
    text_help:props.questionDetails.text_help,
    text_document:props.questionDetails.text_document,
    is_optional: props.questionDetails.is_optional,
    is_logic: props.questionDetails.is_logic,
    logic_option:(props.questionDetails.logic_option)?props.questionDetails.logic_option:"",
  });

  const [questionEditFormErrors, setQuestionEditFormErrors] = useState({
    question: null,
    text_help:null,
    text_document:null,
    error_class: null,
  });

  const [allOptions, setAllOptions] = useState(props.questionDetails.questionType === "free" ? [] : [...props.questionDetails.options]);
  const [tempQuestionType, setTempQuestionType] = useState(props.questionDetails.questionType);
  const [tempAllOptions, setTempAllOptions] = useState([""]);
  const [optionsIdList, setOptionsIdList] = useState([...props.questionDetails.optionsId]);
  const [allTextDocument, setAllTextDocument] = useState([...props.questionDetails.textDocumentOption]);
  const [allJumpTo, setAllJumpTo] = useState([]);
  const [likertSubQuestions, setLikertSubQuestions] = useState(props.questionDetails.questionType === "likertscale" ? [...props.questionDetails.subQuestions] : ['']);
  const [unsavedOptions, setUnsavedOptions] = useState([]);

  const [optionError, setOptionError] = useState("");
  const [optionErrorIndex, setOptionErrorIndex] = useState(null);
  const [otherOptionIncluded, setOtherOptionIncluded] = useState(props.other);
  const [ratingScale, setRatingScale] = useState("5");
  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const [placeholderProps, setPlaceholderProps] = useState({});
  const [logicAdded, setLogicAdded] = useState(props.questionDetails.is_logic);
  const [jumpToListItems, setJumpToListItems] = useState([]);
  const [likertScaleLength, setLikertScaleLength] = useState((props.questionDetails.scale_length == '7 points') ? "7 points" : "5 points");
  const [likertScaleType, setLikertScaleType] = useState((props.questionDetails.scale_type) ? props.questionDetails.scale_type : "Satisfaction");

  const [dropdownOptions, setDropdownOptions] = useState([]);

  const wrapperRef = useRef(null);

  //   const questionTypes=["free","singlechoice", "multiplechoice","ratingscale","likertscale","rankingscale"];
  //const questionTypes = [{ label: "Free text", value: "free" }, { label: "Single choice", value: "singlechoice" }, { label: "Multiple choice", value: "multiplechoice" }, { label: "Rating scale", value: "ratingscale" }, { label: "Likert scale", value: "likertscale" }, { label: "Ranking scale", value: "rankingscale" }];
  const questionTypes = props.islogic ? [{ label: "Free text", value: "free" }, { label: "Single choice", value: "singlechoice" }, { label: "Multiple choice", value: "multiplechoice" }, { label: "Likert scale", value: "likertscale" }]
    : [{ label: "Free text", value: "free" }, { label: "Single choice", value: "singlechoice" }, { label: "Multiple choice", value: "multiplechoice" }, { label: "Likert scale", value: "likertscale" }];

  const [questionsData, setQuestionsData] = useState([]);

  const showError = (error) => {
    Config.showToast(error);
  };


  const setQuestion = (name) => {
    return ({ target: { value } }) => {
      setQuestionEditFormValues((oldValues) => ({ ...oldValues, [name]: value }));

      if(name === 'text_help'){
        if (value.length > 0) {
          setQuestionEditFormErrors({...questionEditFormErrors, text_help: null, error_class: null });
        }
      }else if(name === 'text_document'){
        if (value.length > 0) {
          setQuestionEditFormErrors({...questionEditFormErrors, text_document: null, error_class: null });
        }
      }else {
        if (value.length > 0) {
          setQuestionEditFormErrors({question: null, error_class: null});
        }
      }
      // if (value.length > 0) {
      //   setQuestionEditFormErrors({ question: null, error_class: null });
      // }
    };
  };

  const saveOptionHandler = (index, value) => {
    allOptions.splice(index, 1, value);
    setAllOptions([...allOptions]);
  };

  const saveOptionJumpToHandler = (index, value) => {
    allJumpTo.splice(index, 1, value);
    setAllJumpTo([...allJumpTo]);
  };
  const closeOtherOptionHandler = () => {
    const list = [...allOptions];
    list.pop();
    setAllOptions(list);
    const listjumto = [...allJumpTo];
    listjumto.pop();
    setAllJumpTo(listjumto);
    setOtherOptionIncluded(false);
  };


  const closeOptionHandler = (index) => {

    //console.log(props.questionIdArray)
    // if(optionsIdList.length > 0 && optionsIdList[index]){
    //   // check deleted question is assigned on legal agreement or not
    //   const checkAssignedQuestion = props.questionIdArray.find(c => c === optionsIdList[index]);
    //   if(checkAssignedQuestion){
    //     Config.showToast("This option is already assigned in legal agreement docx. Please remove question from legal agreement first. ");
    //     return  false;
    //   }
    // }

    // console.log(props.textDocumentOptionQuestion);
    // var isQuestionExits = false;
    // props.textDocumentOptionQuestion.forEach((items)=>{
    //   items.OptionQuestionId.forEach((item)=>{
    //
    //     if(optionsIdList[index] == item){
    //
    //       isQuestionExits = true;
    //
    //     }
    //   })
    // })
    //
    // if(isQuestionExits){
    //   Config.showToast("This option is already assigned in another Questionnaire option. Please remove question from Questionnaire option first. ");
    //   return  false;
    // }

    const list = [...allOptions];
    list.splice(index, 1);
    setAllOptions(list);
    const listjumto = [...allJumpTo];
    listjumto.splice(index, 1);
    setAllJumpTo(listjumto);

    // set text document blank
    const document = [...allTextDocument];
    document.splice(index, 1);
    setAllTextDocument(document);

    // set text document blank
    const documentId = [...optionsIdList];
//console.log(optionsIdList)

    if(optionsIdList.length > 0 && optionsIdList[index]){
      Config.delete('/admin/delete-questionnaire?_id='+optionsIdList[index]+'&type=option')
        .then((res) => {
          //console.log(res);
          if (res.status) {
            //Config.showToast("Deleted successfully . !!! ");
            // history.push("/legaldoc")
          }else{
            //Config.showToast("server error!!! ");
          }
        })
        .catch((err) => {
          Config.showToast("Please try after some time.");
        });
    }
    documentId.splice(index, 1);
    setOptionsIdList(documentId);
  };

  const saveAllTextDocument = (index, value) => {
    allTextDocument.splice(index, 1, value);
    setAllTextDocument([...allTextDocument]);
  };
  const editOptionHandler = (index) => {
    const list = [...allOptions];
    const optionToBeEdited = list[index];
    list.splice(index, 1);
    setAllOptions(list);

    const listUnsavedOptions = [...unsavedOptions];
    listUnsavedOptions.splice(0, 0, optionToBeEdited);
    setUnsavedOptions(listUnsavedOptions);
  };

  const addSubQuestionHandler = () => {
    likertSubQuestions.push("");
    setLikertSubQuestions([...likertSubQuestions]);
  };

  const removeSubQuestionHandler = (index) => {
    if (likertSubQuestions.length == 1) {
      showError(
        "Minimum one option is required."
      );
    } else {
      const list = [...likertSubQuestions];
      list.splice(index, 1);
      setLikertSubQuestions(list);
    }
  };

  useEffect(() => { }, [unsavedOptions, allOptions]);
  useEffect(() => {
    var list = [];
    // for (var i = props.questionno + 1; i <= props.questionlist.length; i++) {
    //   list.push(i);
    // }
    // setJumpToListItems(list);

    for (var i = props.questionNoIndex+1; i <= props.questionlist.filter((arr,questionLength) => {
      if (arr.questionType !== 'pagebreak') {return true;}}).length; i++) {
      list.push(i);
    }
    setJumpToListItems(list);

    // set items to logioc options
    var dropdownOptionsArray = [];
    list.forEach((item, i) => (
      <>
        {item == "End Survey" ?
          dropdownOptionsArray.push({ label: "End survey", value: "End Survey" })
          :
          (item - props.questionno == 1)?
            dropdownOptionsArray.push({ label: "Next Question", value: item })
            :
            dropdownOptionsArray.push({ label: "Question "+item, value: item })
        }
      </>
    ));
    dropdownOptionsArray.push({ label: "End survey", value: "End Survey" });
    // console.log(jumptolist)
    // console.log(dropdownOptionsArray)
    setDropdownOptions(dropdownOptionsArray);

    if (props.questionDetails.is_logic == 1) {
      setAllJumpTo(props.questionDetails.jumpTo);

    }
    else {
      var jumptolist = [];
      for (var i = 0; i < allOptions.length; i++) {
        jumptolist.push(props.questionNoIndex < props.questionlist.filter(arr => {if (arr.questionType !== 'pagebreak') {return true;}}).length ? props.questionNoIndex + 1 : "End Survey");
        //jumptolist.push(props.questionno < props.questionlist.filter(arr => {if (arr.questionType !== 'pagebreak') {return true;}}).length ? props.questionno + 1 : "End Survey");
      }
      setAllJumpTo(jumptolist);


    }

    // set data for mention suggestion
    var questionData = [];
    var questionCount = 1;
    props.questionlist.forEach((items)=>{

      if (items.questionType !== 'pagebreak') {

        if(items.questionType === 'free') {
          if(props.questionDetails._id !== items._id) {
            questionData.push({
              text: 'Q' + questionCount + ' ' + items.question,
              value: 'Q' + questionCount + ' ' + items.question,
              url: items._id,
              type: 'free',
              questionOptionId: ''
            })
          }
          questionCount++;
        }else{

          // set suggestions
          // if(items.optionsId) {
          //   if(props.questionDetails._id !== items._id) {
          //     items.optionsId.forEach((itemsOptions, i) => {
          //       var OptionCount = i + 1;
          //       questionData.push({
          //         text: 'Q' + questionCount + ' ' + items.question + ' (Option ' + OptionCount + ')',
          //         value: 'Q' + questionCount + ' ' + items.question + ' (Option ' + OptionCount + ')',
          //         url: itemsOptions,
          //         type: items.questionType,
          //         questionOptionId: items._id
          //       })
          //     })
          //   }
          //
          //   questionCount++;
          //
          // }

          if(props.questionDetails._id !== items._id) {
            questionData.push({
              text: 'Q' + questionCount + ' ' + items.question,
              value: 'Q' + questionCount + ' ' + items.question,
              url: items._id,
              type: items.questionType,
              questionOptionId: items._id
            })
          }
          questionCount++;
        }
      }



    })
    //console.log(questionlist)
    setQuestionsData(questionData);

  }, []);

  const showQuestionFormError = (name, value) => {
    let formErrorsLocal = Object.assign({}, questionEditFormErrors);

    for (var key in questionEditFormErrors) {
      formErrorsLocal[key] = null;
    }
    formErrorsLocal[name] = value;

    formErrorsLocal["error_class"] = "input_error";

    setQuestionEditFormErrors(formErrorsLocal);

  };

  const handleQuestionEditSubmit = () => {
    setOptionErrorIndex(null);
    if (questionEditFormValues.question === "") {
      showQuestionFormError("question", "Required field");
      document.getElementById("question").scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      // error = true;
    }
    // else if (questionEditFormValues.text_help === "") {
    //   showQuestionFormError("text_help", "Required field");
    //   document.getElementById("question").scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //   });
    // }
    else {
      if (props.questionDetails.questionType === "free") {

        // if(questionEditFormValues.text_document === ""){
        //   showQuestionFormError("text_document", "Required field");
        //   document.getElementById("question").scrollIntoView({
        //     behavior: "smooth",
        //     block: "center",
        //   });
        // }else {
        props.editQuestions({
          _id: props.questionDetails._id,
          questionType: props.questionDetails.questionType,
          question: questionEditFormValues.question,
          text_help: questionEditFormValues.text_help,
          is_optional: questionEditFormValues.is_optional,
          is_logic: questionEditFormValues.is_logic,
          logic_option: (questionEditFormValues.is_logic)?(questionEditFormValues.logic_option)?questionEditFormValues.logic_option:dropdownOptions[0].value:'',
          text_document: '',
          //text_document: questionEditFormValues.text_document, dropdownOptions
          textDocumentOption:[],
          optionsId:[]
        });
        setQuestionEditFormValues({
          question: "",
          text_help: "",
          text_document: "",
          is_optional: true,
        });
        props.cancelEditQuestion();
        ///}
      }
      if (props.questionDetails.questionType === "singlechoice") {
        let nulloptioncount = 0;
        allOptions.forEach((element) => {
          if (element !== "") {
            nulloptioncount = nulloptioncount + 1;
          }
        });

        let nullTextDocumentcount = 0;
        allTextDocument.forEach((element,index) => {
          if (element !== "") {
            nullTextDocumentcount = nullTextDocumentcount + 1;
          }
        });

        const list = [...allOptions];
        for (var i = 0; i < list.length; i++) {
          if (list[i] == "" || list[i] == " ") {
            list.splice(i, 1);
            i--;
          }
        }
        if (nulloptioncount !== 0) {
          if (nulloptioncount < 2) {
            showError("Single choice questions require atleast two options.");
          } else {

            if(nullTextDocumentcount !== nulloptioncount){
              showError(
                "Please fill all the text go in document option."
              );
              return false;
            }

            props.editQuestions({
              _id: props.questionDetails._id,
              questionType: props.questionDetails.questionType,
              question: questionEditFormValues.question,
              text_help: questionEditFormValues.text_help,
              is_optional: questionEditFormValues.is_optional,
              is_logic: questionEditFormValues.is_logic,
              logic_option: (questionEditFormValues.is_logic)?(questionEditFormValues.logic_option)?questionEditFormValues.logic_option:dropdownOptions[0].value:'',
              options: list,
              jumpTo: allJumpTo,
              textDocumentOption: allTextDocument,
              text_document:'',
              optionsId:optionsIdList
            });
            setQuestionEditFormValues({
              question: "",
              text_help: "",
              is_optional: true,
            });
            props.cancelEditQuestion();
            setAllTextDocument([]);
            setOptionsIdList([]);
            setAllOptions([]);
          }
        }
        else {
          showError("Your answer is empty - please enter option.");
        }
      }
      if (props.questionDetails.questionType === "multiplechoice") {
        let nulloptioncount = 0;
        allOptions.forEach((element) => {
          if (element !== "") {
            nulloptioncount = nulloptioncount + 1;
          }
        });

        let nullTextDocumentcount = 0;
        allTextDocument.forEach((element,index) => {
          if (element !== "") {
            nullTextDocumentcount = nullTextDocumentcount + 1;
          }
        });

        const list = [...allOptions];
        for (var i = 0; i < list.length; i++) {
          if (list[i] == "" || list[i] == " ") {
            list.splice(i, 1);
            i--;
          }
        }
        if (nulloptioncount !== 0) {
          if (nulloptioncount < 2) {
            showError(
              "Single choice questions require atleast two options."
            );
          } else {

            if(nullTextDocumentcount !== nulloptioncount){
              showError(
                "Please fill all the text go in document option."
              );
              return false;
            }

            props.editQuestions({
              _id: props.questionDetails._id,
              questionType: props.questionDetails.questionType,
              question: questionEditFormValues.question,
              text_help: questionEditFormValues.text_help,
              is_optional: questionEditFormValues.is_optional,
              is_logic: questionEditFormValues.is_logic,
              logic_option: (questionEditFormValues.is_logic)?(questionEditFormValues.logic_option)?questionEditFormValues.logic_option:dropdownOptions[0].value:'',
              options: list,
              textDocumentOption: allTextDocument,
              text_document:'',
              optionsId:optionsIdList
            });
            setQuestionEditFormValues({
              question: "",
              text_help: "",
              is_optional: true,
            });
            props.cancelEditQuestion();
            setAllTextDocument([]);
            setOptionsIdList([]);
            setAllOptions([]);
          }
        } else {
          showError("Your answer is empty - please enter option.");
        }
      }


    }
  };

  // function useOutsideAlerter(ref, questionFormValues) {
  //   useEffect(() => {
  //     /**
  //      * Alert if clicked on outside of element
  //      */
  //     function handleClickOutside(event) {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //         if (questionFormValues.question != "") {
  //           if (!event.target.classList.contains('icon-medium')) {
  //             if (!event.target.classList.contains('toast-close-icon')) {
  //               handleQuestionEditSubmit();
  //             }
  //           }
  //         }
  //       }
  //     }
  //     // Bind the event listener
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       // Unbind the event listener on clean up
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, [ref, questionFormValues, allOptions, likertSubQuestions]);
  // }

  //useOutsideAlerter(wrapperRef, questionEditFormValues);

  const handleDragStart = (event) => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };

  const handleDragUpdate = (event) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;

    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];



    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };

  const getDraggedDom = (draggableId) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  return (
    <div ref={wrapperRef}>
      {props.questionDetails.questionType == "free" && (
        <div className={`add-question-wrapper questioninputbox`}>
          <div className={`bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap`}>
            <div className="bd-que-slide-left">
              <div className="db-que-list-wrap survey-question-drop-down">
                <div className="dropdown">
                  Question {props.questionNoIndex} - Free text
                </div>
              </div>
            </div>
            <div className="bd-que-slide-arrow">
              <div className="sqa-check-top">
                <div className="d-flex form-group custom-control custom-checkbox mb-0 dark-checkbox">

                  <div className="form-group custom-control mb-0 logicbuttonbox">
                    <label className="switch">
                      <input type="checkbox"
                             checked={Boolean(questionEditFormValues.is_logic)}
                             onChange={(e) => {
                               setQuestionEditFormValues({
                                 ...questionEditFormValues,
                                 is_logic: e.target.checked ? 1 : 0,
                               });
                               setLogicAdded(logicAdded == true ? false : true);
                             }
                             }
                      />
                      <span className="slider round"></span>
                      Add logic
                    </label>
                  </div>

                  {questionEditFormValues.is_logic ?
                    <div className="small-dropdown add-logic-dropdown" style={{width: "200px"}}>
                      <select id="logicOption" name="logicOption" className="pointer form-select"
                              onChange={(item) => {
                                // props.saveJumpTo(index, item.target.value)
                                // setData({logic:item.target.value})
                                setQuestionEditFormValues({
                                  ...questionEditFormValues,
                                  logic_option: item.target.value,
                                });
                              }}
                              value={(questionEditFormValues.logic_option)?questionEditFormValues.logic_option:''}>
                        {/*value={props.allJumpTo[index] != "End Survey" ? (props.allJumpTo[index] - props.questionno == 1 ? "Next question" : "Question " + props.allJumpTo[index]) : "End survey"}>*/}

                        {dropdownOptions.map(item => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })}
                      </select>
                    </div>
                    :''
                  }

                  <div className={'cross-q'} style={{marginLeft:'20px'}} onClick={() => {
                    // props.changeQuestionType(tempQuestionType);
                    props.cancelEditQuestion();
                    //props.onDelete();
                    setQuestionEditFormValues({
                      question: "",
                      is_optional: true,
                    });

                  }}>
                    Close
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="create-projectmodal-form-group">
            <label className="form-label">Your question</label>
            {props.questionDetails.questionType === 'free' ?
              <textarea
                rows={5}
                className="form-control"
                type={"text"}
                onChange={setQuestion("question")}
                required={true}
                value={questionEditFormValues.question}
                id="question"
              />
              :
              <input
                className="form-control"
                type={"text"}
                onChange={setQuestion("question")}
                required={true}
                value={questionEditFormValues.question}
                id="question"
              />
            }


            {questionEditFormErrors.question &&
            <span style={{color:"red"}}>{questionEditFormErrors.question}</span>
            }
          </div>

          {/*<div className="create-projectmodal-form-group">*/}
          {/*  <label className="form-label">Text to go in the document</label>*/}
          {/*  <textarea*/}
          {/*    rows={5}*/}
          {/*    className="form-control"*/}
          {/*    type={"text"}*/}
          {/*    onChange={setQuestion("text_document")}*/}
          {/*    required={true}*/}
          {/*    value={questionEditFormValues.text_document}*/}

          {/*  />*/}

          {/*  {questionEditFormErrors.text_document &&*/}
          {/*  <span style={{color:"red"}}>{questionEditFormErrors.text_document}</span>*/}
          {/*  }*/}
          {/*</div>*/}

          <div className="create-projectmodal-form-group">
            <label className="form-label">Text help</label>
            <textarea
              className="form-control"
              type={"text"}
              onChange={setQuestion("text_help")}
              // required={true}
              value={questionEditFormValues.text_help}
              rows= {5}

            />

            {questionEditFormErrors.text_help &&
            <span style={{color:"red"}}>{questionEditFormErrors.text_help}</span>
            }
          </div>
          <div className="button-wrap d-flex justify-content-start">

            <button
              name="Save"
              onClick={handleQuestionEditSubmit}
              className="btn  submit-btn1"
              //state={questionEditFormValues.question == "" ? "disabled" : "active"}
            >Save</button>

          </div>
        </div>
      )}
      {(props.questionDetails.questionType == "singlechoice" ||
        props.questionDetails.questionType == "multiplechoice") && (
        <div className={`add-question-wrapper questioninputbox`}>
          <div className={`bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap`}>

            <div className="bd-que-slide-left question-heading-left">
              <div className="db-que-list-wrap survey-question-drop-down">
                {props.questionDetails.questionType == "singlechoice" && (
                  <div className="dropdown">
                    Question {props.questionNoIndex} - Single choice

                  </div>
                )}
                {props.questionDetails.questionType == "multiplechoice" &&
                (
                  <div className="dropdown">
                    Question {props.questionNoIndex} - Multi choice

                  </div>
                )}
              </div>
            </div>
            <div className={`bd-que-slide-arrow add-logic-survey-option`}>
              <div className="sqa-check-top singlechoice-addlogic">
                <div className="form-group custom-control custom-checkbox d-flex">


                  {/*{props.questionDetails.questionType == "singlechoice" && (*/}
                    <div className="form-group custom-control mb-0 logicbuttonbox">

                      <label className="switch">
                        <input type="checkbox"
                               checked={Boolean(questionEditFormValues.is_logic)}
                               onChange={(e) => {
                                 setQuestionEditFormValues({
                                   ...questionEditFormValues,
                                   is_logic: e.target.checked ? 1 : 0,
                                 });
                                 setLogicAdded(logicAdded == true ? false : true);
                               }
                               }
                        />
                        <span className="slider round"></span>
                        Add logic
                      </label>

                      {/*<ToggleButton*/}
                      {/*  id="logicCheck"*/}
                      {/*  isChecked={Boolean(questionEditFormValues.is_logic)}*/}
                      {/*  label={<Text display="inline" fontWeight={'medium-font'} type="body-text-2">Add logic</Text>}*/}
                      {/*  onChange={(e) => {*/}
                      {/*    setQuestionEditFormValues({*/}
                      {/*      ...questionEditFormValues,*/}
                      {/*      is_logic: e.target.checked ? 1 : 0,*/}
                      {/*    });*/}
                      {/*    setLogicAdded(logicAdded == true ? false : true);*/}
                      {/*  }*/}
                      {/*  }*/}
                      {/*/>*/}
                    </div>
                  {/*)}*/}

                  {questionEditFormValues.is_logic && props.questionDetails.questionType == "multiplechoice"?
                    <div className="small-dropdown add-logic-dropdown" style={{width: "200px"}}>
                      <select id="logicOption" name="logicOption" className="pointer form-select"
                              onChange={(item) => {
                                // props.saveJumpTo(index, item.target.value)
                                // setData({logic:item.target.value})
                                setQuestionEditFormValues({
                                  ...questionEditFormValues,
                                  logic_option: item.target.value,
                                });
                              }}
                              value={(questionEditFormValues.logic_option)?questionEditFormValues.logic_option:''}>
                        {/*value={props.allJumpTo[index] != "End Survey" ? (props.allJumpTo[index] - props.questionno == 1 ? "Next question" : "Question " + props.allJumpTo[index]) : "End survey"}>*/}

                        {dropdownOptions.map(item => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })}
                      </select>
                    </div>
                    :''
                  }

                  <div className={'cross-q'} style={{marginLeft:'20px'}} onClick={() => {
                    //props.changeQuestionType(tempQuestionType);
                    props.cancelEditQuestion();
                    //props.onDelete();
                    setQuestionEditFormValues({
                      question: "",
                      is_optional: true,
                    });
                    setOtherOptionIncluded(false);
                  }}>
                    Cancel
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div className="create-projectmodal-form-group">
            <label htmlFor="inputName5" className="form-label">
              Your question
            </label>
            <input
              className="form-control"
              type={"text"}
              required={true}
              onChange={setQuestion("question")}
              value={questionEditFormValues.question}
              id="question"
            />

            {questionEditFormErrors.question &&
            <span style={{color:"red"}}>{questionEditFormErrors.question}</span>
            }
          </div>

          <div className="create-projectmodal-form-group">
            <label className="form-label">Text help</label>
            <textarea
              className="form-control"
              type={"text"}
              onChange={setQuestion("text_help")}
              // required={true}
              value={questionEditFormValues.text_help}
              id="text_help"
              rows= {5}

            />

            {questionEditFormErrors.text_help &&
            <span style={{color:"red"}}>{questionEditFormErrors.text_help}</span>
            }
          </div>
          <div className={`ans-list-wrap `}>


            <p className="small-info-heading">
              Answers
            </p>
            <Options
              allOptions={allOptions}
              edit={(index) => editOptionHandler(index)}
              close={(index) => closeOptionHandler(index)}
              closeOtherOption={(index) => closeOtherOptionHandler()}
              save={(index, value) => saveOptionHandler(index, value)}
              saveTextDocument={(index, value) => saveAllTextDocument(index, value)}
              saveJumpTo={(index, value) => saveOptionJumpToHandler(index, value)}
              language={props.language}
              questionType={props.questionDetails.questionType}
              addOption={(index) => {
                if (allOptions.length < 200) {
                  allOptions.splice(index + 1, 0, "");
                  setAllOptions([...allOptions]);
                  allJumpTo.splice(index + 1, 0, props.questionNoIndex < props.questionlist.filter(arr => {if (arr.questionType !== 'pagebreak') {return true;}}).length ? props.questionNoIndex + 1 : "End Survey");
                  setAllJumpTo([...allJumpTo]);
                }
                else {

                  showError("Max two hundred options can be added")
                }
              }}
              logicAdded={logicAdded}
              jumpToList={jumpToListItems}
              allJumpTo={allJumpTo}
              allTextDocument={allTextDocument}
              questionno={props.questionNoIndex}
              questionsData={questionsData}
              //questionno={props.questionno}
            />
          </div>
          <div className="add-survey-answer-btn">

            <button
              className="btn submit-btn1"
              onClick={() => {
                allOptions.push("");
                setAllOptions([...allOptions]);
                allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                setAllJumpTo([...allJumpTo]);

                allTextDocument.push(EditorState.createEmpty());
                setAllTextDocument([...allTextDocument]);

                optionsIdList.push("");
                setOptionsIdList([...optionsIdList]);

              }}
            >
              Add answer
            </button>
            {/*<Button*/}
            {/*  iconLeft={<Icon value="add" />}*/}
            {/*  label="Add answer"*/}
            {/*  type="ghost"*/}
            {/*  onClick={() => {*/}
            {/*    if (allOptions.length < 200) {*/}
            {/*      if (otherOptionIncluded == true) {*/}
            {/*        allOptions.splice(-1, 0, "");*/}
            {/*        setAllOptions([...allOptions]);*/}
            {/*        allJumpTo.splice(-1, 0, props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");*/}
            {/*        setAllJumpTo([...allJumpTo]);*/}
            {/*      } else {*/}
            {/*        allOptions.push("");*/}
            {/*        setAllOptions([...allOptions]);*/}
            {/*        allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");*/}
            {/*        setAllJumpTo([...allJumpTo]);*/}
            {/*      }*/}
            {/*    } else {*/}
            {/*      showError("Max two hundred options can be added");*/}
            {/*    }*/}
            {/*  }}*/}
            {/*/>*/}


          </div>
          <div className="button-wrap d-flex justify-content-start">
            <button
              className="btn submit-btn1"
              onClick={handleQuestionEditSubmit}
              //state={questionEditFormValues.question == "" ? "disabled" : "active"}
            >Save</button>
          </div>
        </div>
      )}
    </div>
  );
}
