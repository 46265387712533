import React , { lazy } from 'react'
import { useState, useEffect } from "react"
import Config from '../../config';
import { useHistory } from "react-router-dom";
 const Popup = lazy(() => import('../widgets/DeletePop.js'))
 const Header= lazy(() => import("../widgets/Header.js"));
 const Asidebar =  lazy(() => import('../widgets/Asidebar.js'))
 const Footer = lazy(() => import('../widgets/Footer.js'))
 const Jsfile = lazy(() => import('../widgets/Jsfile.js'))

const AddCategories = (props) => {
const id = localStorage.getItem("catID")
 
//  const [toast, setToast] = useState(true);
 const [data ,setData] = useState([]);
 const catData =  () =>{
   
    Config.get(`/admin/lawyer-categories/id?_id=${id}`)
    .then((res) => {
     console.log(res);
        if (res.status) {
        setData(res.data)

      }else{
        Config.showToast("Please try after some time.");
      }
    })
    .catch((err) => {
      Config.showToast("Please try after some time.");
    });

  }

    const setIdinLocal = (id)=>{
    localStorage.setItem("catID",id);
    localStorage.setItem("deleteType","category");
   
    }
   


    const back = () =>{
      history.push("/membership");
    }

  
  useEffect(() => {
    if(props.type== "Edit"){
      catData()
     document.getElementById("deleteButton").style.display = "block"
    }
  }, [])

  const history = useHistory();

  // add edit data
    async function AddPlan(e) {
          e.preventDefault();
        
             var name = e.target.name.value, 
             code = e.target.code.value
   

        if(props.type == "Add"){
         var data = {name,code};
            Config.post('/admin/lawyer-categories',data)
              .then((res) => {
              console.log(res);
                if (res.status) {
                  history.push("/category");
                  }else{
                  Config.showToast("somthing wrong");
                  }
                })
              .catch((err) => {
                Config.showToast("Please try after some time.");
              });

            }else {
              
          // call edit api 
          var data = {_id:id,name,code};

                Config.put('/admin/lawyer-categories',data)
                .then((res) => {
                  console.log(res);
                  if (res.status) {
                     
                    history.push("/category");
                    }else{
                    Config.showToast("somthing wrong ");
                    }
                  })
                .catch((err) => {
                  Config.showToast("Please try after some time.");
                });



         }

      }
  


  return (
    <>
      <Jsfile />
      <Header />
 <main id="main" className="main">
  <Popup />

  <section className="section">
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{props.type} Categories </h5>
            {/* Multi Columns Form */}

          

            <form className="row g-3" method='post' onSubmit={AddPlan}>
              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                 Name  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="name" onChange={(e) => setData(e.target.value)} value={data.name}  required/>
              </div>
              <div className="col-md-4">
                <label htmlFor="inputName5" className="form-label">
                 Code  <span style={{color:"red"}} >* </span>
                </label>
                <input type="text" className="form-control" id="code" onChange={(e) => setData(e.target.value)} value={data.code} required/>
              </div>
         
       
        
            <div className="text-center">
                  
                  <button type="submit" className="btn submit-btn1">
                  {props.type== "Edit" ? "Update": "Submit" }
                  </button>
 
                  <span onClick={()=>{back()}} className="btn  submit-btn2">
                   Go Back</span>
             

                  <span className="btn  submit-btn4" style={{float:"right", display:"none"}} data-bs-toggle="modal" 
                   id ="deleteButton"
                   onClick={()=>{setIdinLocal(data._id)}} data-bs-target="#verticalycentered"
                   >Delete</span>
              
              </div>
            </form>
            {/* End Multi Columns Form */}
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
 
      <Asidebar />
      <Footer /> 
    
</>

 )
}

export default AddCategories
